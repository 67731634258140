import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { Button, Card, Col, Dropdown, Form, Image, Input, Modal, Row, Select, Space, Tag, Tooltip, message } from 'antd';
import { PlusOutlined, SearchOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import {ReactComponent as Export} from '../../assets/images/export.svg';
import {ReactComponent as Filter} from '../../assets/images/filter.svg';
import {ReactComponent as Action} from '../../assets/images/action.svg';
import ExistingClientIcon from '../../assets/images/existing-client-icon.png';
import { apiUrl } from '../../utils/constants';
import { MainContext } from '../../utils/context';
import TableContainer from '../../components/TableContainer';
import moment from 'moment/moment';
import { useNavigate } from 'react-router';
import LocationSelect from '../../components/LocationSelect';
import InvestigatorSelect from '../../components/InvestigatorSelect';

const Cases = () => {

  const navigate = useNavigate();

  const {token, setToken, user, setUser, logoutHandler} = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [getInvestigatorsLoading, setGetInvestigatorsLoading] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const [recrawlingLoading, setRecrawlingLoading] = useState(false);
  const [rminvstrLoading, setRminvstrLoading] = useState(false);
  const [logs, setLogs] = useState([
    {
      image: ""
    }
  ]);
  const [logsPopup, setLogsPopup] = useState(false);
  const [logsPage, setLogsPage] = useState(1);
  const [logsRows, setLogsRows] = useState(10);
  const [logsTotal, setLogsTotal] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [cases, setCases] = useState([
    {
      name: "",
      client_name: "",
      case_id: "",
      investigator: "",
      created_on: "",
      status: ""
    }
  ]);
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState('all');
  const [clients, setClients] = useState([{value: 0, label: 'All'}]);
  const [client, setClient] = useState(0);
  const [statusString, setStatusString] = useState("search_status[]=active&search_status[]=crawling&search_status[]=partial");
  const [deleteItem, setDeleteItem] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteManyConfirm, setDeleteManyConfirm] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addInvestigatorPopup, setAddInvestigatorPopup] = useState(false);
  const [addInvestigatorfor, setAddInvestigatorFor] = useState({});
  const [investigatorsList, setInvestigatorsList] = useState([]);
  const [selectedInvestigator, setSelectedInvestigator] = useState(undefined);
  const [investigatorData, setInvestigatorData] = useState({});
  const [collapse, setCollapse] = useState(false);

  const items = (user?.role_id === 2 || user?.role_id === 3 || user?.role_id === 4) ? [] : 
  deleteItem?.status?.toLowerCase() === "partial" ?
  [
    {
      key: '1',
      danger: false,
      label: (
        <a onClick={() => setLogsPopup(true)}>
          View Crawl logs
        </a>
      ),
    },
    {
      key: '2',
      danger: true,
      label: (
        <a onClick={() => setDeleteConfirm(true)}>
          Delete {deleteItem?.name}
        </a>
      ),
    }
  ] :
  [
    {
      key: '1',
      danger: true,
      label: (
        <a onClick={() => setDeleteConfirm(true)}>
          Delete {deleteItem?.name}
        </a>
      ),
    }
  ];

  const columns = [
    {
      title: "Case name",
      dataIndex: "name",
      render: (text, row) => (
          <span
            className='navigate-link'
            onClick={e => {
                      if(row?.status?.toLowerCase() === 'crawling') {
                        message.warning("Case is still crawling");
                      }
                      else {
                        navigate(`./${row?.id}?category=marketplace`);
                      }
            }}
          >
            {text?.length ? text: "--"}
          </span>
        )
    },
    {
      title: "Client name",
      dataIndex: "client_name",
      render: (text, row) => (
        <div style={{display: "flex", alignItems: "center"}}>
          {
            (row?.logo?.length) ? <Image src={row?.logo} alt={text} className='logo-img' /> : <div className='no-logo-div'></div>
          }
          <span className='title'>{text}</span>
        </div>
      )
    },
    {
      title: "Case id",
      dataIndex: "case_id"
    },
    {
      title: "Investigator",
      dataIndex: "investigator",
      render: (text, row) =>
      <>
        {
          text?.id !== 0 ? 
          <p onClick={() => setInvestigatorData({...row?.investigator, caseId: row?.id})} style={{cursor: "pointer"}}>{text?.name}</p> : 
          user?.role_id === 3 ? "No Investigator" :  
          <p className='add-investigator-link' onClick={() => {setAddInvestigatorPopup(true); setAddInvestigatorFor(row)}}>+ Add investigator</p>
        }
      </>
    },
    {
      title: "Created on",
      dataIndex: "created_on",
      render: text => 
      <>
        {
          text ? moment(text).format("MMMM D, YYYY") : "--"
        }
      </>
    },
    {
      title: "Status",
      dataIndex: "status",
      render: text => 
      <>
      {
        text &&
        <Tag 
          bordered={false}
          style={{
            padding: 4,
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 500,
            background: text?.toLowerCase() === "active" ? '#DEF2E6' : text?.toLowerCase() === "partial" ? "#FFE7CD" : '#FFEFF0',
            color: text?.toLowerCase() === 'active' ? '#11845B' : text?.toLowerCase() === "partial" ? "#F67906" : '#DC2B2B',
            borderRadius: 40
          }}
        >
          {text}
        </Tag>
      }
      </>
    },
    {
      title: "Action",
      align: "center",
      render: (text, row) => 
      (
        <>
        <Dropdown
          menu={{items,}}
          placement="bottomRight"
          trigger={['click']}
          onClick={() => setDeleteItem(row)}
        >
          <Button
            type='link'
          >
            <Action />
          </Button>
        </Dropdown>
        </>
      )
    }
  ];

  const logsColumns = [
    {
      title: "Image",
      dataIndex: "image",
      render: text => 
      <>
        {
          text?.length ? <Image src={text} alt="image" width={64} /> : "No Image"
        }
      </>
    },
    {
      title: "Subcategory",
      dataIndex: "subcategory",
    },
    {
      title: "Started on",
      dataIndex: "time_started",
      render: text => (
        <>
        {
          text?.length ? moment(text).format("MMM DD, YYYY") : "--"
        }
        </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      render: text => 
      <Tag 
        bordered={false}
        style={{
          padding: 4,
          width: '100%',
          textAlign: 'center',
          fontSize: 14,
          fontWeight: 500,
          textTransform: "capitalize",
          background: text?.toLowerCase() === "completed" ? '#DEF2E6' : text?.toLowerCase() === "partial" ? "#FFE7CD" : '#FFEFF0',
          color: text?.toLowerCase() === 'completed' ? '#11845B' : text?.toLowerCase() === "partial" ? "#F67906" : '#DC2B2B',
          borderRadius: 40
        }}
      >
        {text}
      </Tag>
    },
  ];

  const getCases = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/case/*?page=${page}&rows=${rows}&client_id=${client}&search_str=${searchStr}&${statusString}`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setLoading(false);
        if(res?.status === 1) {
          const object = res.case_details;
          setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          setCases(Object?.values(object));
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch(res => {
        setLoading(false);
        console.log('error', res)
      });
  }

  const getClientList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/client/*?page=1&rows=10000`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setLoading(false);
        if(res?.status === 1) {
          const object = res.client_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          // setClients(Object.values(object));
          const arr = Object.values(object).map(item => ({value: item?.id, label: item?.name}));
          setClients([...clients, ...arr]);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch(res => {
        setLoading(false);
        console.log('error', res)
      });
  }

  const getInvestigatorsFromClientIdHandler = () => {
    setGetInvestigatorsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/client/${addInvestigatorfor?.client_id}`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setGetInvestigatorsLoading(false);
        if(res?.status === 1) {
          setInvestigatorsList(res?.client_details?.investigators);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch(res => {
        setGetInvestigatorsLoading(false);
        console.log('error', res)
      });
  }

  const deleteCaseHandler = () => {
    setDeleteLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/case/${deleteItem?.id}`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setDeleteLoading(false);
        setDeleteItem({});
        message.success(res?.message);
        setDeleteConfirm(false);
        getCases();
      })
      .catch(err => {
        setDeleteLoading(false);
        console.log('error', err)
      })
  }

  const deleteManyCasesHandler = () => {
    setDeleteLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();

    selectedRowKeys?.map(key => (
      formdata.append("case_id[]", key)
    ))

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/case/delete-many`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setDeleteLoading(false);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setDeleteManyConfirm(false);
        message.success(res?.message);
        if(page === 1) {
          getCases();
        }
        else {
            setPage(1);
        }
      })
      .catch(err => {
        setDeleteLoading(false);
        console.log('error', err)
      })
  }

  const handleNewClientClick = (type) => {
    navigate(`/cases/add-new-case?client=${type}`);
  }

  const addInvestigatorHandler = () => {
    setGetInvestigatorsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("_method", "patch");
    formdata.append("investigator", selectedInvestigator);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/case/${addInvestigatorfor?.id}`, requestOptions)
    .then(response => response.json())
    .then(res => {
      setGetInvestigatorsLoading(false);
      if(res?.status === 1) {
        message.success("Investigator added successfully!");
        setAddInvestigatorPopup(false);
        setAddInvestigatorFor({});
        getCases();
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.email[0]);
        }
    })
    .catch(res => {
      setGetInvestigatorsLoading(false);
      console.log('error', res)
    });
  }

  const exportHandler = () => {
    setExporting(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/case/*?page=${page}&rows=${rows}&client_id=${client}&search_str=${searchStr}&${statusString}&export=1`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setExporting(false);
        if(res?.status === 1) {
          window.location.href = res?.file_path;
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch(res => {
        setExporting(false);
        console.log('error', res)
      });
  }

  const getCrawlLogsHandler = () => {
    setLogsLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/list-case-progress/${deleteItem?.id}?page=${logsPage}&rows=${logsRows}`, requestOptions)
    .then(response => response.json())
    .then(res => {
      setLogsLoading(false);
      if(res?.status === 1) {
        const object = res.progress;
        setLogsTotal(object?.total);
        delete object.page;
        delete object.rows;
        delete object.total;
        setLogs(Object?.values(object));
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.email[0]);
        }
    })
    .catch(res => {
      setLogsLoading(false);
      console.log('error', res)
    });
  }

  const startRecrawlingHandler = () => {
    setRecrawlingLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("_method", "patch");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/case/${deleteItem?.id}`, requestOptions)
    .then(response => response.json())
    .then(res => {
      setRecrawlingLoading(false);
      if(res?.status === 1) {
        message.success("Recrawling Started successfully");
        setLogsPopup(false);
        setDeleteItem({});
        getCases();
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.email[0]);
        }
    })
    .catch(res => {
      setRecrawlingLoading(false);
      console.log('error', res)
    });
  }

  const removeInvestigatorHandler = () => {
    setRminvstrLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("_method", "patch");
    formdata.append("investigator", "");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/case/${investigatorData?.caseId}`, requestOptions)
    .then(response => response.json())
    .then(res => {
      setRminvstrLoading(false);
      if(res?.status === 1) {
        message.success("Investigator removed successfully!");
        setInvestigatorData({});
        getCases();
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.email[0]);
        }
    })
    .catch(res => {
      setRminvstrLoading(false);
      console.log('error', res)
    });
  }

  useEffect(() => {
    if(searchStr?.length) {
      setPage(1);
      const getData = setTimeout(() => {
        getCases();
       }, 700);
   
       return () => clearTimeout(getData);
    }
    else {
      getCases();
    }
  }, [searchStr, statusString, rows, page, client]);

  useEffect(() => {
    if(status === 'active') {
      setStatusString("search_status[]=active");
    }
    else if(status === 'crawling') {
      setStatusString("search_status[]=crawling");
    }
    else if(status === 'hold') {
      setStatusString("search_status[]=hold");
    }
    else if(status === 'partial') {
      setStatusString("search_status[]=partial");
    }
    else {
      setStatusString("");
    } 
  }, [status]);

  useEffect(() => {
    getClientList();
  }, []);

  useEffect(() => {
    if(addInvestigatorfor?.client_id) {
      getInvestigatorsFromClientIdHandler()
    }
  }, [addInvestigatorfor])

  useEffect(() => {
    if(logsPopup) {
      getCrawlLogsHandler()
    }
  }, [logsPopup, logsPage, logsRows])

  return (
    <div className='cases-div'>
        <Card className='card cases-card'>
          <Row gutter={10}>
            <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
              <h3 className='page-title'>Cases</h3>
            </Col>
            <Col span={12}>
              <Space wrap style={{float: "right"}}>
                {
                  isMobile ?
                  <>
                  <Tooltip title="Export">
                    <Button 
                      shape="circle" 
                      icon={<Export />} 
                      loading={loading || exporting}
                      onClick={() => exportHandler()}
                      disabled={!cases?.length}
                    />
                  </Tooltip>
                  {
                    (user?.role_id === 2 || user?.role_id === 3 || user?.role_id === 4) ?
                    <></> : 
                    <Tooltip title="Add case">
                      <Button 
                        shape="circle" 
                        icon={<PlusOutlined />} 
                        onClick={() => setOpenPopup(true)}
                      />
                    </Tooltip>
                  }
                  <Tooltip title="More options">
                    <Button 
                      shape="circle" 
                      icon={<DownOutlined style={{transform: collapse ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.2s ease-in-out'}} />} 
                      onClick={() => setCollapse(!collapse)}
                    />
                  </Tooltip>
                  </> :
                  <>
                  <Button
                    size='large'
                    loading={loading || exporting}
                    onClick={() => exportHandler()}
                    disabled={!cases?.length}
                  >
                    <Export style={{marginBottom: -2, marginRight: 8}} />
                    Export
                  </Button>
                  {
                    (user?.role_id === 2 || user?.role_id === 3 || user?.role_id === 4) ?
                    <></> :
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => setOpenPopup(true)}
                    >
                      <PlusOutlined />
                      Add new case
                    </Button>
                  }
                  </>
                }
              </Space>
            </Col>
          </Row>
          <hr style={{margin: '28px 0 24px 0', border: 'none', height: 1, background: '#E6E6E6'}} />
          <Row>
            <Col 
              xs={24} 
              lg={18}
              style={{
                paddingTop: 1,
                marginTop: (isMobile && collapse) ? 15 : 0,
                height: (isMobile && collapse) ? 189 : (!isMobile) ? "auto" : 0,
                overflow: "hidden",
                transition: "all 0.2s ease-in-out"
              }}
            >
              <Space wrap className='filter-space'>
                <Input
                  size='large'
                  placeholder='Search cases'
                  prefix={<SearchOutlined style={{color: "#A9AFBA"}} />}
                  style={{width: isMobile ? "100%" : 296}}
                  value={searchStr}
                  // onChange={e => setSearchStr(e.target.value)}
                  onChange={e => {
                    (setSearchStr(e.target.value));
                    setPage(1);
                  }}
                />
                {
                  (user?.role_id === 5) ?
                  <></> :
                  <div className='select-input-div'>
                    <label>Client</label>
                    <Select
                      className='select status-select'
                      defaultValue={client}
                      style={{minWidth: 120, width: isMobile ? "85%" : ""}}
                      size='large'
                      bordered={false}
                      options={clients}
                      onChange={value => setClient(value)}
                    />
                  </div>
                }
                <div className='select-input-div'>
                  <label>Status</label>
                  <Select
                    className='select status-select'
                    defaultValue={status}
                    style={{minWidth: 80, width: isMobile ? "83%" : ""}}
                    size='large'
                    bordered={false}
                    options={[
                      {
                        value: 'all',
                        label: 'All'
                      },
                      {
                        value: 'active',
                        label: 'Active'
                      },
                      {
                        value: 'crawling',
                        label: 'Crawling'
                      },
                      {
                        value: 'hold',
                        label: 'On hold'
                      },
                      {
                        value: 'partial',
                        label: 'Partial'
                      },
                    ]}
                    onChange={value => setStatus(value)}
                  />
                </div>
                {/* <Button
                  size='large'
                >
                  <Filter style={{marginRight: 10}} />
                  Filter
                </Button> */}
              </Space>
            </Col>
            <Col xs={24} lg={6} style={{display: "flex", justifyContent: "flex-end"}}>
              {
                selectedRowKeys?.length > 0 &&
                <Button
                  size='large'
                  danger
                  // onClick={deleteManyCasesHandler}
                  onClick={() => {
                    setDeleteManyConfirm(true);
                  }}
                  loading={deleteLoading}
                >
                  Delete selected cases
                </Button>
              }
            </Col>
          </Row>
          {/* table component */}
          <TableContainer 
            data={cases} 
            columns={columns} 
            loading={loading}
            total={total}
            page={page}
            setPage={setPage}
            rows={rows}
            setRows={setRows}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Card>
        <Modal 
          title="Add a new case for" 
          open={openPopup} 
          onCancel={() => setOpenPopup(false)}
          footer={[]} 
          className='case-options-modal'
          width={384}
        >
          <Button className='option-btn' onClick={() => handleNewClientClick("existing")}>
            <img src={ExistingClientIcon} alt='existing' width={32} style={{marginRight: 14}} />
            Existing client
          </Button>
          <Button className='option-btn' onClick={() => handleNewClientClick("new")}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#DEF2E6"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M21 15H17V11C17 10.45 16.55 10 16 10C15.45 10 15 10.45 15 11V15H11C10.45 15 10 15.45 10 16C10 16.55 10.45 17 11 17H15V21C15 21.55 15.45 22 16 22C16.55 22 17 21.55 17 21V17H21C21.55 17 22 16.55 22 16C22 15.45 21.55 15 21 15Z" fill="#11845B"/>
            </svg>
            New client
          </Button>
        </Modal>
        <Modal
          title={`Delete ${deleteItem?.name}`}
          open={deleteConfirm} 
          onCancel={() => {setDeleteItem({}); setDeleteConfirm(false)}}
          footer={[]} 
          className='case-options-modal delete-confirm-modal'
          width={384}
        >
          <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
          <h3 className='sub-title'>Are you sure?</h3>
            <Button
              type='default'
              size='large'
              className='btn'
              onClick={() => {setDeleteItem({}); setDeleteConfirm(false)}}
              loading={deleteLoading}
            >
              No
            </Button>
            <Button
              type='primary'
              size='large'
              className='btn confirm-btn'
              onClick={deleteCaseHandler}
              loading={deleteLoading}
            >
              Yes, Delete it
            </Button>
        </Modal>
        <Modal
          open={addInvestigatorPopup}
          title={`Add investigator for ${addInvestigatorfor?.name}`}
          onCancel={() => {setAddInvestigatorPopup(false); setAddInvestigatorFor({}); setSelectedInvestigator(undefined)}}
          footer={[]} 
          className='case-options-modal'
          width={500}
        >
          <Select
            size='large'
            placeholder="Select investigator..."
            defaultValue={addInvestigatorfor?.investigator?.id !== 0 ? addInvestigatorfor?.investigator?.id : selectedInvestigator}
            value={addInvestigatorfor?.investigator?.id !== 0 ? addInvestigatorfor?.investigator?.id : selectedInvestigator}
            onChange={e => setSelectedInvestigator(e)}
            style={{width: "100%", marginTop: 16}}
            allowClear
            loading={getInvestigatorsLoading}
          >
            {
              investigatorsList?.map((inv, i) => (
                <Select.Option key={i} value={inv?.id}>{inv?.name}</Select.Option>
              ))
            }
          </Select>
          <div style={{textAlign: "right", marginTop: 24}}>
            <Button
              type='primary'
              size='large'
              loading={getInvestigatorsLoading}
              onClick={() => addInvestigatorHandler()}
            >
              Add investigator
            </Button>
          </div>
        </Modal>
        <Modal
          title="Crawl Logs"
          open={logsPopup}
          onCancel={() => {setLogsPopup(false); setLogs([{image: ""}]); setLogsPage(1)}}
          footer={[]}
          width={700}
        >
          <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
            <Button
              type='primary'
              onClick={() => startRecrawlingHandler()}
              loading={recrawlingLoading || logsLoading}
            >
              Start Recrawling
            </Button>
          </div>
          <TableContainer 
            data={logs} 
            columns={logsColumns} 
            loading={logsLoading}
            total={logsTotal}
            page={logsPage}
            setPage={setLogsPage}
            rows={logsRows}
            setRows={setLogsRows}
            isSelectable={false}
          />
        </Modal>
        <Modal
          title="Investigator Details"
          open={investigatorData?.id}
          onCancel={() => setInvestigatorData({})}
          footer={[]}
        >
          <Form
            layout='vertical'
            style={{marginTop: 20}}
          >
            <Form.Item label="Name">
              <Input
                size='large'
                defaultValue={investigatorData?.name}
                value={investigatorData?.name}
                readOnly
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                size='large'
                defaultValue={investigatorData?.email}
                value={investigatorData?.email}
                readOnly
              />
            </Form.Item>
            <Form.Item label="Assigned on">
              <Input
                size='large'
                defaultValue={moment(investigatorData?.date_assigned).format("MMM DD, YYYY")}
                value={moment(investigatorData?.date_assigned).format("MMM DD, YYYY")}
                readOnly
              />
            </Form.Item>
          </Form>
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Button
              size='large'
              type='default'
              danger
              onClick={() => removeInvestigatorHandler()}
              loading={rminvstrLoading}
            >
              Remove Investigator
            </Button>
          </div>
        </Modal>
        <Modal
        title={`Delete ${selectedRowKeys?.length} Cases`}
        open={deleteManyConfirm} 
        onCancel={() => {setSelectedRowKeys([]); setDeleteManyConfirm(false)}}
        footer={[]} 
        className='case-options-modal delete-confirm-modal'
        width={384}
      >
        <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
        <h3 className='sub-title'>Are you sure?</h3>
          <Button
            type='default'
            size='large'
            className='btn'
            onClick={() => {setDeleteManyConfirm(false)}}
            loading={deleteLoading}
          >
            No
          </Button>
          <Button
            type='primary'
            size='large'
            className='btn confirm-btn'
            onClick={deleteManyCasesHandler}
            loading={deleteLoading}
          >
            Yes, Delete
          </Button>
      </Modal>
    </div>
  )
}

export default Cases