import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/context";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
  message,
} from "antd";
import { apiUrl } from "../../utils/constants";
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";

const Plan = ({
  data = [],
  formData = {},
  setFormData = () => {},
  isEdit = false,
  investigators = [],
  setInvestigators = () => {},
  legal = [],
  setLegal = () => {},
  supportManager = undefined,
  setSupportManager = () => {},
  selectedPlan = {},
  setSelectedPlan = () => {},
  investigatorLoading = [],
  investigatorsList = [],
  legalList = [],
  supportManagerList = []
}) => {
  const { token, logoutHandler } = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const [loading, setLoading] = useState({
    solutions: false,
    plans: false,
  });
  const [solutionTypes, setSolutionTypes] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [planType, setPlanType] = useState(formData?.plan_id);
  const [customizePopup, setCustomizePopup] = useState(false);

  const [addonsList, setAddonsList] = useState([
    {
      label: "Visual Search",
      value: "visual search",
    },
    {
      label: "Test Purchase",
      value: "test purchase",
    },
    {
      label: "Domain Management",
      value: "domain management",
    },
    {
      label: "seller Intelligence",
      value: "seller intelligence",
    },
  ]);

  const getPlansHandler = () => {
    setLoading({ ...loading, plans: true });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/plan/*`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading({ ...loading, plans: false });
        if (res?.status === 1) {
          const object = res.plan_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          const dataArr = Object?.values(object);
          if(data?.plan_rules?.length) {
            let arr = dataArr.find(item => item?.id === data?.plan_rules[0]?.plan_id);
            arr.rules = data?.plan_rules[0]?.rule
            setPlanTypes(dataArr);
            setSelectedPlan(dataArr[planType-1]);
          }
          else {
            setPlanTypes(dataArr);
            setSelectedPlan(dataArr[planType-1]);
          }
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((res) => {
        setLoading({ ...loading, plans: false });
        console.log("error", res);
      });
  };

  const handleRuleChange = (newValue, property) => {
    const newData = selectedPlan?.rules?.map((item) => {
            if(item?.property === property) {
                    item.rule = newValue
            }
            return item;
    })
    setSelectedPlan({...selectedPlan, rules: newData})
  }

  const customizePlanHandler = () => {
    const newArr = [...planTypes]
    newArr[selectedPlan?.index] = selectedPlan;
    setPlanTypes(newArr)
    setCustomizePopup(false)
}

  useEffect(() => {
    getPlansHandler();
  }, []);

  useEffect(() => {
    setFormData({...formData, plan_rules: selectedPlan});
}, [selectedPlan])

  return (
    <div className="form-div client-info-div" style={{ padding: 0 }}>
      <Card className="card form-card">
        <h3 className="form-card-title">Solution Type</h3>
        {loading?.solutions ? (
          <div className="loading" style={{ height: 169 }}>
            <Spin />
          </div>
        ) : !formData?.solutions?.length ? (
          <div className="loading" style={{ height: 169 }}>
            No solutions
          </div>
        ) : (
          <Radio.Group
            className="solutiontype-radio-group"
            value={formData?.solution_id}
            onChange={(e) =>
              setFormData({ ...formData, solution_id: e.target.value })
            }
            disabled={!isEdit}
          >
            {formData?.solutions?.map((type, i) => (
              <Radio key={i} value={i + 1} className="solutiontype-radio">
                <div>
                  <p className="title">{type?.name}</p>
                  <div className="radio-desc-div">
                    <hr />
                    <Row>
                      <Col span={12} className="type-label">
                        <span>Crawling</span>
                        <span>:</span>
                      </Col>
                      <Col span={12} className="type-answer">
                        {type?.crawling}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="type-label">
                        <span>Monitoring</span>
                        <span>:</span>
                      </Col>
                      <Col span={12} className="type-answer">
                        {type?.monitoring}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="type-label">
                        <span>Legal</span>
                        <span>:</span>
                      </Col>
                      <Col span={12} className="type-answer">
                        {type?.legal}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Radio>
            ))}
          </Radio.Group>
        )}
      </Card>
      <Card className="card form-card">
        <h3 className="form-card-title">Plan Type</h3>
        {loading?.plans ? (
          <div className="loading" style={{ height: 420 }}>
            <Spin />
          </div>
        ) : !planTypes?.length ? (
          <div className="loading" style={{ height: 420 }}>
            No plans
          </div>
        ) : (
          <Radio.Group
            className="plantype-radio-group"
            value={planType}
            onChange={(e) => {
              setPlanType(e.target.value);
              setSelectedPlan(planTypes[e.target.value - 1]);
              setFormData({...formData, plan_id: e.target.value})
            }}
            disabled={!isEdit}
          >
            {
                planTypes?.map((type, i) => {
                        let costing = type?.rules?.find(item => item?.property === "cost");
                        return (
                                <Radio key={i} value={i+1} className='plantype-radio'>
                                        <div className='top-section'>
                                                <div className="top-left">
                                                        <p className="title">{type?.name}</p>
                                                        <p className="price">
                                                          {
                                                            isMobile ? 
                                                            <>${costing?.rule}/month</> :
                                                            <>Price: ${costing?.rule} per month</>
                                                          }
                                                          </p>
                                                </div>
                                                <div className="top-right">
                                                        {
                                                               (isEdit && planType === i+1) &&
                                                               <>
                                                               {
                                                                isMobile ?
                                                                <>
                                                                <Tooltip title="Customize">
                                                                  <Button 
                                                                    shape="circle" 
                                                                    icon={ <EditOutlined />} 
                                                                    onClick={() => {setSelectedPlan({...type, index: i}); setCustomizePopup(true)}}
                                                                  />
                                                                </Tooltip>
                                                                </> : 
                                                                <>
                                                                <Button
                                                                        type='default'
                                                                        onClick={() => {setSelectedPlan({...type, index: i}); setCustomizePopup(true)}}
                                                                >
                                                                        Customize Plan
                                                                </Button>
                                                                </>
                                                               }
                                                               </>
                                                        }
                                                        <DownOutlined style={{transform:  planType === i+1 ? "rotate(180deg)" : ""}} />
                                                </div>
                                        </div>
                                        <div className={planType === i+1 ? "bottom-section open" : "bottom-section"}>
                                                <div className="bottom">
                                                        {
                                                                type?.rules?.filter(item => item?.property !== "cost")
                                                                ?.map((rule, index) => (
                                                                        <Row key={index}>
                                                                                <Col span={13} className='type-label'>
                                                                                        {rule?.property?.replace(/_/g, " ")}
                                                                                </Col>
                                                                                <Col span={11} className='type-answer'>
                                                                                        {rule?.rule}{" "}
                                                                                        {
                                                                                                rule?.property === "social_media" && "Accounts"
                                                                                        }
                                                                                </Col>
                                                                        </Row>
                                                                ))
                                                        }
                                                </div>
                                        </div>
                                </Radio>
                        )
                })
            }
          </Radio.Group>
        )}
      </Card>
      <Card className="card form-card">
        <h3 className="form-card-title">Addons</h3>
        {loading?.plans ? (
          <div className="loading" style={{ height: 169 }}>
            <Spin />
          </div>
        ) : !addonsList?.length ? (
          <div className="loading" style={{ height: 169 }}>
            No Addons
          </div>
        ) : (
          <Checkbox.Group
            className="solutiontype-radio-group addon-checkbox-group"
            defaultValue={formData?.add_on_services}
            value={formData?.add_on_services}
            onChange={(e) => setFormData({ ...formData, add_on_services: e })}
            disabled={!isEdit}
          >
            {addonsList?.map((type, i) => (
              <Checkbox
                key={i}
                value={type?.value}
                className="solutiontype-radio addon-checkbox"
              >
                <div>
                  <p className="title">{type?.label}</p>
                </div>
              </Checkbox>
            ))}
          </Checkbox.Group>
        )}
      </Card>
      <Card className="card form-card">
        <h3 className="form-card-title">Plan Details</h3>
        <Form className="form" layout="vertical" autoComplete="off">
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label="Start Date" name="startDate">
                <DatePicker
                  size="large"
                  format={"MMMM DD, YYYY"}
                  style={{ width: "100%", height: 40 }}
                  value={dayjs(formData?.start_date)}
                  defaultValue={dayjs(formData?.start_date)}
                  onChange={(date) => setFormData({...formData, start_date: date})}
                  disabled={!isEdit}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="End Date" name="endDate">
                <DatePicker
                  size="large"
                  format={"MMMM DD, YYYY"}
                  style={{ width: "100%", height: 40 }}
                  value={dayjs(formData?.end_date)}
                  defaultValue={dayjs(formData?.end_date)}
                  onChange={(date) => setFormData({...formData, end_date: date})}
                  disabled={!isEdit}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Payment frequency" name="paymentFrequency">
                <Select
                  className="row-select"
                  options={[
                    {
                      value: "monthly",
                      label: "Monthly",
                    },
                    {
                      value: "yearly",
                      label: "Yearly",
                    },
                  ]}
                  defaultValue={formData?.payment_frequency}
                  onChange={(value) =>
                    setFormData({ ...formData, payment_frequency: value })
                  }
                  size="large"
                  disabled={!isEdit}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card className="card form-card">
        {
          // formData?.plan_id !==1 &&
          selectedPlan?.rules?.filter(item => item?.property === "account_manager")[0]?.rule?.includes("1") &&
          <>
            <h3 className="form-card-title" style={{ marginBottom: 10 }}>Select Support Manager</h3>
            <Form className="form" layout="vertical">
              <Form.Item>
                <Select
                  allowClear
                  placeholder="Select support manager"
                  defaultValue={supportManager}
                  value={supportManager}
                  onChange={(e) => setSupportManager(e)}
                  disabled={!isEdit}
                  size="large"
                >
                  {supportManagerList?.map((item, i) => (
                    <Select.Option value={item?.id} key={i}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </>
        }
        {formData?.solution_id !== 3 && (
          <>
            <h3 className="form-card-title" style={{ marginBottom: 10 }}>
              Select Investigators
            </h3>
            <Form className="form" layout="vertical">
              <Form.Item>
                <Select
                  placeholder="Select investigators"
                  mode="multiple"
                  allowClear
                  defaultValue={investigators}
                  value={investigators}
                  onChange={(e) => setInvestigators(e)}
                  disabled={!isEdit}
                  size="large"
                >
                  {investigatorsList?.map((item, i) => (
                    <Select.Option value={item?.id} key={i}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </>
        )}
        <h3 className="form-card-title" style={{ marginBottom: 10 }}>
          Select Legal
        </h3>
        <Form className="form" layout="vertical">
          <Form.Item>
            <Select
              placeholder="Select legal"
              mode="multiple"
              allowClear
              defaultValue={legal}
              value={legal}
              onChange={(e) => setLegal(e)}
              disabled={!isEdit}
              size="large"
            >
              {legalList?.map((item, i) => (
                <Select.Option value={item?.id} key={i}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {
        selectedPlan?.id &&
        <Modal 
                title={`Customize ${selectedPlan?.name} plan`} 
                open={customizePopup} 
                onCancel={() => setCustomizePopup(false)} 
                centered 
                okText="Customize"
                onOk={() => {customizePlanHandler()}}
        >
                <Form
                          className='form'
                          layout='vertical'
                          autoComplete="off"
                >
                        <Row gutter={24}>
                        {
                                selectedPlan?.rules?.filter(item => item?.property === 'cost')
                                .map((rule, i) => (
                                        <Col span={24} key={i}>
                                                <Form.Item label={rule.property?.replace(/_/g, " ")} style={{width: "47%"}}>
                                                        <Input 
                                                                value={rule?.rule} 
                                                                onChange={e => handleRuleChange(e.target.value, rule?.property)} 
                                                        />
                                                </Form.Item>
                                        </Col>
                                ))
                        }
                        {
                                selectedPlan?.rules?.filter(item => item?.property !== 'cost')
                                .map((rule, i) => (
                                        <Col span={12} key={i}>
                                                <Form.Item label={rule.property?.replace(/_/g, " ")}>
                                                {
                                                        (rule?.property === "scan_schedule") ?
                                                        <>
                                                                <Select
                                                                        value={rule?.rule}
                                                                        onChange={e => handleRuleChange(e, rule?.property)}
                                                                        placeholder="select scan schedule"
                                                                >
                                                                        <Select.Option value="daily">Daily</Select.Option>
                                                                        <Select.Option value="weekly">Weekly</Select.Option>
                                                                        <Select.Option value="bi-weekly">Bi weekly</Select.Option>
                                                                        <Select.Option value="monthly">Monthly</Select.Option>
                                                                </Select>
                                                        </> :
                                                        <Input 
                                                                value={rule?.rule} 
                                                                onChange={e => handleRuleChange(e.target.value, rule?.property)} 
                                                        />
                                                }
                                                </Form.Item>
                                        </Col>
                                )) 
                        }
                        </Row>
                </Form>
        </Modal>
      }
    </div>
  );
};

export default Plan;
