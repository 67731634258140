import React from 'react'
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  Path,
  PDFViewer
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  pageOne: {
        display: "flex",
    flexDirection: "column",
    backgroundColor: "#0B101B",
    padding: 30,
  },
  section: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  logo: {
    width: 80,
    marginTop: 100,
    marginBottom: 20,
  },
  title: {
        marginBottom: 20,
    fontSize: 32,
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  desc: {
        marginBottom: 16,
        fontSize: 14,
        lineHeight: 1.5,
        fontWeight: 400,
        color: "#FFFFFF",
        textAlign: "left"
  },
  smallTitle: {
        marginBottom: 4,
        color: "#374151",
        fontSize: 10,
        fontWeight: 400,
  },
  listingsCard: {
        marginBottom: 20,
        padding: 20,
        backgroundColor: "#FFFFFF",
        width: "48.5%",
        borderRadius: 10
  },
  listingCardNumberDiv: {
        marginTop: 24,
        flexDirection: "row",
        justifyContent: "space-between"
  },
  listingsPercentage: {
        marginRight: -12,
        padding: "4px 6px",
        flexDirection: "row",
        backgroundColor: "grey",
        borderRadius: 4,
        fontSize: 14,
        transform: "scale(0.7)"
  },
  listingsCardTwo: {
        marginBottom: 20,
        padding: 20,
        backgroundColor: "#FFFFFF",
        width: "22.5%",
        borderRadius: 10,
        flexDirection: "column",
        justifyContent: "space-between"
  },
});

const SvgIcon = ({color}) => (
        <Svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
                <Path d="M11.3333 5.83203H18M18 5.83203V12.4987M18 5.83203L11.3333 12.4987L8 9.16536L3 14.1654" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </Svg>
)

function numConvert (labelValue) 
{
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ?( Math.abs(Number(labelValue) / 1.0e+9)).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

    : (Math.abs(Number(labelValue))).toFixed(1);
  }

const Export = ({ data = {} }) => (
  <div style={{height: "80vh", overflow: "hidden"}}>
      <PDFViewer style={{height: "100%", width: "100%"}}>
        <Document>
          <Page size="A4" style={styles.pageOne}>
            <View style={styles.section}>
              <Image
                style={styles.logo}
                // src="https://picsum.photos/id/1/200/300"
                src={{uri: `${data?.clients[0]?.logo}`, method: "GET", headers: { "Cache-Control": "no-cache" }, body: ""}}
              />
              <Text style={styles.title}>
                {data?.clients[0]?.name}
              </Text>
              <View>
                <Text style={styles.desc}>
                        The company, <Text style={{textTransform: "capitalize"}}>{data?.clients[0]?.name}</Text>, operating under the legal name of <Text style={{textTransform: "capitalize"}}>{data?.clients[0]?.legal_name}</Text>, is a dynamic organization that offers a range of services.
                        They can be reached via the following contact information:
                </Text>
              </View>
              <View style={{flexDirection: "row", marginLeft: 0}}>
                <View style={{width: 100}}>
                  <Text style={styles.desc}>Email Id:</Text>
                </View>
                <View style={{width: 120}} wrap={true}>
                  <Text style={styles.desc}>{data?.clients[0]?.emails}</Text>
                </View>
              </View>
              <View style={{flexDirection: "row", marginLeft: 0}}>
                <View style={{width: 100}}>
                  <Text style={styles.desc}>Phone No.:</Text>
                </View>
                <View style={{width: 120}} wrap={true}>
                  <Text style={styles.desc}>{data?.clients[0]?.contacts}</Text>
                </View>
              </View>
              <Text style={styles.desc}>
                Their office is located at {data?.clients[0]?.address?.line}, {data?.clients[0]?.address?.city}, {data?.clients[0]?.address?.zipcode}, {data?.clients[0]?.address?.state}, {data?.clients[0]?.address?.country}.
                </Text>
            </View>
          </Page>
          <Page size="A4" style={styles.pageOne}>
              <Text
                      style={{
                              color: "#FFF"
                      }}
              >
                      Reports
              </Text>
              <View
                      style={{
                              marginTop: 20,
                              marginBottom: 20,
                              padding: 20,
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "#FFFFFF",
                              borderRadius: 10
                      }}
              >
                      <View
                              style={{
                                      width: "35%"
                              }}
                      >
                              <Text style={styles.smallTitle}>Risk Analysis</Text>
                              <Text
                                      style={{
                                              marginBottom: 6,
                                              color: data?.risk_analysis?.risk_score <= 20 ? "#0AA677" :data?.risk_analysis?.risk_score > 20 &&
                                              data?.risk_analysis?.risk_score <= 50 ? "#CA8A04" : "#DC2B2B",
                                              fontSize: "18px",
                                              fontWeight: 700,
                                              textTransform: "capitalize"
                                      }}
                              >
                                      {data?.risk_analysis?.counterfits} Risk Score
                              </Text>
                              <Text
                                      style={{
                                              fontSize: 50
                                      }}
                              >
                                      {data?.risk_analysis?.risk_score?.toFixed(0)}%
                              </Text>
                      </View>
                      <View
                              style={{
                                      width: "65%"
                              }}
                      >
                              <View
                                      style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              marginBottom: 20
                                      }}
                              >
                                      <Image
                                              style={{width: 24, height: 24, borderRadius: "100%"}}
                                              // src="https://static.vecteezy.com/packs/media/components/global/search-explore-nav/img/vectors/term-bg-1-666de2d941529c25aa511dc18d727160.jpg"
                                              src="./BI.png"
                                      />
                                      <View style={{marginLeft: 10}}>
                                              <Text style={styles.smallTitle}>Brand Impact</Text>
                                              <Text
                                                      style={{
                                                              marginBottom: 4,
                                                              color: "#374151",
                                                              fontSize: 12,
                                                              fontWeight: 400,
                                                              wordWrap: "break-word"
                                                      }}      
                                              >
                                                      <Text style={{
                                                        color: data?.risk_analysis?.risk_score <= 20 ? "#0AA677" :data?.risk_analysis?.risk_score > 20 &&
                                                        data?.risk_analysis?.risk_score <= 50 ? "#CA8A04" : "#DC2B2B", 
                                                        textTransform: "capitalize"
                                                        }}>{data?.risk_analysis?.counterfits} </Text>
                                                      counterfeits are damaging your brand reputation
                                              </Text>
                                      </View>
                              </View>
                              <View
                                      style={{
                                              display: "flex",
                                              flexDirection: "row",
                                      }}
                              >
                                      <Image
                                              style={{width: 24, height: 24, borderRadius: "100%"}}
                                              // src="https://static.vecteezy.com/packs/media/components/global/search-explore-nav/img/vectors/term-bg-1-666de2d941529c25aa511dc18d727160.jpg"
                                              src="./RI.png"
                                      />
                                      <View style={{marginLeft: 10}}>
                                              <Text style={styles.smallTitle}>Revenue Impact</Text>
                                              <Text
                                                      style={{
                                                              marginBottom: 4,
                                                              color: "#DC2B2B",
                                                              fontSize: 12,
                                                              fontWeight: 400,
                                                              wordWrap: "break-word"
                                                      }}  
                                              >
                                                      Estimated Loss of ~${numConvert(data?.risk_analysis?.revenue_impact)}
                                              </Text>
                                      </View>
                              </View>
                      </View>
              </View>
              <View
                      style={{
                              flexDirection: "row",
                              flexWrap: "wrap"
                      }}
              >
                      <View style={styles.listingsCard}>
                              <Text style={styles.smallTitle}>Listings Detected</Text>
                              <view style={styles.listingCardNumberDiv}>
                                      <Text>{data?.listings?.listing_detected}</Text>
                                      <View style={{...styles.listingsPercentage, color: "#f67906", backgroundColor: "#ffe7cd"}}>
                                              <SvgIcon color="#f67906" />
                                              <Text style={{marginLeft: 4}}>{data?.listings?.listing_detected_percent?.toFixed(2)}%</Text>
                                      </View>
                              </view>
                      </View>
                      <View style={{...styles.listingsCard, marginLeft: "3%"}}>
                              <Text style={styles.smallTitle}>Listings Flagged</Text>
                              <view style={styles.listingCardNumberDiv}>
                                      <Text>{data?.listings?.listing_flagged}</Text>
                                      <View style={{...styles.listingsPercentage, color: "#db2b2b", backgroundColor: "#fdeaec"}}>
                                              <SvgIcon color="#db2b2b" />
                                              <Text style={{marginLeft: 4}}>{data?.listings?.listing_flagged_percent?.toFixed(2)}%</Text>
                                      </View>
                              </view>
                      </View>
                      <View style={styles.listingsCard}>
                              <Text style={styles.smallTitle}>Listings Removed</Text>
                              <view style={styles.listingCardNumberDiv}>
                                      <Text>{data?.listings?.listing_removed}</Text>
                                      <View style={{...styles.listingsPercentage, color: "#895302", backgroundColor: "#ece6dc"}}>
                                              <SvgIcon color="#895302" />
                                              <Text style={{marginLeft: 4}}>{data?.listings?.listing_removed_percent?.toFixed(2)}%</Text>
                                      </View>
                              </view>
                      </View>
                      <View style={{...styles.listingsCard, marginLeft: "3%"}}>
                              <Text style={styles.smallTitle}>Revenue Saved</Text>
                              <view style={styles.listingCardNumberDiv}>
                                      <Text>{numConvert(data?.listings?.revenue_saved)}</Text>
                                      <View style={{...styles.listingsPercentage, color: "#0AA677", backgroundColor: "#eef9f6"}}>
                                              <SvgIcon color="#0AA677" />
                                              <Text style={{marginLeft: 4}}>{data?.listings?.revenue_saved_percent?.toFixed(2)}%</Text>
                                      </View>
                              </view>
                      </View>
              </View>
              <View style={{marginTop: 20}}>
                      <Text style={{color: "#FFF", marginBottom: 20}}>Listings Per Country</Text>
                      <View
                              style={{
                                      flexDirection: "row",
                                      flexWrap: "wrap"
                              }}
                      >
                        {
                          data?.listings_country_wise?.map((item, i) => (
                            <View key={i} style={{...styles.listingsCardTwo, marginLeft: (i % 4 === 0) ? "0%" : "3%"}}>
                              <Text style={styles.smallTitle}>{item?.name}</Text>
                              <Text>{item?.listings_no}</Text>
                            </View> 
                          ))
                        } 
                      </View>
              </View>
          </Page>
          <Page size="A4" style={styles.pageOne}>
              <Text
                      style={{
                              color: "#FFF",
                              marginBottom: 20
                      }}
              >
                      Seller Analysis
              </Text>
              <View
                style={{
                        flexDirection: "row",
                        flexWrap: "wrap"
                }}
              >
                {
                  Object.keys(data?.seller_analysis).map((key) => ({
                    name: key,
                    number: data?.seller_analysis[key],
                  })).map((item, i) => (
                    <View key={i} style={{...styles.listingsCardTwo, marginLeft: (i % 4 === 0) ? "0%" : "3%"}}>
                      <Text style={styles.smallTitle}>{item?.name}</Text>
                      <Text>{item?.number}</Text>
                    </View> 
                  ))
                } 
              </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
);

export default Export