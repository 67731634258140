import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as BackBtn } from "../../assets/images/back-icon-btn.svg";
import { ReactComponent as Action } from "../../assets/images/action.svg";
import { MainContext } from "../../utils/context";
import { apiUrl } from "../../utils/constants";
import TabPane from "antd/es/tabs/TabPane";
import moment from "moment";
import TableContainer from "../../components/TableContainer";
import { DeleteOutlined, EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import Info from "./Info";
import Plan from "./Plan";

const ClientDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token, logoutHandler, user } = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [getInvestigatorsLoading, setGetInvestigatorsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [tab, setTab] = useState("client_info");
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [cases, setCases] = useState();
  const [deleteItem, setDeleteItem] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [investigators, setInvestigators] = useState([]);
  const [legal, setLegal] = useState([]);
  const [supportManager, setSupportManager] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [investigatorLoading, setInvestigatorLoading] = useState(false);
  const [investigatorsList, setInvestigatorsList] = useState([]);
  const [legalList, setLegalList] = useState([]);
  const [supportManagerList, setSupportManagerList] = useState([]);
  const [addInvestigatorPopup, setAddInvestigatorPopup] = useState(false);
  const [addInvestigatorfor, setAddInvestigatorFor] = useState({});
  const [selectedInvestigator, setSelectedInvestigator] = useState(undefined);

  const items = [
    {
      key: "1",
      danger: true,
      label: (
        <a onClick={() => setDeleteConfirm(true)}>Delete {deleteItem?.name}</a>
      ),
    },
  ];

  const columns = [
    {
      title: "Case name",
      dataIndex: "name",
      render: (text, row) => <span>{text?.length ? text : "--"}</span>,
    },
    {
      title: "Case id",
      dataIndex: "case_id",
    },
    {
      title: "Investigator",
      dataIndex: "investigator",
      render: (text, row) => (
        <>
          {text?.id !== 0 ? (
            text?.name
          ) : user?.role_id === 3 ? (
            "No Investigator"
          ) : (
            <p
              className="add-investigator-link"
              onClick={() => {
                setAddInvestigatorPopup(true);
                setAddInvestigatorFor(row);
              }}
            >
              + Add investigator
            </p>
          )}
        </>
      ),
    },
    {
      title: "Created on",
      dataIndex: "created_on",
      render: (text) => <>{moment(text).format("MMMM D, YYYY")}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <Tag
          bordered={false}
          style={{
            padding: 4,
            width: "100%",
            textAlign: "center",
            fontSize: 14,
            fontWeight: 500,
            background:
              text?.toLowerCase() === "active" ? "#DEF2E6" : "#FFEFF0",
            color: text?.toLowerCase() === "active" ? "#11845B" : "#DC2B2B",
            borderRadius: 40,
          }}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, row) => (
        <>
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            trigger={["click"]}
            onClick={() => setDeleteItem(row)}
          >
            <Button type="link">
              <Action />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const getClientDetailsHandler = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("domains", "[]");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/client/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          setData(res?.client_details);
          setFormData(res?.client_details);
          setSelectedPlan({
            ...res?.client_details?.plan_rules[0],
            rules: res?.client_details?.plan_rules[0]?.rule,
          });
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const getCasesHandler = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/case/*?client_id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          const object = res.case_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          setCases(Object?.values(object));
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((res) => {
        setLoading(false);
        console.log("error", res);
      });
  };

  const deleteCaseHandler = () => {
    setDeleteLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/case/${deleteItem?.id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setDeleteLoading(false);
        setDeleteItem({});
        message.success(res?.message);
        setDeleteConfirm(false);
        getCasesHandler();
      })
      .catch((err) => {
        setDeleteLoading(false);
        console.log("error", err);
      });
  };

  const updateClientHandler = () => {
    setUpdating(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("_method", "patch");
    formdata.append("name", formData?.name);
    formdata.append("legal_name", formData?.legal_name);
    formdata.append("websites", JSON.stringify(formData?.websites));
    formdata.append("emails", JSON.stringify(formData?.emails));
    formdata.append("contacts", JSON.stringify(formData?.contacts));
    formdata.append("address", JSON.stringify(formData?.address));
    formdata.append("payment_frequency", formData?.payment_frequency);
    formdata.append("plan_id", JSON.stringify(formData?.plan_id));
    formdata.append(
      "start_date",
      moment(formData?.start_date).format("YYYY-MM-DD")
    );
    formdata.append(
      "end_date",
      moment(formData?.end_date).format("YYYY-MM-DD")
    );
    formdata.append("plan_rules", JSON.stringify(selectedPlan?.rules));
    formdata.append("solution_id", JSON.stringify(formData?.solution_id));
    if (formData?.add_on_services?.length) {
      formData?.add_on_services?.map((service) =>
        formdata.append("add_on_services[]", service)
      );
    } else {
      formdata.append("add_on_services[]", "");
    }
    formData?.logo?.name &&
      formdata.append("logo", formData?.logo, formData?.logo?.name);
    if (investigators?.length) {
      investigators?.map((investigator) =>
        formdata.append("investigators[]", investigator)
      );
    } else {
      formdata.append("investigators[]", "");
    }
    if (legal?.length) {
      legal?.map((legl) => formdata.append("legal[]", legl));
    } else {
      formdata.append("legal[]", "");
    }
    if (supportManager !== undefined) {
      formdata.append("manager_user_id[]", supportManager);
    } else {
      formdata.append("manager_user_id[]", "");
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/client/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setUpdating(false);
        if (res?.status === 1) {
          message.success(res?.message);
          setIsEdit(false);
          getClientDetailsHandler();
          setTab("client_info");
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((res) => {
        setUpdating(false);
        console.log("error", res);
      });
  };

  const getInvestigatorsHandler = () => {
    setInvestigatorLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/auth/list-users/*?user_type=touchstone&role_id=4&rows=1000&page=1&client_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setInvestigatorLoading(false);
        if (res?.status === 1) {
          const object = res.user_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          setInvestigatorsList(Object?.values(object));
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((res) => {
        setInvestigatorLoading(false);
        console.log("error", res);
      });
  };

  const getLegalHandler = () => {
    setInvestigatorLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/auth/list-users/*?user_type=touchstone&role_id=2&rows=1000&page=1&client_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setInvestigatorLoading(false);
        if (res?.status === 1) {
          const object = res.user_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          setLegalList(Object?.values(object));
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((res) => {
        setInvestigatorLoading(false);
        console.log("error", res);
      });
  };

  const getSupportManagersHandler = () => {
    setInvestigatorLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/auth/list-users/*?user_type=touchstone&role_id=6&rows=1000&page=1&client_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setInvestigatorLoading(false);
        if (res?.status === 1) {
          const object = res.user_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          setSupportManagerList(Object?.values(object));
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((res) => {
        setInvestigatorLoading(false);
        console.log("error", res);
      });
  };

  const addInvestigatorHandler = () => {
    setGetInvestigatorsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("_method", "patch");
    formdata.append("investigator", selectedInvestigator);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/case/${addInvestigatorfor?.id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setGetInvestigatorsLoading(false);
        if (res?.status === 1) {
          message.success("Investigator added successfully!");
          setAddInvestigatorPopup(false);
          setAddInvestigatorFor({});
          getCasesHandler();
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((res) => {
        setGetInvestigatorsLoading(false);
        console.log("error", res);
      });
  };

  useEffect(() => {
    if (!isEdit) {
      getClientDetailsHandler();
      getCasesHandler();
    }
  }, [id, isEdit]);

  useEffect(() => {
    getInvestigatorsHandler();
    getLegalHandler();
    getSupportManagersHandler();
  }, []);

  useEffect(() => {
    if (legalList?.length) {
      // formData?.legal_users?.map((item) => {
      //   legal.push(item?.id);
      //   setLegal(legal)
      // });
      setLegal(formData?.legal_users?.map((item) => item?.id));
    }
  }, [legalList]);

  useEffect(() => {
    if (investigatorsList?.length) {
      formData?.investigators?.map((item) => {
        investigators.push(item?.id);
        setInvestigators(investigators);
      });
    }
  }, [investigatorsList]);

  useEffect(() => {
    if (formData?.id && supportManagerList?.length) {
      setSupportManager(formData?.account_managers[0]?.id);
    }
  }, [formData, supportManagerList]);

  return (
    <div className="cases-div add-new-case-div add-new-client-div client-info-div">
      <Card className="card add-new-case-card">
        <Row gutter={10}>
          <Col xs={24} lg={12} style={{ display: "flex", alignItems: "center" }}>
            <BackBtn
              style={{ marginRight: 12, cursor: "pointer" }}
              onClick={() => navigate("/clients")}
            />
            <h3 className="page-title">Client Details: {data?.name}</h3>
          </Col>
          <Col xs={24} lg={12}>
            {user?.role_id === 5 ? (
              <></>
            ) : (
              <Space wrap style={{ float: "right" }}>
                {isEdit && (
                  <>
                  {
                    isMobile ? 
                    <>
                    <Tooltip title="Cancel">
                      <Button 
                        shape="circle" 
                        icon={ <CloseOutlined />} 
                        onClick={() => {
                          setIsEdit(false);
                        }}
                      />
                    </Tooltip>
                    </> : 
                    <>
                    <Button
                      size="large"
                      style={{ width: 88 }}
                      onClick={() => {
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                    </>
                  }
                  </>
                )}
                {
                  isMobile ?
                  <>
                  <Tooltip title={isEdit ? "Save" : "Edit"}>
                    <Button 
                      shape="circle" 
                      icon={ isEdit ? <SaveOutlined /> : <EditOutlined />} 
                      loading={isEdit ? updating : loading}
                      onClick={() => {
                        if (isEdit) {
                          updateClientHandler();
                        } else {
                          setIsEdit(true);
                        }
                      }}
                    />
                  </Tooltip>
                  </> :
                  <>
                  <Button
                    type="primary"
                    size="large"
                    style={{ width: 88 }}
                    onClick={() => {
                      if (isEdit) {
                        updateClientHandler();
                      } else {
                        setIsEdit(true);
                      }
                    }}
                    loading={isEdit ? updating : loading}
                  >
                    {isEdit ? "Update" : "Edit"}
                  </Button>
                  </>
                }
              </Space>
            )}
          </Col>
          <Col span={24}>
            <Tabs
              defaultActiveKey={tab}
              activeKey={tab}
              onChange={(e) => {
                setTab(e);
              }}
            >
              <TabPane tab="Client info" key="client_info" />
              <TabPane tab="Plan" key="plan" />
              <TabPane tab="Cases" key="cases" />
            </Tabs>
          </Col>
        </Row>
      </Card>
      <div className="content-body">
        {loading ? (
          <div
            className="loading-div"
            style={{
              height: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />
          </div>
        ) : tab === "client_info" ? (
          <>
            <Info
              data={data}
              formData={formData}
              setFormData={setFormData}
              isEdit={isEdit}
            />
          </>
        ) : tab === "plan" ? (
          <>
            <Plan
              data={data}
              formData={formData}
              setFormData={setFormData}
              isEdit={isEdit}
              investigators={investigators}
              setInvestigators={setInvestigators}
              legal={legal}
              setLegal={setLegal}
              supportManager={supportManager}
              setSupportManager={setSupportManager}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              investigatorLoading={investigatorLoading}
              investigatorsList={investigatorsList}
              legalList={legalList}
              supportManagerList={supportManagerList}
            />
          </>
        ) : tab === "cases" ? (
          <>
            <div className="cases-table-div">
              <TableContainer
                data={cases}
                columns={columns}
                loading={loading}
                isPagination={false}
                isSelectable={false}
              />
              <Modal
                title={`Delete ${deleteItem?.name}`}
                open={deleteConfirm}
                onCancel={() => {
                  setDeleteItem({});
                  setDeleteConfirm(false);
                }}
                footer={[]}
                className="case-options-modal delete-confirm-modal"
                width={384}
              >
                <DeleteOutlined
                  style={{ fontSize: 50 }}
                  className="delete-icon"
                />
                <h3 className="sub-title">Are you sure?</h3>
                <Button
                  type="default"
                  size="large"
                  className="btn"
                  onClick={() => {
                    setDeleteItem({});
                    setDeleteConfirm(false);
                  }}
                  loading={deleteLoading}
                >
                  No
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="btn confirm-btn"
                  onClick={deleteCaseHandler}
                  loading={deleteLoading}
                >
                  Yes, Delete it
                </Button>
              </Modal>
              <Modal
                open={addInvestigatorPopup}
                title={`Add investigator for ${addInvestigatorfor?.name}`}
                onCancel={() => {
                  setAddInvestigatorPopup(false);
                  setAddInvestigatorFor({});
                  setSelectedInvestigator(undefined);
                }}
                footer={[]}
                className="case-options-modal"
                width={500}
              >
                <Select
                  size="large"
                  placeholder="Select investigator..."
                  defaultValue={
                    addInvestigatorfor?.investigator?.id !== 0
                      ? addInvestigatorfor?.investigator?.id
                      : selectedInvestigator
                  }
                  value={
                    addInvestigatorfor?.investigator?.id !== 0
                      ? addInvestigatorfor?.investigator?.id
                      : selectedInvestigator
                  }
                  onChange={(e) => setSelectedInvestigator(e)}
                  style={{ width: "100%", marginTop: 16 }}
                  allowClear
                  loading={getInvestigatorsLoading}
                >
                  {investigatorsList?.map((inv, i) => (
                    <Select.Option key={i} value={inv?.id}>
                      {inv?.name}
                    </Select.Option>
                  ))}
                </Select>
                <div style={{ textAlign: "right", marginTop: 24 }}>
                  <Button
                    type="primary"
                    size="large"
                    loading={getInvestigatorsLoading}
                    onClick={() => addInvestigatorHandler()}
                  >
                    Add investigator
                  </Button>
                </div>
              </Modal>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ClientDetails;
