import React, {createContext, useState, useEffect} from 'react'
import axios from 'axios'
import {apiUrl} from './constants'
import { message } from 'antd'

export const storeData = (type, value) => {
        localStorage.setItem(type, JSON.stringify(value))
}

export const fetchData = (type) => {
        if(localStorage.getItem(type)) return JSON.parse(localStorage.getItem(type))
        return ''
}

const MainContext = createContext()
const MainContextProvider = ({children}) => {

        const [user, setUser] = useState(fetchData('user') || {});
        const [token, setToken] = useState(fetchData('token') || '');
        const [allStatus, setAllStatus] = useState(fetchData('allStatus') || []);
        const [newCaseClientType, setNewCaseClientType] =  useState("new");
        const [tokenRefreshing, setTokenRefreshing] = useState(true);
        const [notifications, setNotifications] = useState([]);
        const [notificationLoading, setNotificationLoading] = useState(false);
        const [unmarkedNotifications, setUnmarkedNotifications] = useState(0);

        const [assets, setAssets] = useState([
                {
                        name: "",
                        keywords: []
                }
        ]);

        const [rules, setRules] = useState([]);

        const [countryList, setCountryList] = useState([]);

        const [totalRegion, setTotalRegion] = useState(0);

        const logoutHandler = (status) => {
                setUser({});
                setToken("");
                setAllStatus([]);
                if(status === "401") {
                        message.error("Session expired, please login again");     
                }
                else {
                        message.success("Logged out successfully!");
                        window.location.href="/";
                }
        }

        const getNotificationsHandler = () => {
                setNotificationLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/notifications?status=unmarked`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setNotificationLoading(false);
                        if(res?.status === 1) {
                                setNotifications(res?.data);
                                setUnmarkedNotifications(res?.total);
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        setNotificationLoading(false);
                        console.log('error', res)
                });
        }
        const updateNotificationHandler = (notification) => {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                "notification_id": notification?.id,
                "seen": 1
                });

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/notifications`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        if(res?.status === 1) {
                                console.log(res);
                                getNotificationsHandler();
                        //        navigate(`/cases/${notification?.data?.case_id}?category=${notification?.data?.area}&status=${notification?.data?.status}`)
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        console.log('error', res)
                });
        }

        useEffect(() => storeData("user", user), [user]);
        useEffect(() => storeData("token", token), [token]);
        useEffect(() => storeData("allStatus", allStatus), [allStatus]);

        useEffect(() => {
                if(token?.length) {
                        getNotificationsHandler();
                }
        }, [token]);
        
        return (
                <MainContext.Provider
                        value={{
                                storeData,
                                fetchData,
                                user,
                                setUser,
                                token,
                                setToken,
                                allStatus,
                                setAllStatus,
                                newCaseClientType,
                                setNewCaseClientType,
                                assets,
                                setAssets,
                                rules,
                                setRules,
                                countryList,
                                setCountryList,
                                totalRegion,
                                setTotalRegion,
                                tokenRefreshing,
                                setTokenRefreshing,
                                logoutHandler,
                                notifications,
                                getNotificationsHandler,
                                updateNotificationHandler,
                                unmarkedNotifications,
                                setUnmarkedNotifications,
                        }}
                >
                        {children}
                </MainContext.Provider>
        )
}

export { MainContext, MainContextProvider };