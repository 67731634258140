import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  message,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { apiUrl } from "../../utils/constants";
import { MainContext } from "../../utils/context";
import moment from "moment";
import Export from "./Export";
import { PDFViewer } from '@react-pdf/renderer';

const Reports = () => {
  const { token, logoutHandler, allStatus, user } = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [casesLoading, setCasesLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [clientsList, setClientList] = useState([]);
  const [casesList, setCasesList] = useState([]);
  const [filteredCasesList, setFilteredCasesList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [exportScreen, setExportScreen] = useState(false);
  const [exportData, setExportData] = useState({});

  const [formData, setFormData] = useState({
    clientId: undefined,
    caseId: [],
    assets: [],
    category: [],
    status: [],
    startDate: "",
    endDate: "",
  });

  const getClientList = () => {
    setClientsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/client/*?page=1&rows=10000`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        setClientsLoading(false);
        if(res?.status === 1) {
          const object = res.client_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          const arr = Object.values(object)?.filter(item =>item?.activeCasesCount > 0 )?.map(item => ({value: item?.id, label: item?.name}));
          setClientList([...clientsList, ...arr]);

        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch(res => {
        setClientsLoading(false);
        console.log('error', res)
      });
  }

  const getCasesHandler = () => {
    setCasesLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/case/*?search_status[]=active&search_status[]=partial`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setCasesLoading(false);
        if (res?.status === 1) {
          const object = res.case_details;
          // setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          const newArr = Object.values(object)?.map((item) => ({
            label: item?.name,
            value: item?.id,
            assets: item?.keyword?.map(it => ({label: it?.name, value: it?.name})),
            clientId: item?.client_id
          }));
          setCasesList(newArr);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((res) => {
        setCasesLoading(false);
        console.log("error", res);
        message.error("Something went wrong!");
      });
  };

  const getCategoriesHandler = () => {
    setCategoriesLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/categories/*`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setCategoriesLoading(false);
        if (res?.status === 1) {
          const object = res.category_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          setCategoryList(
            Object.values(object)?.map((cat) => ({
              value: cat?.id,
              label: cat?.name,
            }))
          );
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((error) => {
        setCategoriesLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const generateHandler = () => {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
  
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
  
      fetch(`${apiUrl}/reports?cl=${formData?.clientId}&c=${formData?.caseId}&cat=${formData?.category}&a=${formData?.assets}&s=${formData?.status}&start_date=${formData?.startDate}&end_date=${formData?.endDate}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res?.status === 1) {
            setFormData({
              caseId: undefined,
              category: undefined,
              status: undefined,
              startDate: '',
              endDate: ''
            })
            setExportScreen(true);
            setExportData(res?.data);
          }
          else if(res?.api_status ==='401') {
            logoutHandler("401");
            }
            else {
                    message.error(res?.email[0]);
            }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
          message.error("Something went wrong!");
        });
    }

  useEffect(() => {
    getClientList();
    getCasesHandler();
    getCategoriesHandler();
  }, []);

  return (
    <div className="reports-div cases-div add-new-case-div add-new-client-div client-info-div">
      <Card className="card add-new-case-card reports-card">
        <Row gutter={10} style={{ marginBottom: 24 }}>
          <Col
            xs={24}
            lg={24}
            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <h3 className="page-title">
              {exportScreen ? "Download" : "Generate"} Reports
            </h3>
            {
              exportScreen &&
              <Button onClick={() => setExportScreen(false)}>
                Regenerate report
              </Button>
            }
          </Col>
        </Row>
      </Card>
      <div className="content-body">
        {
          exportScreen ?
          <Export data={exportData} /> :
          <Card className="reports-form-card">
            <Form layout="vertical">
              <Row gutter={16}>
                <Col xs={24} lg={24}>
                  <FormItem label="Client">
                    <Select
                      size="large"
                      placeholder="Select client"
                      loading={clientsLoading}
                      disabled={loading}
                      value={formData?.clientId}
                      defaultValue={formData?.clientId}
                      onChange={(e) => {
                        setFormData({ 
                          ...formData, 
                          clientId: e,
                          caseId: [],
                          assets: [],
                          category: [],
                          status: [],
                          startDate: "",
                          endDate: ""
                        });
                        let newArr = casesList?.filter(item => item?.clientId === e);
                        setFilteredCasesList(newArr);

                      }}
                    >
                      {clientsList?.map((item, i) => (
                        <Select.Option 
                          key={i} 
                          value={item?.value}
                        >
                          {item?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} lg={24}>
                  <FormItem label="Case">
                    <Select
                      size="large"
                      placeholder="Select case/cases"
                      mode="multiple"
                      loading={casesLoading}
                      disabled={loading}
                      value={formData?.caseId}
                      defaultValue={formData?.caseId}
                      onChange={(e) => {
                        setFormData({ ...formData, caseId: e });
                        let newArr = filteredCasesList.filter(item => e.includes(item?.value));
                        const newMergedAssets = [];

                        for (const object of newArr) {
                          newMergedAssets.push(...object.assets);
                        }
                        setAssetsList(newMergedAssets);
                      }}
                    >
                      {filteredCasesList?.map((item, i) => (
                        <Select.Option key={i} value={item?.value}>
                          {item?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} lg={24}>
                  <FormItem label="Assets">
                    <Select
                      size="large"
                      placeholder="Select asset/assets"
                      mode="multiple"
                      loading={casesLoading}
                      disabled={loading}
                      value={formData?.assets}
                      defaultValue={formData?.assets}
                      onChange={(e) => setFormData({ ...formData, assets: e })}
                    >
                      {assetsList?.map((item, i) => (
                        <Select.Option key={i} value={item?.value}>
                          {item?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} lg={24}>
                  <FormItem label="Category">
                    <Select
                      size="large"
                      placeholder="Select category/categories"
                      mode="multiple"
                      loading={categoriesLoading}
                      disabled={loading}
                      value={formData?.category}
                      defaultValue={formData?.category}
                      onChange={(e) => setFormData({ ...formData, category: e })}
                    >
                      {categoryList?.map((item, i) => (
                        <Select.Option key={i} value={item?.value}>
                          {item?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} lg={24}>
                  <FormItem label="Status">
                    <Select
                      size="large"
                      placeholder="Select status"
                      mode="multiple"
                      disabled={loading}
                      value={formData?.status}
                      defaultValue={formData?.status}
                      onChange={(e) => setFormData({ ...formData, status: e })}
                    >
                      {allStatus?.map((item, i) => (
                        <Select.Option key={i} value={item?.code}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} lg={24}>
                  <FormItem label="Date">
                    <DatePicker.RangePicker
                      size="large"
                      defaultValue={[formData.startDate, formData.endDate]}
                      format="DD MMM YYYY"
                      onChange={(date, dateString) => {
                        setFormData({
                          ...formData,
                          startDate: moment(dateString[0]).format("YYYY-MM-DD"),
                          endDate: moment(dateString[1]).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <div className="button-div">
                <Button 
                size="large" 
                type="primary"
                loading={loading}
                onClick={() => generateHandler()}
                disabled={
                  formData?.clientId === undefined ||
                  // formData?.caseId === [] ||
                  // formData?.category === [] ||
                  // formData?.status === [] ||
                  formData?.startDate === '' ||
                  formData?.endDate === ''
                }
                >
                  Generate report
                </Button>
              </div>
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};

export default Reports;
