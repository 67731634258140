import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Collapse, DatePicker, Form, Image, Input, Modal, Row, Select, Space, Spin, Tabs, Tag, message } from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import TabPane from 'antd/es/tabs/TabPane';
import { ReactComponent as VisualSearch } from "../../assets/images/visual-search-icon-2.svg";
import { ReactComponent as TestPurchase } from "../../assets/images/test-purchase-no-steps.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/link.svg";
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { MainContext } from '../../utils/context';
import moment from 'moment';
import { apiUrl } from '../../utils/constants';
import { useNavigate } from 'react-router';

const ProductDetailsModal = ({
        selectedProduct = {},
        setSelectedProduct = () => {},
        selectedProuctStatus = "",
        setSelectedProductStatus = () => {},
        caseId = "",
        fetchDataHandler = () => {}
}) => {

        const navigate = useNavigate();

        const {user, allStatus, token, logoutHandler} = useContext(MainContext)

        const [loading, setLoading] = useState(false);
        const [testPurchaseLoading, setTestPurchaseLoading] = useState(false);
        const [addActivityLoading, setAddActivityLoading] = useState(false);
        const [uploading, setUploading] = useState(false);

        const [imageIndex, setImageIndex] = useState(0);
        const [checkboxGrp, setCheckboxGrp] = useState([
                "Brand name / Logo misuse",
                "Copyright",
                "Replica",
                "Counterfeit",
                "Other",
              ]);
        const [checkedList, setCheckedList] = useState([]);
        const [checkboxGrpTwo, setCheckboxGrpTwo] = useState([
                "Report all the listings of this seller",
                "Report Seller",
        ]);
        const [otherInfringementType, setOtherInfringementType] = useState("");
        const [checkedListTwo, setCheckedListTwo] = useState([]);
        const [modalTab, setModalTab] = useState("listing_details"); 
        const [testPurchaseSteps, setTestPurchaseSteps] = useState([]);
        const [testInitiated, setTestInitiated] = useState(false);
        const [newStepData, setNewStepData] = useState({
        activity: "",
        description: "",
        date: dayjs().format("YYYY-MM-DD"),
        insights: ""
        });
        
        const getTestPurchases = () => {
                setTestPurchaseLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("domains", "[]");
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var formdata = new FormData();
                formdata.append("case_id", caseId);
                formdata.append("unique_id", selectedProduct?.unique_id);
            
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: formdata,
                  redirect: "follow",
                };
            
                fetch(`${apiUrl}/get-test-purchase/*`, requestOptions)
                  .then((response) => response.json())
                  .then((res) => {
                    setTestPurchaseLoading(false);
                    if (res?.status === 1) {
                      const object = res?.test_purchase_details;
                //       setTotal(object?.total);
                      delete object.page;
                      delete object.rows;
                      delete object.total;
                      setTestPurchaseSteps(Object?.values(object));
                    }
                    else if(res?.api_status ==='401') {
                      logoutHandler("401");
                    }
                    else {
                            message.error(res?.email[0]);
                    }
                  })
                  .catch((error) => {
                    setTestPurchaseLoading(false);
                    console.log("error", error);
                    message.error("Something went wrong!");
                  });
        }

        const updateSellerHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var formdata = new FormData();
                formdata.append(
                  "about", "amazon");
                formdata.append("case_id", caseId);
                formdata.append("unique_id", checkedListTwo?.length ? selectedProduct?.seller?.id : selectedProduct?.uid);
                formdata.append("seller_flag", "0");
                formdata.append("seller_status", selectedProuctStatus);
                checkedList?.map(item => formdata.append("reporting_for[]", item))
                checkedListTwo?.map(item => formdata.append("report_action[]", item))
                if(otherInfringementType?.length > 0) {
                  formdata.append("other_infringement_type", otherInfringementType);
                }
            
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: formdata,
                  redirect: "follow",
                };
            
                fetch(`${apiUrl}/update-seller`, requestOptions)
                  .then((response) => response.json())
                  .then((res) => {
                    setLoading(false);
                    if (res?.status === 1) {
                      message.success(res?.message);
                      setSelectedProduct({});
                      fetchDataHandler();
                    }
                    else if(res?.api_status ==='401') {
                      logoutHandler("401");
              }
              else {
                      message.error(res?.email[0]);
              }
                  })
                  .catch((error) => {
                    setLoading(false);
                    console.log("error", error);
                    message.error("Something went wrong!");
                  });
        };

        const addActivityHandler = () => {
                setAddActivityLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var formdata = new FormData();
                formdata.append("case_id", caseId);
                formdata.append("unique_id", selectedProduct?.unique_id);
                formdata.append("activity", newStepData?.activity);
                formdata.append("description", newStepData?.description);
                formdata.append("date", newStepData?.date);
                formdata.append("insight", newStepData?.insights);
            
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: formdata,
                  redirect: 'follow'
                };
            
                fetch(`${apiUrl}/test-purchase`, requestOptions)
                  .then((response) => response.json())
                  .then((res) => {
                    setAddActivityLoading(false);
                    if (res?.status === 1) {
                      message.success(res?.message);
                      setTestPurchaseSteps([...testPurchaseSteps, res?.test_purchase_details]);
                      setTestInitiated(false);
                    }
                    else if(res?.api_status ==='401') {
                      logoutHandler("401");
              }
              else {
                      message.error(res?.email[0]);
              }
                  })
                  .catch((error) => {
                    setAddActivityLoading(false);
                    console.log("error", error);
                    message.error("Something went wrong!");
                  });
        };

        const initiateVisualSearchHandler = () => {
                setUploading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var formdata = new FormData();
                formdata.append("case_id", caseId);
                formdata.append("unique_id", "");
                formdata.append("file_url[]", selectedProduct?.image[imageIndex]);
            
                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
                };
            
                fetch(`${apiUrl}/image-search-initiate`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setUploading(false);
                        if(res?.status === 1) {
                                console.log(res);
                                message.success(res?.message);
                                setTimeout(() => {
                                  navigate(`/visual-search/${res?.imageDetails?.id}`);
                                }, 3000);
                        }
                        else if(res?.api_status ==='401') {
                          logoutHandler("401");
                  }
                  else {
                          message.error(res?.email[0]);
                  }
                })
                .catch(res => {
                        setUploading(false);
                        console.log('error', res)
                });
        }

        useEffect(() => {
                if (selectedProduct?.id) {
                        setSelectedProductStatus(selectedProduct?.status);
                        setCheckedList(selectedProduct?.reporting_for);
                        setCheckedListTwo(selectedProduct?.report_action);
                        setOtherInfringementType(selectedProduct?.other_infringement_type);
                } else {
                        setSelectedProductStatus("");
                        setCheckedList([]);
                        setCheckedListTwo([]);
                        setOtherInfringementType("");
                }
        }, [selectedProduct]);

        useEffect(() => {
                if(modalTab === "test_purchase") {
                  getTestPurchases()
                }
        }, [modalTab])

  return (
    <div>
        <Modal
        open={selectedProduct?.id}
        onCancel={() => {
          setSelectedProduct({});
          setImageIndex(0);
        }}
        footer={[]}
        title="Listing Details"
        width={1195}
        className="listing-details-modal"
      >
        <Tabs defaultActiveKey={modalTab} onChange={e => setModalTab(e)}>
          <TabPane tab="Listing  details" key="listing_details" />
          <TabPane tab="Test purchase" key="test_purchase" />
        </Tabs>
        {
          modalTab === "listing_details" ?
          <Row className="listing-details-row">
            <Col span={13}>
              <div style={{position: "sticky", top: 10}}>
                {
                        selectedProduct?.image?.length &&
                        <VisualSearch className="visual-search-btn" onClick={() => initiateVisualSearchHandler()} />
                }
                <Image
                  src={
                    selectedProduct?.image?.length &&
                    selectedProduct?.image[imageIndex]
                  }
                  width={552}
                  height={450}
                />
                <div className="preview-grid-div">
                  {selectedProduct?.image?.length &&
                    selectedProduct?.image?.map((src, i) => (
                      <img
                        src={src}
                        alt="src"
                        className={
                          imageIndex === i
                            ? "preview-grid-item preview-grid-item-active"
                            : "preview-grid-item"
                        }
                        key={i}
                        onClick={() => setImageIndex(i)}
                      />
                    ))}
                </div>
              </div>
            </Col>
            <Col span={11}>
              <div className={`status-div ${selectedProduct?.status}`}>
                <Tag className={`${selectedProduct?.status}`}>
                  {selectedProduct?.status}
                </Tag>
                <p className="date">
                  {setSelectedProduct?.created_at}
                </p>
              </div>
              <p className="title">{selectedProduct?.title}</p>
              <p className="desc">
                {
                  parse(`${selectedProduct?.description}`)
                }
                </p>
              <a
                href={selectedProduct?.link}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                visit link <LinkIcon />
              </a>
              <hr />
              <p className="line">
                Original price: <span>{selectedProduct?.price}</span>
              </p>
              <p className="line">
                Converted price:{" "}
                <span>${selectedProduct?.converted?.value?.toFixed(2)}</span>
              </p>
              <hr />
              <p className="line">
                Seller:{" "}
                <a
                  href={`/seller/${
                    selectedProduct?.unique_id
                  }?case_id=${caseId}&about=amazon`}
                  rel="noreferrer"
                >
                  {selectedProduct?.seller?.name}
                </a>
              </p>
              <p className="line">
                Platform:{" "}
                <a
                  href={`https://${selectedProduct?.platform}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectedProduct?.platform}
                </a>
              </p>
              <hr />
              <Form layout="vertical">
                <Form.Item label= {user?.role_id ===3 ? "Status" : "Take action"}>
                  <Select
                    size="large"
                    value={selectedProuctStatus}
                    onChange={(e) => setSelectedProductStatus(e)}
                    disabled={user?.role_id ===3}
                  >
                    {
                      allStatus?.map((status) => (
                        <Select.Option value={status?.code}>{status?.name}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                  <>
                  <hr />
                  <Form.Item label="Why are you reporting this?">
                    <Checkbox.Group
                      options={checkboxGrp}
                      value={checkedList}
                      onChange={(list) => setCheckedList(list)}
                      disabled={user?.role_id ===3}
                    />
                  </Form.Item>
                  {
                    checkedList?.includes("Other") &&
                    <Form.Item style={{marginTop: -28, marginLeft: 20}}>
                      <Input
                        placeholder="Type other infringement type here..."
                        size="large"
                        value={otherInfringementType}
                        onChange={e => setOtherInfringementType(e.target.value)}
                      />
                    </Form.Item>
                  }
                  <hr />
                  <Form.Item>
                    <Checkbox.Group
                      options={checkboxGrpTwo}
                      value={checkedListTwo}
                      onChange={(list) => setCheckedListTwo(list)}
                      disabled={user?.role_id ===3}
                    />
                  </Form.Item>
                  </>
              </Form>

              {
                user?.role_id !== 3 &&
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={() => updateSellerHandler()}
                  loading={loading}
                >
                  Update
                </Button>
              }
            </Col>
          </Row> :
          modalTab === "test_purchase" ?
          <>
            {
              testPurchaseLoading ?
              <div className="loading" style={{height: "80%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Spin size="large" />
              </div> :
              (testPurchaseSteps?.length === 0 && !testInitiated) ?
              <>
              <div className="no-steps-div">
                <TestPurchase />
                <p>Test purchase this item</p>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => setTestInitiated(true)}
                >
                  Initiate test purchase
                </Button>
              </div>
              </> : 
              <>
              <div className="steps-div">
                  {
                    testPurchaseSteps?.map((step, i) => (
                      <Collapse ghost expandIconPosition="end" className='steps collapse-steps' key={i}>
                        <div className="progress-indicator"></div>
                        <Collapse.Panel 
                          header={
                            <p className="title">{step?.activity}</p>
                          } 
                        >
                          <div className="desc">
                            <p className="label">Description</p>
                            <p className="value">
                              {step?.description}
                            </p>
                          </div>
                          {
                            step?.insight?.length > 0 &&
                            <div className="desc">
                              <p className="label">Insight</p>
                              <p className="value">
                                {step?.insight}
                              </p>
                            </div>
                          }
                        </Collapse.Panel>
                        <p className="date">{moment(step?.date).format("DD MMM YYYY")}</p>
                      </Collapse>
                    ))
                  }
                {
                  testInitiated ?
                  <>
                  <div className="steps">
                    <div className="progress-indicator"></div>
                    <Form
                      className="form"
                      layout='vertical'
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Activity"
                        name="activity"
                      >
                        <Select
                          placeholder="Select activity"
                          size="large"
                          options={[
                            {
                              value: "initiated test purchase",
                              label: "Initiated test purchase",
                            },
                            {
                              value: "order placed",
                              label: "Order placed",
                            },
                            {
                              value: "product received",
                              label: "Product received",
                            },
                            {
                              value: "product reviewed",
                              label: "Product reviewed",
                            },
                            {
                              value: "product not delivered",
                              label: "Product not delivered",
                            },
                            {
                              value: "test purchased cancelled",
                              label: "Test purchased cancelled",
                            }
                          ]}
                          defaultValue={newStepData?.activity}
                          value={newStepData?.activity}
                          onChange={e => setNewStepData({...newStepData, activity: e})}
                        />
                      </Form.Item>
                      <Form.Item
                              label="Description"
                              name="description"
                      >
                        <Input.TextArea
                          rows={4}
                          placeholder="Type here...."
                          maxLength={250}
                          size="large"
                          defaultValue={newStepData?.description}
                          value={newStepData?.description}
                          onChange={e => setNewStepData({...newStepData, description: e.target.value})}
                        />
                      </Form.Item>
                      <Form.Item
                              label="Date"
                              name="date"
                      >
                        <DatePicker
                                size="large"
                                format={"DD MMM YYYY"}
                                style={{width: "100%", height: 40}}
                                defaultValue={dayjs()}
                                onChange={(date, dateString) => setNewStepData({...newStepData, date: moment(dateString).format("YYYY-MM-DD")})}
                                disabled
                        />
                      </Form.Item>
                      <Form.Item
                              label="Insights"
                              name="insights"
                      >
                        <Input.TextArea
                          rows={4}
                          placeholder="Type here...."
                          maxLength={250}
                          size="large"
                          defaultValue={newStepData?.insights}
                          value={newStepData?.insights}
                          onChange={e => setNewStepData({...newStepData, insights: e.target.value})}
                        />
                      </Form.Item>
                      <Space>
                        <Button
                          type="primary"
                          size="large"
                          disabled = {!newStepData?.activity?.length || !newStepData?.description?.length}
                          onClick={() => addActivityHandler()}
                          loading={addActivityLoading}
                        >
                          Add activity
                        </Button>
                        <Button
                          type="link"
                          size="large"
                          danger
                          onClick={() => setTestInitiated(false)}
                        >
                          Cancel
                        </Button>
                      </Space>
                    </Form>
                  </div>
                  </> : 
                  <>
                  <div>
                    <Button
                      type="default"
                      icon={<PlusOutlined />}
                      className="add-activity-btn"
                      onClick={() => setTestInitiated(true)}
                    >
                      Add activity
                    </Button>
                  </div>
                  </>
                }
              </div>
              </>
            }
          </> :
          <>No tab</>
        }
      </Modal>
    </div>
  )
}

export default ProductDetailsModal