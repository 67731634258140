import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Pagination,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  message,
} from "antd";
import { SearchOutlined, PlusOutlined, CloseOutlined, ReloadOutlined, FlagFilled, MoreOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import { MainContext } from "../../utils/context";
import { apiUrl } from "../../utils/constants";
import { Link, useSearchParams } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";
import { ReactComponent as SelectIcon } from "../../assets/images/select-icon.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/link.svg";
import NoImage from "../../assets/images/no-image.jpeg";
import { ReactComponent as BackBtn } from "../../assets/images/back-icon-btn.svg";
import { ReactComponent as Filter } from "../../assets/images/filter.svg";
import { ReactComponent as Export } from "../../assets/images/export.svg";
import { ReactComponent as GridIcon } from "../../assets/images/grid.svg";
import { ReactComponent as ListIcon } from "../../assets/images/list.svg";
import TableContainer from "../../components/TableContainer";
import { ReactComponent as Action } from "../../assets/images/action.svg";
import { ReactComponent as TestPurchase } from "../../assets/images/test-purchase-no-steps.svg";
import { ReactComponent as Takedown } from "../../assets/images/takedown.svg";
import { ReactComponent as VisualSearch } from "../../assets/images/visual-search-icon-2.svg";
import { ReactComponent as NumberAlert } from "../../assets/images/number-alert.svg";
import { ReactComponent as ImportIcon } from "../../assets/images/Import-icon.svg";
import { ReactComponent as Options } from '../../assets/images/dropzone-options.svg';
import dayjs from "dayjs";
import moment from "moment/moment";
import parse from 'html-react-parser';
import NoData from "../../components/NoData";

const CaseListings = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = window.innerWidth < 767;

  const queryParams = new URLSearchParams(window.location.search);
  const currentCategory = queryParams.get("category") || "marketplace";

  const [catArr, setCatArr] = useState([
    {
      tab: "Marketplace",
      key: "marketplace"
    },
    {
      tab: "Sellers",
      key: "seller"
    },
    {
      tab: "Search engine",
      key: "search engine"
    },
    {
      tab: "Social media",
      key: "social media"
    },
  ]);

  const { token, user, setUser, setToken, allStatus, logoutHandler } = useContext(MainContext);

  const [mainLoading, setMainLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [importPopup, setImportPopup] = useState(false);
  const [importHistoryPopup, setImportHistoryPopup] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [importing, setImporting] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [recrawlLoading, setRecrawlLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [automatedtakedownLoading, setAutomatedtakedownLoading] = useState(false);
  const [testPurchaseLoading, setTestPurchaseLoading] = useState(false);
  const [addActivityLoading, setAddActivityLoading] = useState(false);
  const [automatedTakedownPopup, setAutomatedTakedownPopup] = useState(false);
  const [numberAlert, setNumberAlert] = useState(true);
  const [recrawlModal, setRecrawlModal] = useState(false);
  const [recrawlPeriod, setRecrawlperiod] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [marketplaceFilter, setMarketplaceFilter] = useState([]);
  const [recrawlData, setRecrawlData] = useState([]); 
  const [multipleListingsUpdateStatus, setMultipleListingsUpdateStatus] = useState(allStatus[0]?.code || "");
  const [recrawlUpdating, setRecrawlUpdating] = useState(false);
  const [listingType, setListingType] = useState("grid");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);
  const [searchStr, setSearchStr] = useState(queryParams.get("search_str") || "");
  const [statusFilter, setStatusFilter] = useState(queryParams.get("status") || allStatus[0]?.code);
  const [countryFilter, setCountryFilter] = useState(queryParams.get("country") || "");
  const [reportingFor, setReportingFor] = useState([queryParams.get("reporting_for")] || []);
  const [countryList, setCountryList] = useState([{label: "All countries", value: ""}]);
  const [asset, setAsset] = useState(queryParams.get("asset") || "");
  const [flag, setFlag] = useState(queryParams.get("flag") || "");
  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedSeller, setSelectedSeller] = useState({});
  const [selectedProuctStatus, setSelectedProductStatus] = useState("");
  const [selectedSellerStatus, setSelectedSellerStatus] = useState("pending");
  const [casesList, setCasesList] = useState([]);
  const [selectedCase, setSelectedCase] = useState({});
  const [imageIndex, setImageIndex] = useState(0);
  const [checkboxGrp, setCheckboxGrp] = useState([
    "Brand name / Logo misuse",
    "Copyright",
    "Replica",
    "Counterfeit",
    "Other",
  ]);
  const [checkedList, setCheckedList] = useState([]);
  const [checkboxGrpTwo, setCheckboxGrpTwo] = useState([
    "Report all the listings of this seller",
    "Report Seller",
  ]);
  const [otherInfringementType, setOtherInfringementType] = useState("");
  const [checkedListTwo, setCheckedListTwo] = useState([]);
  const [caseDetails, setCaseDetails] = useState({});
  const [modalTab, setModalTab] = useState("listing_details"); 
  const [testPurchaseSteps, setTestPurchaseSteps] = useState([]);
  const [testInitiated, setTestInitiated] = useState(false);
  const [newStepData, setNewStepData] = useState({
    activity: "",
    description: "",
    date: dayjs().format("YYYY-MM-DD"),
    insights: ""
  });

  const [editStepData, setEditStepData] = useState({
    activity: "",
    description: "",
    date: "",
    insights: ""
  })

  const [automatedTakedownData, setAutomatedTakedownData] = useState({
    type: "less than",
    asset: undefined,
    price: 0
  });

  const [importData, setImportData] = useState([]);
  const [importDataLoading, setImportDataLoading] = useState(false);
  const [importDataPage, setImportDataPage] = useState(1);
  const [importDataLimit, setImportDataLimit] = useState(10);
  const [importDataTotal, setImportDataTotal] = useState(0);

  const [sortBy, setSortBy] = useState("last_seen");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [takedownHistory, setTakedownHistory] = useState(false);
  const [tdhLoading, setTdhLoading] = useState(false);
  const [tdhData, setTdhData] = useState([{rules: []}]);
  const [confirmDeleteTP, setConfirmDeleteTP] = useState(0);
  const [deletingTP, setDeletingTP] = useState(false);
  const [editTP, setEditTP] = useState(0);
  const [editLoading, setEditLoading] = useState(false);

  const columns = [
    {
      title: "Listing",
      dataIndex: "title",
      width: 300,
      render: (text, row) => (
        <>
        <div
          className="listing-title-cell"
          onClick={() => {
            setSelectedProduct(row);
          }}
        >
          {
            row?.flag === 1 &&
            <div className="flag-div"><FlagFilled /></div>
          }
          {row?.image && (
            <img
              src={row?.image[0]}
              alt={row?.title}
              className="listing-img-table"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = NoImage;
              }}
            />
          )}
          <Image src={text?.image} />
          <span>
            {text?.substring(0, 60)}
            {text?.length > 60 && "..."}
          </span>
        </div>
        </>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      width: 100,
    },
    {
      title: "Price",
      dataIndex: "converted",
      width: 100,
      render: (text) => (`$${text?.value?.toFixed(2)}`),
    },
    {
      title: "Seller",
      dataIndex: "seller",
      width: 100,
      render: (text, row) => (
        <Link
          to={`/seller/${
            row?.unique_id
          }?case_id=${id}&about=${currentCategory === "search engine" ? "search_engine" :currentCategory === "social media" ? "social_media" : "amazon"}`}
        >
          {text?.name?.substring(0, 20)}
          {text?.name?.length > 20 && "..."}
        </Link>
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      width: 100,
      align: "center",
      render: (text) => (
        <a href={text} target="_blank" rel="noreferrer">
          <LinkIcon />
        </a>
      ),
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   width: 100,
    //   render: (text) => (
    //     <Button type="link">
    //       <Action />
    //     </Button>
    //   ),
    // },
  ];

  const sellerColumns = [
    {
      title: "Seller",
      dataIndex: "seller",
      width: 200,
      render: (text, row) => (
        <a
          href={`/seller/${
            row?.unique_id
          }?case_id=${id}&about=${"amazon"}`}
        >
          {text?.name}
        </a>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      width: 100,
    },
    {
      title: "No. of products",
      dataIndex: "productsCount",
      width: 100,
      align: "center"
    },
    {
      title: "Link",
      dataIndex: "seller",
      width: 100,
      align: "center",
      render: (text) => (
          text?.link?.length ?
          <a href={text?.link} target="_blank" rel="noreferrer">
            <LinkIcon />
          </a> : "NA"
      ),
    },
    {
      title: "Action",
      align: "center",
      width: 100,
      render: (text) => (
        <Button type="link" onClick={() => setSelectedSeller(text)}>
          <Action />
        </Button>
      ),
    },
  ];

  const socialMediaColumns = [
    {
      title: "Listing",
      dataIndex: "title",
      width: 300,
      render: (text, row) => (
        <>
        <div
          className="listing-title-cell"
          onClick={() => {
            setSelectedProduct(row);
          }}
        >
          {
            row?.flag === 1 &&
            <div className="flag-div"><FlagFilled /></div>
          }
          {row?.image && (
            <img
              src={row?.image[0]}
              alt={row?.title}
              className="listing-img-table"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = NoImage;
              }}
            />
          )}
          <Image src={text?.image} />
          <span>
            {text?.substring(0, 60)}
            {text?.length > 60 && "..."}
          </span>
        </div>
        </>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      width: 200,
    },
    {
      title: "Seller",
      dataIndex: "seller",
      width: 100,
      render: (text, row) => (
        <Link
          to={`/seller/${
            row?.unique_id
          }?case_id=${id}&about=${currentCategory === "search engine" ? "search_engine" :currentCategory === "social media" ? "social_media" : "amazon"}`}
        >
          {text?.name?.substring(0, 20)}
          {text?.name?.length > 20 && "..."}
        </Link>
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      width: 100,
      align: "center",
      render: (text) => (
        <a href={text} target="_blank" rel="noreferrer">
          <LinkIcon />
        </a>
      ),
    },
  ];

  const importDataColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      width: 200,
      render: (text, row) => (
        <a
          href={`${row?.file_path}`}
          target="_blank"
          rel="noreferrer"
        >
          {text?.substring(0, 20)}
          {text?.length > 20 && "..."}
        </a>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 200,
      render: text => <p style={{textTransform: "capitalize"}}>{text?.replace(/_/g, " ")}</p>
    },
    {
      title: "Uploaded on",
      dataIndex: "created_at",
      width: 200,
      render: text => moment(text).format("DD MMM, YYYY")
    }
  ];

  const tdhColumns = [
    {
      title: "Initiated By",
      dataIndex: "name"
    },
    {
      title: "Initiated On",
      dataIndex: "created_on",
      render: text => text?.length ? moment(text).format("DD MMM YYYY [at] h:mm A z") : "--"
    },
    {
      title: "Assets",
      dataIndex: "rules",
      render: text => text[0]?.assets
    },
    {
      title: "Price",
      dataIndex: "rules",
      render: text => text[0]?.condition ? `$${text[0]?.condition?.price?.value}` : ""
    },
    {
      title: "Condition",
      dataIndex: "rules",
      render: text => text[0]?.condition?.price?.type
    }
  ]

  const CategoryTabChangeHandler = (value) => {
    setSearchParams({ category: value });
    setNumberAlert(true);
    setPage(1)
  };

  const getTabsHandler = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/categories/*`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === 1) {
          const object = res.category_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          let newArr = Object.values(object)?.map(item => ({tab: item?.name, key: item?.code}));
          setCatArr(newArr);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const getCountriesList = (date) => {
    const startDate = moment(date).format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/dashboard-v2?start_date=${startDate}&end_date=${endDate}&widget=LISTINGS_COUNTRY_WISE&case_id=${id}`, requestOptions)
      .then(response => response.json())
      .then(res => {
        const arr = res?.data?.map(country => ({label: country?.name, value: country?.name?.toLowerCase()}));
        setCountryList([...countryList, ...arr])
      })
      .catch(error => {
        console.log('error', error)
      });
  }

  const getCasesHandler = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(
      `${apiUrl}/case/*`, 
      requestOptions
      )
      .then(response => response.json())
      .then(res => {
        if(res?.status === 1) {
          const object = res.case_details;
          // setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          const newArr = Object.values(object)?.map(item => (
            {
              label: item?.name,
              value: item?.id
            }
          ))
          setCasesList(newArr);
          const currentCase = newArr.filter(item => item.value.toString() === id);
          setSelectedCase(currentCase[0])
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch(res => {
        console.log('error', res)
      });
  }

  const getRecrawlHandler = () => {
    setRecrawlLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/recrawl/*?case_id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      setRecrawlLoading(false);
      if (res?.status === 1) {
        const object = res.crawl_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          setRecrawlData(Object?.values(object));
          if(currentCategory === "marketplace") {
            let newArr = Object?.values(object)?.filter(item => item?.area?.toLocaleLowerCase() === "ecom");
            setRecrawlperiod(newArr[0]?.recrawl_period?.toString())
          }
          if(currentCategory === "seller") {
            let newArr = Object?.values(object)?.filter(item => item?.area?.toLocaleLowerCase() === "seller");
            setRecrawlperiod(newArr[0]?.recrawl_period?.toString())
          }
          if(currentCategory === "search engine") {
            let newArr = Object?.values(object)?.filter(item => item?.area?.toLocaleLowerCase() === "search_engine");
            setRecrawlperiod(newArr[0]?.recrawl_period?.toString())
          }
          if(currentCategory === "social media") {
            let newArr = Object?.values(object)?.filter(item => item?.area?.toLocaleLowerCase() === "social_media");
            setRecrawlperiod(newArr[0]?.recrawl_period?.toString())
          }
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.message);
        }
    })
    .catch((error) => {
      setRecrawlLoading(false);
      console.log("error", error);
      message.error("Something went wrong!");
    });
  }

  const getCaseDetails = () => {
    setMainLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("domains", "[]");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/case/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setMainLoading(false);
        if (res?.status === 1) {
          setCaseDetails(res?.case_details);
          getCountriesList(res?.case_details?.created_on);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setMainLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const getListDataHandler = () => {
    console.log(currentCategory);
    setData([]);
    setTotal(0);
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("about", (currentCategory === "marketplace" || currentCategory === "seller") ? "amazon" : currentCategory);
    formdata.append("page", page);
    formdata.append("limit", limit);
    formdata.append("search_status[]", statusFilter);
    formdata.append("search_str", searchStr);
    formdata.append("country", countryFilter);
    if(asset?.length) {
      formdata.append("assets[]", asset);
    }
    formdata.append("flag", flag);
    reportingFor[0] !== null && reportingFor?.map(item => (
      formdata.append("reporting_for[]", item)
    ));
    if(minPrice?.length > 0) {
      formdata.append("price[]", minPrice);
    }
    if(maxPrice?.length > 0) {
      formdata.append("price[]", maxPrice);
    }
    if(startDate !== "Invalid date" && startDate !== undefined) {
      formdata.append("start_date", startDate);
    }
    if(endDate !== "Invalid date" && endDate !== undefined) {
      formdata.append("end_date", endDate);
    }
    if(sortBy === "product_count") {
      formdata.append("sort_by", "last_seen");
      setSortBy("last_seen");
    }
    else {
      formdata.append("sort_by", sortBy);
    }
    formdata.append("sort_order", sortOrder);

    marketplaceFilter?.map((marketplace) => formdata.append("platform[]", marketplace))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/list-common-data?category_code=${currentCategory}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          setData(res?.data);
          setTotal(res?.total);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const getMarketplaceData = () => {
    setData([]);
    setTotal(0);
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("about", "amazon");
    formdata.append("page", page);
    formdata.append("limit", limit);
    formdata.append("search_status[]", statusFilter);
    formdata.append("search_str", searchStr);
    formdata.append("country", countryFilter);
    if(asset?.length) {
      formdata.append("assets[]", asset);
    }
    formdata.append("flag", flag);
    reportingFor[0] !== null && reportingFor?.map(item => (
      formdata.append("reporting_for[]", item)
    ));
    if(minPrice?.length > 0) {
      formdata.append("price[]", minPrice);
    }
    if(maxPrice?.length > 0) {
      formdata.append("price[]", maxPrice);
    }
    if(startDate !== "Invalid date" && startDate !== undefined) {
      formdata.append("start_date", startDate);
    }
    if(endDate !== "Invalid date" && endDate !== undefined) {
      formdata.append("end_date", endDate);
    }
    if(sortBy === "product_count") {
      formdata.append("sort_by", "last_seen");
      setSortBy("last_seen");
    }
    else {
      formdata.append("sort_by", sortBy);
    }
    formdata.append("sort_order", sortOrder);

    marketplaceFilter?.map((marketplace) => formdata.append("platform[]", marketplace))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/list-case-data`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          setData(res?.data);
          setTotal(res?.total);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const getSellersData = () => {
    setData([]);
    setTotal(0);
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("about", "amazon");
    formdata.append("page", page);
    formdata.append("limit", limit);
    formdata.append("search_status[]", statusFilter);
    formdata.append("search_str", searchStr);
    formdata.append("country", countryFilter);
    if(asset?.length) {
      formdata.append("assets[]", asset);
    }
    formdata.append("flag", flag);
    reportingFor[0] !== null && reportingFor?.map(item => (
      formdata.append("reporting_for[]", item)
    ))
    if(startDate !== "Invalid date" && startDate !== undefined) {
      formdata.append("start_date", startDate);
    }
    if(endDate !== "Invalid date" && endDate !== undefined) {
      formdata.append("end_date", endDate);
    }
    if(sortBy === "converted_price") {
      formdata.append("sort_by", "last_seen");
      setSortBy("last_seen");
    }
    else {
      formdata.append("sort_by", sortBy);
    }
    formdata.append("sort_order", sortOrder);

    marketplaceFilter?.map((marketplace) => formdata.append("platform[]", marketplace))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/list-seller-data`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          setData(res?.data);
          setTotal(res?.total);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });


  }

  const getSearchengineData = () => {
    setData([]);
    setTotal(0);
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("page", page);
    formdata.append("limit", limit);
    formdata.append("search_status[]", statusFilter);
    formdata.append("search_str", searchStr);
    formdata.append("country", countryFilter);
    if(asset?.length) {
      formdata.append("assets[]", asset);
    }
    formdata.append("flag", flag);
    reportingFor[0] !== null && reportingFor?.map(item => (
      formdata.append("reporting_for[]", item)
    ));
    if(startDate !== "Invalid date" && startDate !== undefined) {
      formdata.append("start_date", startDate);
    }
    if(endDate !== "Invalid date" && endDate !== undefined) {
      formdata.append("end_date", endDate);
    }
    if(sortBy === "product_count") {
      formdata.append("sort_by", "last_seen");
      setSortBy("last_seen");
    }
    else {
      formdata.append("sort_by", sortBy);
    }
    formdata.append("sort_order", sortOrder);

    marketplaceFilter?.map((marketplace) => formdata.append("platform[]", marketplace))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/list-search-engine-data`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          setData(res?.data);
          setTotal(res?.total);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const getSocialmediaData = () => {
    setData([]);
    setTotal(0);
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("page", page);
    formdata.append("limit", limit);
    formdata.append("search_status[]", statusFilter);
    formdata.append("search_str", searchStr);
    formdata.append("country", countryFilter);
    if(asset?.length) {
      formdata.append("assets[]", asset);
    }
    formdata.append("flag", flag);
    reportingFor[0] !== null && reportingFor?.map(item => (
      formdata.append("reporting_for[]", item)
    ))
    if(startDate !== "Invalid date" && startDate !== undefined) {
      formdata.append("start_date", startDate);
    }
    if(endDate !== "Invalid date" && endDate !== undefined) {
      formdata.append("end_date", endDate);
    }
    if(sortBy === "product_count") {
      formdata.append("sort_by", "last_seen");
      setSortBy("last_seen");
    }
    else {
      formdata.append("sort_by", sortBy);
    }
    formdata.append("sort_order", sortOrder);

    marketplaceFilter?.map((marketplace) => formdata.append("platform[]", marketplace))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/list-social-media-data`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          setData(res?.data);
          setTotal(res?.total);
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const getDataHandler = () => {
    if (currentCategory === "marketplace") {
      getMarketplaceData();
    } else if (currentCategory === "search engine") {
      getSearchengineData();
    } else if (currentCategory === "social media") {
      getSocialmediaData();
    } else if (currentCategory === "seller") {
      getSellersData();
    } else {
      // message.error("Category not present in URL");
      getListDataHandler();
    }
  };

  const updateSellerHandler = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append(
      "about", 
      currentCategory === "marketplace" ?
      "amazon" : 
      currentCategory === "seller" ?
      "amazon" : 
      currentCategory === "search engine" ?
      "search_engine" : 
      currentCategory === "social media" ?
      "social_media" : 
      currentCategory
      );
    formdata.append("case_id", id);
    if(selectedSeller?.id) {
      formdata.append("unique_id", selectedSeller?.seller?.id);
    }
    else {
      formdata.append("unique_id", checkedListTwo?.length ? selectedProduct?.seller?.id : selectedProduct?.id);
    }
    formdata.append("seller_flag", "0");
    formdata.append("seller_status", selectedSeller?.id ? selectedSellerStatus : selectedProuctStatus);
    checkedList?.map(item => formdata.append("reporting_for[]", item))
    checkedListTwo?.map(item => formdata.append("report_action[]", item))
    if(currentCategory === "seller") {
      formdata.append("report_action[]", "Report Seller")
    }
    if(otherInfringementType?.length > 0) {
      formdata.append("other_infringement_type", otherInfringementType);
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/update-seller`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          message.success(res?.message);
          setSelectedProduct({});
          setSelectedSeller({});
          getCaseDetails();
          getDataHandler();
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const automatedTakedownHandler = () => {
    setAutomatedtakedownLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("domains", "[]");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const rule = [{"name":"Automated Takedown","assets":[`${automatedTakedownData?.asset}`],"condition":{"title":{"type":"","keywords":[]},"price":{"type":`${automatedTakedownData?.type}`,"value":`${automatedTakedownData?.price}`,"currency":"$"}},"marketplace":[]}]

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("rule", JSON.stringify(rule));
    formdata.append("category", currentCategory);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/automatic-takedown`, requestOptions)
      .then(response => response.json())
      .then((res) => {
        setAutomatedtakedownLoading(false);
        if (res?.status === 1) {
          message.success(res?.message);
          setAutomatedTakedownPopup(false);
          setAutomatedTakedownData({
            type: "less than",
            asset: undefined,
            price: 0
          });
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setAutomatedtakedownLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const updateRecrawlHandler = () => {
    setRecrawlUpdating(true);
      let area = "";
      if(currentCategory === "marketplace") {
        area = "ECOM"
      }
      if(currentCategory === "seller") {
        area = "SELLER"
      }
      if(currentCategory === "search engine") {
        area = "SEARCH_ENGINE"
      }
      if(currentCategory === "social media") {
        area = "SOCIAL_MEDIA"
      }

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      var formdata = new FormData();
      formdata.append("case_id", id);
      formdata.append("area", area);
      formdata.append("recrawl_period", recrawlPeriod);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${apiUrl}/recrawl`, requestOptions)
        .then(response => response.json())
      .then(res => {
        setRecrawlUpdating(false);
        if(res?.status === 1) {
          message.success("Recrawl period updated successfully");
          setRecrawlModal(false)
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch(res => {
        setRecrawlUpdating(false);
        console.log('error', res)
      });
  }

  const exportHandler = () => {
    console.log(currentCategory);
    setExporting(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("about", "amazon");
    formdata.append("page", page);
    formdata.append("limit", limit);
    formdata.append("search_status[]", statusFilter);
    formdata.append("search_str", searchStr);
    formdata.append("country", countryFilter);
    if(asset?.length) {
      formdata.append("assets[]", asset);
    }
    formdata.append("flag", flag);
    reportingFor[0] !== null && reportingFor?.map(item => (
      formdata.append("reporting_for[]", item)
    ));
    if(minPrice?.length > 0) {
      formdata.append("price[]", minPrice);
    }
    if(maxPrice?.length > 0) {
      formdata.append("price[]", maxPrice);
    }
    if(startDate !== "Invalid date" && startDate !== undefined) {
      formdata.append("start_date", startDate);
    }
    if(endDate !== "Invalid date" && endDate !== undefined) {
      formdata.append("end_date", endDate);
    }

    formdata.append("export", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const url = 
      currentCategory === "marketplace" ? `${apiUrl}/list-case-data` : 
      currentCategory === "seller" ? `${apiUrl}/list-seller-data` : 
      currentCategory === "search engine" ? `${apiUrl}/list-search-engine-data` : 
      currentCategory === "social media" ? `${apiUrl}/list-social-media-data` : 
      "";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setExporting(false);
        if (res?.status === 1) {
          window.location.href = res?.file_path;
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setExporting(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const getCategoriesHandler = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/categories/*`, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      setExporting(false);
      if (res?.status === 1) {
        const object = res.category_details;
          delete object.page;
          delete object.rows;
          delete object.total;
          setCategoryList(Object.values(object)?.map(cat => ({value: cat?.name?.toLowerCase(), label: cat?.name})));
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.message);
        }
    })
    .catch((error) => {
      setExporting(false);
      console.log("error", error);
      message.error("Something went wrong!");
    });
  }

  const importHandler = () => {
    setImporting(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("category", selectedCategory);
    formdata.append("file", importFile, importFile?.name);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/upload-case-data`, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      setImporting(false);
      if (res?.status === 1) {
        message.success(res?.message);
        setImportFile(null);
        setImportPopup(false);
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.message);
        }
    })
    .catch((error) => {
      setImporting(false);
      console.log("error", error);
      message.error("Something went wrong!");
    });
  }

  useEffect(() => {
    getCaseDetails();
    getCasesHandler();
    getTabsHandler();
    getDataHandler();
  }, [id]);

  useEffect(() => {
    if (searchStr?.length) {
      const getData = setTimeout(() => {
        getDataHandler();
      }, 700);

      return () => clearTimeout(getData);
    } else {
      getDataHandler();
    }
  }, [currentCategory, searchStr, statusFilter, limit, page, asset]);

  useEffect(() => {
    setSelectedSellerStatus(statusFilter);
  }, [statusFilter])

  const getTestPurchases = () => {
    setTestPurchaseLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("domains", "[]");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("unique_id", selectedProduct?.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/get-test-purchase/*`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setTestPurchaseLoading(false);
        if (res?.status === 1) {
          const object = res?.test_purchase_details;
          setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          setTestPurchaseSteps(Object?.values(object));
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setTestPurchaseLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const addActivityHandler = () => {
    setAddActivityLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("unique_id", selectedProduct?.id);
    formdata.append("activity", newStepData?.activity);
    formdata.append("description", newStepData?.description);
    formdata.append("date", newStepData?.date);
    formdata.append("insight", newStepData?.insights);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/test-purchase`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setAddActivityLoading(false);
        if (res?.status === 1) {
          message.success(res?.message);
          setTestPurchaseSteps([...testPurchaseSteps, res?.test_purchase_details]);
          setTestInitiated(false);
          setNewStepData({
            activity: "",
            description: "",
            date: dayjs().format("YYYY-MM-DD"),
            insights: ""
          })
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setAddActivityLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const initiateVisualSearchHandler = () => {
    setUploading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("case_id", id);
    formdata.append("unique_id", "");
    // selectedProduct?.image?.map(file => (
    //         formdata.append("file_url[]", file)
    // ));
    formdata.append("file_url[]", selectedProduct?.image[imageIndex]);

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
    };

    fetch(`${apiUrl}/image-search-initiate`, requestOptions)
    .then(response => response.json())
    .then(res => {
            if(res?.status === 1) {
                    message.success(res?.message);
                    setTimeout(() => {
                      setUploading(false);
                      navigate(`/visual-search/${res?.imageDetails?.id}`);
                    }, 3000);
            }
            else if(res?.api_status ==='401') {
              logoutHandler("401");
              }
              else {
                      message.error(res?.message);
              }
    })
    .catch(res => {
            setUploading(false);
            console.log('error', res)
    });
 }

  const UpdateMultipleListingsHandler = () => {
    setUpdating(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append(
      "about", 
      currentCategory === "marketplace" ?
      "amazon" : 
      currentCategory === "seller" ?
      "amazon" : 
      currentCategory === "search engine" ?
      "search_engine" : 
      currentCategory === "social media" ?
      "social_media" : 
      ""
      );
    formdata.append("case_id", id);
    formdata.append("seller_status", multipleListingsUpdateStatus);
    formdata.append("seller_flag", "0");
    selectedRowKeys?.map((key, i) => (
      formdata.append("unique_id[]", key)
    ))
    if(currentCategory === "seller") {
      formdata.append("report_action[]", "Report Seller");
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/update-seller`, requestOptions)
      .then(response => response.json())
      .then((res) => {
        setUpdating(false);
        if (res?.status === 1) {
          message.success(res?.message);
          setOpenUpdateStatus(false);
          setSelectedRows([]);
          setSelectedRowKeys([]);
          getCaseDetails();
          getDataHandler();
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setUpdating(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const getImportedFilesHandler = () => {
    setImportDataLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/list-import-log/*?case_id[]=${id}&page=${importDataPage}&rows=${importDataLimit}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setImportDataLoading(false);
        if (res?.status === 1) {
          const object = res?.import_details;
          setImportDataTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          setImportData(Object?.values(object));
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setImportDataLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const deleteTPHandler = (data) => {
    setDeletingTP(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("_method", "patch");

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/test-purchase/${data?.id}`, requestOptions)
    .then(response => response.json())
    .then((res) => {
      setDeletingTP(false);
      if (res?.status === 1) {
        message.success(res?.message);
        setConfirmDeleteTP(0);
        getTestPurchases();
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.message);
        }
    })
    .catch((error) => {
      setDeletingTP(false);
      console.log("error", error);
      message.error("Something went wrong!");
    });
  }

  const editTPHandler = (data) => {
    setEditLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("activity", editStepData?.activity);
    formdata.append("description", editStepData?.description);
    formdata.append("insight", editStepData?.insights);
    formdata.append("_method", "patch");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/test-purchase/${data?.id}`, requestOptions)
      .then(response => response.json())
      .then((res) => {
        setEditLoading(false);
        if (res?.status === 1) {
          message.success(res?.message);
          setEditTP(0);
          getTestPurchases();
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.message);
          }
      })
      .catch((error) => {
        setEditLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  const getTakedownHistory = () => {
    setTdhLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/automatic-takedown/*?case_id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      setTdhLoading(false);
      if (res?.status === 1) {
        setTdhData(res?.takedown_details);
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
          message.success(res?.message);
        }
    })
    .catch((error) => {
      setTdhLoading(false);
      console.log("error", error);
      message.error("Something went wrong!");
    });
  }

  useEffect(() => {
    if (selectedProduct?.id) {
      setSelectedProductStatus(selectedProduct?.status);
      setCheckedList(selectedProduct?.reporting_for);
      setCheckedListTwo(selectedProduct?.report_action);
      setOtherInfringementType(selectedProduct?.other_infringement_type);
    } else {
      setSelectedProductStatus("");
      setCheckedList([]);
      setCheckedListTwo([]);
      setOtherInfringementType("");
    }
  }, [selectedProduct]);

  useEffect(() => {
    if(modalTab === "test_purchase") {
      getTestPurchases()
    }
  }, [modalTab])

  useEffect(() => {
    if(recrawlModal) {
      getRecrawlHandler();
    }
  }, [recrawlModal])

  useEffect(() => {
    getCategoriesHandler()
  }, []);

  useEffect(() => {
    if(takedownHistory) {
      getTakedownHistory();
    }
  }, [takedownHistory])

  useEffect(() => {
    setSelectedCategory(currentCategory);
    if(currentCategory !== "seller") {
      setSortBy("last_seen");
    }
  }, [currentCategory])

  useEffect(() => {
    if(importHistoryPopup) {
      getImportedFilesHandler()
    }
  }, [importHistoryPopup, importDataPage, importDataLimit]);

  return (
    <div className="cases-div case-listings-div">
      <Card className="card cases-card">
        {mainLoading ? (
          <div className="loading">
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Row gutter={10} className="header-row">
              <Col className="header-left-col" xs={24} lg={14} style={{ display: "flex", alignItems: "center" }}>
                <BackBtn
                  style={{ marginRight: 12, cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <h3 className="page-title">
                  Listings for{" "}
                  {/* {caseDetails?.name || caseDetails?.brand?.clientName} */}
                </h3>
                <Select
                  className="case-select"
                  defaultValue={selectedCase}
                  value={selectedCase}
                  onChange={e => {
                    setSelectedCase(e);
                    navigate(`/cases/${e}?category=${currentCategory}`)
                  }}
                  size="large"
                  style={{marginLeft: isMobile ? 0 : 8}}
                >
                  {
                    casesList?.map((item, i) => (
                      <Select.Option value={item?.value} key={i}>{item?.label}</Select.Option>
                    ))
                  }
                </Select>
                <Select
                  className="case-select"
                  defaultValue={asset}
                  value={asset}
                  onChange={e => setAsset(e)}
                  loading={loading}
                  size="large"
                  style={{marginLeft: isMobile ? 0 : 8}}
                >
                  <Select.Option value="">All assets</Select.Option>
                  {
                    caseDetails?.keyword?.map((item, i) => (
                      <Select.Option value={item?.name?.toLowerCase()} key={i}>{item?.name}</Select.Option>
                    ))
                  }
                </Select>
                {/* <Button
                  style={{marginLeft: 8, marginTop: isMobile ? 12 : 0}}
                  size="large"
                  onClick={() => navigate(`/case-details/${id}`)}
                >
                  Case details <RightOutlined />
                </Button> */}
              </Col>
              <Col xs={24} lg={10} style={{display: "flex", justifyContent: "flex-end"}}>
                <Space size="middle" className="search-space">
                  <Input
                    style={{width: isMobile ? "100%" : ""}}
                    size="large"
                    value={searchStr}
                    // onChange={(e) => setSearchStr(e.target.value)}
                    onChange={e => {
                      (setSearchStr(e.target.value));
                      setPage(1);
                    }}
                    placeholder={`Search ${currentCategory}`}
                    prefix={<SearchOutlined style={{ color: "#A9AFBA" }} />}
                  />
                  <Tooltip title="More">
                    <Dropdown
                      trigger={['click']}
                      menu={{
                        items: [{
                          key: '1',
                          label: (
                            <span onClick={() => navigate(`/case-details/${id}`)}>
                              Case details
                            </span>
                          ),
                        },
                        ]
                      }}
                    >
                      <Button 
                        size="large"
                        style={{padding: 6}}
                      >
                        <MoreOutlined style={{fontSize: 24}} />
                      </Button>
                    </Dropdown>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
            {/* categories tabs */}
            <div className="categories-tab-btn-div">
              <Tabs
                defaultActiveKey={currentCategory}
                onChange={(e) => {CategoryTabChangeHandler(e); setMarketplaceFilter([])}}
                style={{width: isMobile ? "100%" : "45%", marginBottom: isMobile ? 16 : ""}}
              >
                {
                  catArr?.map(cat => (
                    <TabPane tab={cat?.tab} key={cat?.key} disabled={loading} />
                  ))
                }
              </Tabs>
              <Space style={{marginLeft: isMobile ? "auto" : ""}}>
                <Tooltip title="More">
                  <Dropdown
                    trigger={['click']}
                    menu={(user?.role_id === 2 || user?.role_id === 3 || currentCategory === "seller") ?{
                      items: [{
                        key: '1',
                        label: (
                          <p onClick={() => setOpenFilters(true)}>
                            <Filter className="white-filter-icon" /> Filters
                          </p>
                        ),
                      },
                      ]
                    } : {
                      items: [
                        {
                          key: '1',
                          label: (
                            <p onClick={() => setAutomatedTakedownPopup(true)}>
                              <Takedown style={{marginRight: isMobile ? 0 : 8}} /> Automated takedown
                            </p>
                          ),
                        },
                        {
                          key: '2',
                          label: (
                            <p onClick={() => setRecrawlModal(true)}>
                              <ReloadOutlined /> Recrawl period
                            </p>
                          ),
                        },
                        {
                          key: '3',
                          label: (
                            <p onClick={() => setOpenFilters(true)}>
                              <Filter className="white-filter-icon" /> Filters
                            </p>
                          ),
                        },
                      ]
                    }}
                  >
                    <Button 
                      size="large"
                      style={{padding: 6, background: "transparent", border: "none"}}
                    >
                      <MoreOutlined style={{fontSize: 24, color: "white"}} />
                    </Button>
                  </Dropdown>
                </Tooltip>
              </Space>
            </div>
            {/* status filter tabs */}
            <div className="status-tab-btn-div">
              <Tabs
                // defaultActiveKey="pending"
                defaultActiveKey={statusFilter}
                onChange={(e) => {setStatusFilter(e); setPage(1)}}
              >
                {
                  allStatus?.map(status => (
                    <TabPane
                      tab={`${status?.name}`}
                      key={status?.code}
                      disabled={loading}
                    />
                  ))
                }
              </Tabs>
            </div>
            {/* cta div */}
            <div className="cta-div">
              {data?.length > 0 && (
                <>
                  <Space size="middle" className="cta-div-space-one">
                    <p className="total" style={{fontWeight: 500}}>
                      {
                        currentCategory === "seller" ? "All sellers" : "All listings"
                      } ({total})
                    </p>
                    <div
                      style={
                        listingType === "list" ? { visibility: "hidden" } : {}
                      }
                    >
                      {
                        user?.role_id !== 3 &&
                        <Checkbox
                          onChange={(e) => {
                            if(e.target.checked) {
                              setSelectedRowKeys(data?.map(item => item?.id))
                            }
                            else {
                              setSelectedRowKeys([])
                            }
                          }}
                          checked={selectedRowKeys?.length === data?.length}
                        >
                          {selectedRowKeys?.length === data?.length
                            ? "Deselect all"
                            : "Select all"}
                        </Checkbox>
                      }
                    </div>
                  </Space>
                  <Space size={isMobile ? "small" : "middle"} style={isMobile ? {width: "100%", justifyContent: "flex-end"}: {}}>
                    {
                      (selectedRowKeys?.length && user?.role_id !== 3) ? <>
                      <Button 
                        size={isMobile ? "small" : "large"} 
                        style={isMobile ? {height: 30} : {}}
                        onClick={() => setOpenUpdateStatus(true)}
                      >
                        {
                          isMobile ? 
                          `Update ${selectedRowKeys?.length} Items` :
                          `Update Status of ${selectedRowKeys?.length} Listings`
                        }
                      </Button>
                      </> : null
                    }
                    {
                      (user?.role_id === 2 || user?.role_id === 4) ? 
                      <></> :
                      <Tooltip title="Import">
                        <Button
                          size={isMobile ? "small" : "large"} 
                          loading={loading}
                          style={isMobile ? {padding: "0 8px", height: 30} : {}}
                          onClick={() => setImportPopup(true)}
                        >
                          <ImportIcon style={{ marginBottom: -1, marginRight: isMobile ? 0 : 0 }} />
                          {/* {
                            !isMobile && "Import"
                          } */}
                        </Button>
                      </Tooltip>
                    }
                    <Tooltip title="Export">
                      <Button 
                        size={isMobile ? "small" : "large"} 
                        loading={loading || exporting}
                        style={isMobile ? {padding: "0 8px", height: 30} : {}}
                        onClick={() => exportHandler()}
                      >
                        <Export style={{ marginBottom: -2, marginRight: isMobile ? 0 : 0 }} />
                        {/* {
                          !isMobile && "Export"
                        } */}
                      </Button>
                    </Tooltip>
                    <div className="list-type-toggle-div">
                      <Button
                        className={
                          listingType === "grid"
                            ? "list-type-toggle active"
                            : "list-type-toggle"
                        }
                        icon={<GridIcon />}
                        type="text"
                        onClick={() => {setListingType("grid"); setSelectedRowKeys([])}}
                      />
                      <Button
                        className={
                          listingType === "list"
                            ? "list-type-toggle active"
                            : "list-type-toggle"
                        }
                        icon={<ListIcon />}
                        type="text"
                        onClick={() => {setListingType("list"); setSelectedRowKeys([])}}
                      />
                    </div>
                  </Space>
                </>
              )}
            </div>
            {/* alert div */}
            {
              (
                (currentCategory === "marketplace" && caseDetails?.marketPlace?.totalCountThisWeek > 0) ||
                (currentCategory === "seller" && caseDetails?.sellerData?.totalCountThisWeek > 0) ||
                (currentCategory === "search engine" && caseDetails?.searchEngine?.totalCountThisWeek > 0) ||
                (currentCategory === "social media" && caseDetails?.socialMedia?.totalCountThisWeek > 0)
              ) ?
                <div className={numberAlert ? "listing-number-alert open" : "listing-number-alert close"}>
                  <div className="left-col">
                    <div className="icon">
                      <NumberAlert />
                    </div>
                    <div className="text">
                      <p className="label">
                        {
                          currentCategory === "marketplace" ?
                          caseDetails?.marketPlace?.totalCountThisWeek :
                          currentCategory === "seller" ?
                          caseDetails?.sellerData?.totalCountThisWeek : 
                          currentCategory === "search engine" ?
                          caseDetails?.searchEngine?.totalCountThisWeek : 
                          currentCategory === "social media" ?
                          caseDetails?.socialMedia?.totalCountThisWeek : 
                          ""
                        }{" "}
                        new listings found this week <span>New</span>
                      </p>
                      <div className="desc">Next crawling session would be in 6 days.</div>
                    </div>
                  </div>
                  <div className="right-col">
                    <Button
                      size="small"
                      className="alert-close-btn"
                      onClick={() => setNumberAlert(false)}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                </div> : null
            }
            {/* listing cards div */}
            <div className="listing-data-div">
              {listingType === "grid" ? (
                <>
                  {loading ? (
                    <div className="loading">
                      <Spin />
                    </div>
                  ) : !data?.length ? (
                    <NoData title={currentCategory === "seller" ? "No Sellers" : "No listings"} desc={`${currentCategory} listings will appear here`} />
                  ) : (
                    <Row className="listing-row" gutter={[24, 24]}>
                      {data?.map((item, i) => (
                        <Col key={i} xs={24} lg={6} className="listing-col">
                          <div className="col-div">
                            {
                              item?.flag === 1 &&
                              <div className="flag-div"><FlagFilled /></div>
                            }
                            {
                              user?.role_id !== 3 &&
                              <Checkbox
                                className="list-checkbox"
                                value={item?.id}
                                checked={selectedRowKeys?.includes(item?.id)}
                                onChange={e => {
                                  if(selectedRowKeys?.includes(e.target.value)) {
                                    const newArr = selectedRowKeys.filter(item => item !== e.target.value);
                                    setSelectedRowKeys(newArr)
                                  }
                                  else {
                                    setSelectedRowKeys([...selectedRowKeys, e.target.value]);
                                  }
                                }}
                              />
                            }
                            {
                              (currentCategory === "seller" && user?.role_id !== 3) &&
                              <>
                              <Tooltip title="Quick edit">
                                <EditOutlined
                                  style={{position: "absolute", bottom: "15px", right: "15px"}}
                                  onClick={() => currentCategory === "seller" ? setSelectedSeller(item) : null}
                                 />
                              </Tooltip>
                              </>
                            }
                            {
                            // currentCategory === "social media" ? 
                            // <img
                            //     onClick={() => setSelectedProduct(item)}
                            //     src={item?.image[0] || NoImage}
                            //     alt={item?.title}
                            //     className="listing-img"
                            //     onError={({ currentTarget }) => {
                            //       currentTarget.onerror = null; // prevents looping
                            //       currentTarget.src = NoImage;
                            //     }}
                            //   /> :
                            (currentCategory !== "seller" && item?.image) ? (
                              <img
                                onClick={() => setSelectedProduct(item)}
                                src={item?.image[0] || NoImage}
                                alt={item?.title}
                                className="listing-img"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = NoImage;
                                }}
                              />
                            ) : null}
                            <div
                              className="text-div"
                              onClick={() => currentCategory === "seller" ? null : setSelectedProduct(item)}
                            >
                              {
                                currentCategory === 'seller' ?
                                <>
                                  <div className="seller">
                                    <p>
                                      <Link
                                        to={`/seller/${
                                          item?.unique_id
                                        }?case_id=${id}&about=${"amazon"}`}
                                      >
                                        {item?.seller?.name?.substring(0, 45)}
                                        {item?.title?.length > 45 && "..."}
                                      </Link>
                                    </p>
                                  </div>
                                  <div className="platform">
                                    <p>Platform: {item?.platform}</p>
                                  </div>
                                  <div className="platform">
                                    <p>No. of products: {item?.productsCount}</p>
                                  </div>
                                  <div className="date">
                                    <p>
                                      {moment(item?.last_seen).format("Do MMM YYYY")}
                                    </p>
                                  </div>
                                </> :
                                <>
                                  <div className="platform">
                                    {item?.platform_image?.length ? (
                                      <img
                                        src={item?.platform_image}
                                        alt={item?.platform}
                                      />
                                    ) : (
                                      <div className="img-holder"></div>
                                    )}
                                    <p>{item?.platform}</p>
                                  </div>
                                  <div className="title">
                                    <p>
                                      {item?.title?.substring(0, 45)}
                                      {item?.title?.length > 45 && "..."}
                                    </p>
                                    <a
                                      href={item?.link}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <LinkIcon />
                                    </a>
                                  </div>
                                  <div className="price">
                                    {
                                      // (item?.platform?.toLowerCase() === "facebook ads") ?
                                      // null :
                                      // <p>${item?.converted?.value?.toFixed(2)}</p>
                                      item?.converted?.value ?
                                      <p>${item?.converted?.value?.toFixed(2)}</p> : null
                                    }
                                  </div>
                                  <div className="seller">
                                    <p>
                                      Seller:{" "}
                                      <Link
                                        to={`/seller/${
                                          item?.unique_id
                                        }?case_id=${id}&about=${currentCategory === "search engine" ? "search_engine" :currentCategory === "social media" ? "social_media" : "amazon"}`}
                                      >
                                        {item?.seller?.name?.substring(0, 20)}
                                        {item?.title?.length > 20 && "..."}
                                      </Link>
                                    </p>
                                  </div>
                                  <div className="date">
                                    <p>
                                      {moment(item?.last_seen).format("Do MMM YYYY")}
                                    </p>
                                  </div>
                                </>
                              }
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                  {data?.length ? (
                    <div className="pagination-div">
                      <Pagination
                        className="pagination"
                        defaultCurrent={page}
                        total={total}
                        pageSize={limit}
                        onChange={(e) => setPage(e)}
                      />
                      <div className="row-select-div">
                        {
                          !isMobile && <label>Show:</label>
                        }
                        <Select
                          className="row-select"
                          defaultValue={limit}
                          value={limit}
                          options={[
                            {
                              value: 8,
                              label: isMobile ? "8 rows" : "2 rows",
                            },
                            {
                              value: 16,
                              label: isMobile ? "16 rows" : "4 rows",
                            },
                            {
                              value: 36,
                              label: isMobile ? "36 rows" :  "8 rows",
                            },
                            {
                              value: 64,
                              label: isMobile ? "64 rows" :  "16 rows",
                            },
                          ]}
                          onChange={(value) => setLimit(value)}
                          suffixIcon={<SelectIcon style={{ marginLeft: 20 }} />}
                          size="default"
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="listing-table">
                  <TableContainer
                    data={data}
                    columns={currentCategory === "seller" ? sellerColumns : currentCategory === "social media" ? socialMediaColumns : columns}
                    loading={loading}
                    total={total}
                    page={page}
                    setPage={setPage}
                    rows={limit}
                    setRows={setLimit}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Card>

      <Modal
        open={selectedProduct?.id}
        onCancel={() => {
          setSelectedProduct({});
          setImageIndex(0);
          setModalTab("listing_details");
        }}
        footer={[]}
        title="Listing Details"
        width={1195}
        className="listing-details-modal"
      >
        <Tabs defaultActiveKey={modalTab} activeKey={modalTab} onChange={e => setModalTab(e)}>
          <TabPane tab="Listing  details" key="listing_details" />
          {
            (
              user?.role_id === 1 ||
              user?.role_id === 2 ||
              user?.role_id === 4 ||
              user?.role_id === 6 ||
              (user?.role_id === 3 && user?.company_details?.add_on_services?.includes("test purchase")) ||
              (user?.role_id === 5 && user?.client_details?.add_on_services?.includes("test purchase"))
            ) ?
            <TabPane tab="Test purchase" key="test_purchase" /> :
            <></>
          }
        </Tabs>
        {
          modalTab === "listing_details" ?
          <Row className="listing-details-row">
            <Col xs={24} lg={13}>
              <div style={{position: "sticky", top: 10}}>
                {
                  (
                    selectedProduct?.image?.length > 0 && (
                      user?.role_id === 1 ||
                      user?.role_id === 2 ||
                      user?.role_id === 4 ||
                      (user?.role_id === 5 && user?.client_details?.add_on_services?.includes("visual search")) ||
                      user?.role_id === 6 ||
                      (user?.company_details?.add_on_services?.includes("visual search"))
                    )
                  ) ?
                  <>
                  {
                    uploading ?
                    <Spin className="visual-search-btn" /> :
                    <VisualSearch className="visual-search-btn" onClick={() => initiateVisualSearchHandler()} /> 
                  }
                  </>
                  : <></>
                }
                <Image
                  src={
                    selectedProduct?.image?.length > 0 &&
                    selectedProduct?.image[imageIndex]
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = NoImage;
                  }}
                  width={isMobile ? "100%" : 552}
                  height={isMobile ? "100%" : 450}
                />
                <div className="preview-grid-div">
                  {selectedProduct?.image?.length > 0 &&
                    selectedProduct?.image?.map((src, i) => (
                      <img
                        src={src}
                        alt=""
                        className={
                          imageIndex === i
                            ? "preview-grid-item preview-grid-item-active"
                            : "preview-grid-item"
                        }
                        key={i}
                        onClick={() => setImageIndex(i)}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = NoImage;
                        }}
                      />
                    ))}
                </div>
              </div>
            </Col>
            <Col xs={24} lg={11}>
              <div className={`status-div ${selectedProduct?.status}`}>
                <Tag className={`${selectedProduct?.status}`}>
                  {selectedProduct?.status}
                </Tag>
                <p className="date">
                  {moment(selectedProduct?.status_change).format("Do MMM YYYY")}
                </p>
              </div>
              <p className="title">{selectedProduct?.title}</p>
              <p className="desc">
                {
                  parse(`${selectedProduct?.description}`)
                  // selectedProduct?.description
                }
                </p>
              <a
                href={selectedProduct?.link}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                visit link <Link />
              </a>
              <hr />
              <p className="line" style={{marginBottom: 0}}>
                Keyword: <span>{selectedProduct?.keyword || selectedProduct?.asset}</span>
              </p>
              {
                currentCategory === "social media" ? <></> :
                <>
                <hr />
                <p className="line">
                  Original price: <span>{selectedProduct?.price}</span>
                </p>
                <p className="line">
                  Converted price:{" "}
                  <span>${selectedProduct?.converted?.value?.toFixed(2)}</span>
                </p>
                </>
              }
              <hr />
              <p className="line">
                Seller:{" "}
                <a
                  href={`/seller/${
                    selectedProduct?.unique_id
                  }?case_id=${id}&about=${currentCategory === "search engine" ? "search_engine" :currentCategory === "social media" ? "social_media" : "amazon"}`}
                  // target="_blank"
                  rel="noreferrer"
                >
                  {selectedProduct?.seller?.name}
                </a>
              </p>
              <p className="line">
                Platform:{" "}
                <a
                  href={`https://${selectedProduct?.platform_link}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectedProduct?.platform}
                </a>
              </p>
              <hr />
              <Form layout="vertical">
                <Form.Item label= {user?.role_id ===3 ? "Status" : "Take action"}>
                  <Select
                    size="large"
                    value={selectedProuctStatus}
                    onChange={(e) => setSelectedProductStatus(e)}
                    disabled={user?.role_id ===3}
                  >
                    {
                      allStatus?.map((status) => (
                        <Select.Option value={status?.code}>{status?.name}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                  <>
                  <hr />
                  <Form.Item label="Why are you reporting this?">
                    <Checkbox.Group
                      options={checkboxGrp}
                      value={checkedList}
                      onChange={(list) => setCheckedList(list)}
                      disabled={user?.role_id ===3}
                    />
                  </Form.Item>
                  {
                    checkedList?.includes("Other") &&
                    <Form.Item style={{marginTop: -28, marginLeft: 20}}>
                      <Input
                        placeholder="Type other infringement type here..."
                        size="large"
                        value={otherInfringementType}
                        onChange={e => setOtherInfringementType(e.target.value)}
                      />
                    </Form.Item>
                  }
                  <hr />
                  <Form.Item>
                    <Checkbox.Group
                      options={checkboxGrpTwo}
                      value={checkedListTwo}
                      onChange={(list) => setCheckedListTwo(list)}
                      disabled={user?.role_id ===3}
                    />
                  </Form.Item>
                  </>
              </Form>

              {
                user?.role_id !== 3 &&
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={() => updateSellerHandler()}
                  loading={loading}
                >
                  Update
                </Button>
              }
            </Col>
          </Row> :
          modalTab === "test_purchase" ?
          <>
            {
              testPurchaseLoading ?
              <div className="loading" style={{height: "80%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Spin size="large" />
              </div> :
              (testPurchaseSteps?.length === 0 && !testInitiated) ?
              <>
              <div className="no-steps-div">
                <TestPurchase />
                <p>Test purchase this item</p>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => setTestInitiated(true)}
                >
                  Initiate test purchase
                </Button>
              </div>
              </> : 
              <>
              <div className="steps-div">
                  {
                    testPurchaseSteps?.map((step, i) => (
                      <Collapse ghost expandIconPosition="end" className='steps collapse-steps' key={i}>
                        <div className="progress-indicator"></div>
                        <Collapse.Panel 
                          header={
                            <p className="title">{(editTP === step?.id) ? "Edit Activity" : step?.activity}</p>
                          } 
                        >
                          {
                            (editTP === step?.id) ?
                            <>
                              <Form
                                className="form"
                                layout='vertical'
                                autoComplete="off"
                              >
                                <Form.Item
                                  label="Activity"
                                  name="activity"
                                >
                                  <Select
                                    placeholder="Select activity"
                                    size="large"
                                    options={[
                                      {
                                        value: "initiated test purchase",
                                        label: "Initiated test purchase",
                                      },
                                      {
                                        value: "order placed",
                                        label: "Order placed",
                                      },
                                      {
                                        value: "product received",
                                        label: "Product received",
                                      },
                                      {
                                        value: "product reviewed",
                                        label: "Product reviewed",
                                      },
                                      {
                                        value: "product not delivered",
                                        label: "Product not delivered",
                                      },
                                      {
                                        value: "test purchased cancelled",
                                        label: "Test purchased cancelled",
                                      }
                                    ]}
                                    defaultValue={editStepData?.activity}
                                    value={editStepData?.activity}
                                    onChange={e => setEditStepData({...editStepData, activity: e})}
                                  />
                                </Form.Item>
                                <Form.Item
                                        label="Description"
                                        name="description"
                                >
                                  <Input.TextArea
                                    rows={4}
                                    placeholder="Type here...."
                                    maxLength={250}
                                    size="large"
                                    defaultValue={editStepData?.description}
                                    value={editStepData?.description}
                                    onChange={e => setEditStepData({...editStepData, description: e.target.value})}
                                  />
                                </Form.Item>
                                <Form.Item
                                        label="Insights"
                                        name="insights"
                                >
                                  <Input.TextArea
                                    rows={4}
                                    placeholder="Type here...."
                                    maxLength={250}
                                    size="large"
                                    defaultValue={editStepData?.insights}
                                    value={editStepData?.insights}
                                    onChange={e => setEditStepData({...editStepData, insights: e.target.value})}
                                  />
                                </Form.Item>
                                <Space>
                                  <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => editTPHandler(step)}
                                    loading={editLoading}
                                  >
                                    Edit activity
                                  </Button>
                                  <Button
                                    type="link"
                                    size="large"
                                    danger
                                    onClick={() => setEditTP(0)}
                                    disabled={editLoading}
                                  >
                                    Cancel
                                  </Button>
                                </Space>
                              </Form>
                            </> :
                            <>
                            <div className="desc">
                              <p className="label">Description</p>
                              <p className="value">
                                {step?.description}
                              </p>
                            </div>
                            {
                              step?.insight?.length > 0 &&
                              <div className="desc">
                                <p className="label">Insight</p>
                                <p className="value">
                                  {step?.insight}
                                </p>
                              </div>
                            }
                            <div className="panel-btn-div">
                              <Space>
                                {
                                  (confirmDeleteTP === step?.id) ?
                                  <>
                                  <Button 
                                    onClick={() => setConfirmDeleteTP(0)}
                                    disabled={deletingTP}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={() => deleteTPHandler(step)}
                                    loading={deletingTP}
                                  >
                                    Confirm Delete
                                  </Button>
                                  </> :
                                  <>
                                  <Button 
                                    onClick={() => {
                                      setEditTP(step?.id);
                                      setEditStepData({
                                        activity: step?.activity,
                                        description: step?.description,
                                        insights: step?.insight
                                      });
                                    }}
                                    icon={<EditOutlined />}
                                  />
                                  <Button 
                                    icon={<DeleteOutlined />} 
                                    onClick={() => setConfirmDeleteTP(step?.id)}
                                    danger 
                                  />
                                  </>
                                }
                              </Space>
                            </div>
                            </>
                          }
                        </Collapse.Panel>
                        <p className="date">{moment(step?.date).format("DD MMM YYYY")}</p>
                      </Collapse>
                    ))
                  }
                {
                  testInitiated ?
                  <>
                  <div className="steps">
                    <div className="progress-indicator"></div>
                    <Form
                      className="form"
                      layout='vertical'
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Activity"
                        name="activity"
                      >
                        <Select
                          placeholder="Select activity"
                          size="large"
                          options={[
                            {
                              value: "initiated test purchase",
                              label: "Initiated test purchase",
                            },
                            {
                              value: "order placed",
                              label: "Order placed",
                            },
                            {
                              value: "product received",
                              label: "Product received",
                            },
                            {
                              value: "product reviewed",
                              label: "Product reviewed",
                            },
                            {
                              value: "product not delivered",
                              label: "Product not delivered",
                            },
                            {
                              value: "test purchased cancelled",
                              label: "Test purchased cancelled",
                            }
                          ]}
                          defaultValue={newStepData?.activity}
                          value={newStepData?.activity}
                          onChange={e => setNewStepData({...newStepData, activity: e})}
                        />
                      </Form.Item>
                      <Form.Item
                              label="Description"
                              name="description"
                      >
                        <Input.TextArea
                          rows={4}
                          placeholder="Type here...."
                          maxLength={250}
                          size="large"
                          defaultValue={newStepData?.description}
                          value={newStepData?.description}
                          onChange={e => setNewStepData({...newStepData, description: e.target.value})}
                        />
                      </Form.Item>
                      <Form.Item
                              label="Date"
                              name="date"
                      >
                        <DatePicker
                                size="large"
                                format={"DD MMM YYYY"}
                                style={{width: "100%", height: 40}}
                                defaultValue={dayjs()}
                                onChange={(date, dateString) => setNewStepData({...newStepData, date: moment(dateString).format("YYYY-MM-DD")})}
                                disabled
                        />
                      </Form.Item>
                      <Form.Item
                              label="Insights"
                              name="insights"
                      >
                        <Input.TextArea
                          rows={4}
                          placeholder="Type here...."
                          maxLength={250}
                          size="large"
                          defaultValue={newStepData?.insights}
                          value={newStepData?.insights}
                          onChange={e => setNewStepData({...newStepData, insights: e.target.value})}
                        />
                      </Form.Item>
                      <Space>
                        <Button
                          type="primary"
                          size="large"
                          disabled = {!newStepData?.activity?.length || !newStepData?.description?.length}
                          onClick={() => addActivityHandler()}
                          loading={addActivityLoading}
                        >
                          Add activity
                        </Button>
                        <Button
                          type="link"
                          size="large"
                          danger
                          onClick={() => setTestInitiated(false)}
                        >
                          Cancel
                        </Button>
                      </Space>
                    </Form>
                  </div>
                  </> : 
                  <>
                  <div>
                    <Button
                      type="default"
                      icon={<PlusOutlined />}
                      className="add-activity-btn"
                      onClick={() => setTestInitiated(true)}
                    >
                      Add activity
                    </Button>
                  </div>
                  </>
                }
              </div>
              </>
            }
          </> :
          <>No tab</>
        }
      </Modal>
      <Modal
        open={selectedSeller?.id}
        onCancel={() => {
          setSelectedSeller({});
        }}
        footer={[]}
        title="Seller Details"
        width={500}
        className="listing-details-modal seller-details-modal"
      >
        <Row className="listing-details-row">
          <Col span={24}>
            <div className={`status-div ${statusFilter}`}>
              <Tag className={`${statusFilter}`}>
                {statusFilter}
              </Tag>
              <p className="date">
                {moment(selectedSeller?.last_seen).format("Do MMM YYYY")}
              </p>
            </div>
            <p className="title">{selectedSeller?.name}</p>
            <hr />
            <p className="line">
              Platform:{" "}
              <a
                  href={`https://${selectedSeller?.platform}`}
                  target="_blank"
                  rel="noreferrer"
              >
                {selectedSeller?.platform}
              </a>
            </p>
            <p className="line">
              No. of products:{" "}
                {selectedSeller?.product_count}
            </p>
            <hr />
            <Form layout="vertical">
              <Form.Item label= {user?.role_id ===3 ? "Status" : "Take action"}>
                <Select
                  size="large"
                  value={selectedSellerStatus}
                  onChange={(e) => setSelectedSellerStatus(e)}
                  disabled={user?.role_id ===3}
                >
                  {
                    allStatus?.map((status) => (
                      <Select.Option value={status?.code}>{status?.name}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Form>
            {
              user?.role_id !== 3 &&
              <Button
                type="primary"
                size="large"
                block
                onClick={() => updateSellerHandler()}
                loading={loading}
              >
                Update
              </Button>
            }
          </Col>
        </Row>
      </Modal>
      <Modal
        open={automatedTakedownPopup}
        onCancel={() => {
          setAutomatedTakedownPopup(false)
        }}
        footer={[]}
        title="Automated takedown"
        width={500}
        className="automated-takedown-modal"
      >
        <Form layout="vertical">
          <Form.Item label="Price" style={{marginBottom: 0}}>
            <Row gutter={12}>
              <Col xs={24} lg={8} style={{marginBottom: isMobile ? 16 : 0}}>
                <Select
                        size='large'
                        style={{width: "100%"}}
                        defaultValue={automatedTakedownData?.type}
                        options={[
                                {
                                        value: "less than",
                                        label: "Less than"
                                },
                                {
                                        value: "more than",
                                        label: "More than"
                                },
                        ]}
                        onChange={e => setAutomatedTakedownData({...automatedTakedownData, type: e})}
                />
              </Col>
              <Col xs={24} lg={8} style={{marginBottom: isMobile ? 16 : 0}}>
                <Select
                  size='large'
                  placeholder="select asset"
                  style={{width: "100%"}}
                  defaultValue={automatedTakedownData?.asset}
                  onChange={e => setAutomatedTakedownData({...automatedTakedownData, asset: e})}
                  allowClear
                >
                  <Select.Option value="ALL">All</Select.Option>
                  {
                    caseDetails?.keyword?.map((item, i) => (
                      <Select.Option value={item?.name} key={i}>{item?.name}</Select.Option>
                    ))
                  }
                </Select>
              </Col>
              <Col xs={24} lg={8}>
                <InputNumber
                  size="large"
                  prefix="$"
                  style={{width: "100%"}}
                  placeholder="Enter price..."
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                    }
                  }}
                  value={automatedTakedownData?.price}
                  onChange={e => setAutomatedTakedownData({...automatedTakedownData, price: e})}
                />
              </Col>
              <Col span={24} style={{display: "flex", alignItems: "flex-end", justifyContent: "space-between"}}>
                <p
                  style={{color: "#144ee3", marginBottom: 5, cursor: "pointer"}}
                  onClick={() => setTakedownHistory(true)}
                >
                  View takedown history
                </p>
                <Button
                  type="primary"
                  size="large"
                  style={{float: "right", marginTop: 24}}
                  disabled={automatedTakedownData?.price === null || automatedTakedownData?.price === 0 || automatedTakedownData?.asset === undefined}
                  loading={automatedtakedownLoading}
                  onClick={() => automatedTakedownHandler()}
                >
                  Takedown
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={recrawlModal}
        onCancel={() => {
          setRecrawlModal(false)
        }}
        footer={[]}
        title="Recrawl period"
        width={500}
        className="automated-takedown-modal"
      >
        {
          recrawlLoading ?
          <div className="loading-div" style={{display:"flex", alignItems: "center", justifyContent: "center", height: 236}}><Spin /></div> :
          <Radio.Group 
            defaultValue={recrawlPeriod}
            value={recrawlPeriod} 
            onChange={e => setRecrawlperiod(e.target.value)}
            className="recrawl-radio-group"
          >
            <Radio value="1">Daily</Radio>
            <Radio value="7">Weekly</Radio>
            <Radio value="15">Biweekly</Radio>
            <Radio value="30">Monthly</Radio>
            <Radio value="90">Quaterly</Radio>
          </Radio.Group>
        }
        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 12}}>
          <Button
            size="large"
            type="primary"
            loading={recrawlLoading || recrawlUpdating}
            onClick={() => updateRecrawlHandler()}
          >
            Update
          </Button>
        </div>
      </Modal>
      <Modal
        open={openFilters}
        onCancel={() => setOpenFilters(false)}
        footer={[]}
        title="Sorts & Filters"
      >
        <div className="filter-section" style={{marginBottom: 24}}>
          <p className="modal-title">Sort by</p>
          <Row gutter={24}>
            <Col span={12}>
              <Select
                placeholder="Select term here..."
                size="large"
                style={{width: "100%"}}
                defaultValue={sortBy}
                value={sortBy}
                onChange={e => setSortBy(e)}
              >
                {
                  currentCategory === "seller" ?
                  <>
                  <Select.Option value="last_seen">Last seen</Select.Option>
                  <Select.Option value="product_count">Product count</Select.Option>
                  </>
                  :
                  <>
                    <Select.Option value="last_seen">Last seen</Select.Option>
                    <Select.Option value="converted_price">Price</Select.Option>
                  </>
                }
              </Select>
            </Col>
            <Col span={12}>
              <Select
                placeholder="Select order here..."
                size="large"
                style={{width: "100%"}}
                defaultValue={sortOrder}
                value={sortOrder}
                onChange={e => setSortOrder(e)}
              >
                <Select.Option value="ASC">{(sortBy === "converted_price" || sortBy === "product_count") ? "Low to High" : "Ascending"}</Select.Option>
                <Select.Option value="DESC">{(sortBy === "converted_price" || sortBy === "product_count") ? "High to Low" : "Descending"}</Select.Option>
              </Select>
            </Col>
          </Row>
        </div>
        <div className="filter-section" style={{marginBottom: 24}}>
          <p className="modal-title">Country</p>
          <Select 
            style={{width: '100%'}}
            placeholder="Select country"
            size="large"
            value={countryFilter}
            defaultValue={countryFilter}
            onChange={e => setCountryFilter(e)}
          >
            {
              countryList?.map((country, i) => (
                <Select.Option key={country?.value?.toLowerCase()} value={country?.value?.toLocaleLowerCase()}>
                  {country?.label}
                </Select.Option>
              ))
            }
          </Select>
        </div>
        <div className="filter-section" style={{marginBottom: 24}}>
          <p className="modal-title">Date</p>
          <DatePicker.RangePicker
            size='large'
            className='date-selector'
            style={{height: 40}}
            // defaultValue={[dayjs(startDate, "YYYY-MM-DD"), dayjs(endDate, "YYYY-MM-DD")]}
            defaultValue={[startDate, endDate]}
            format="DD MMM YYYY"
            onChange={(date, dateString) => {
              setStartDate(moment(dateString[0]).format("YYYY-MM-DD"));
              setEndDate(moment(dateString[1]).format("YYYY-MM-DD"));
            }}
          />
        </div>
        <div className="filter-section" style={{marginBottom: 24}}>
          <p className="modal-title">Price</p>
          <Row gutter={24}>
            <Col span={12}>
              <Input
                size="large"
                placeholder="Min price"
                type="number"
                defaultValue={minPrice}
                value={minPrice}
                onChange={e => setMinPrice(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <Input
                size="large"
                placeholder="Max price"
                type="number"
                defaultValue={maxPrice}
                value={maxPrice}
                onChange={e => {
                  setMaxPrice(e.target.value);
                }}
                disabled={!minPrice?.length}
              />
            </Col>
          </Row>
        </div>
        <div className="filter-section">
          <p className="modal-title">
            {
              currentCategory === "search engine" ?
              "Search engines" :
              currentCategory === "social media" ?
              "Social media" :
              "Marketplaces"
            }
            </p>
          <Select
            placeholder={currentCategory === "search engine" ? "Select search engine..." : currentCategory === "social media" ? "Select social media..." : "Select marketplaces..."}
            defaultValue={marketplaceFilter}
            value={marketplaceFilter}
            onChange={e => setMarketplaceFilter(e)}
            size="large"
            mode="multiple"
            className="filters-select"
            style={{width: "100%"}}
          >
            {
              currentCategory === "search engine" ?
              caseDetails?.search_engine?.map((site, i) => (
                <Select.Option value={site?.name?.toLowerCase()} key={i}>{site?.name?.toLowerCase()}</Select.Option>
              )) :
              currentCategory === "social media" ?
              caseDetails?.social_media?.media?.map((site, i) => (
                <Select.Option value={site?.toLowerCase()} key={i}>{site?.toLowerCase()}</Select.Option>
              )) :
              caseDetails?.ecom?.sites?.map((site, i) => (
                <Select.Option value={site?.toLowerCase()} key={i}>{site?.toLowerCase()}</Select.Option>
              ))
            }
          </Select>
          {/* <Checkbox.Group
            defaultValue={marketplaceFilter}
            value={marketplaceFilter}
            onChange={e => setMarketplaceFilter(e)}
            className='filters-checkbox'
          >
            {
              caseDetails?.ecom?.sites?.map((site, i) => (
                <Checkbox value={site?.toLowerCase()} key={i}>{site?.toLowerCase()}</Checkbox>
              ))
            }
          </Checkbox.Group> */}
        </div>
        <div className="filter-section">
          <p className="modal-title">Flag</p>
          <Radio.Group
            defaultValue={flag}
            value={flag}
            onChange={e => setFlag(e.target.value)}
            className='filters-radio-group'
          >
            <Radio value="">All</Radio>
            <Radio value="flagged">Flagged</Radio>
            <Radio value="non-flagged">Non flagged</Radio>
          </Radio.Group>
        </div>
        <div className="filter-section">
          <p className="modal-title">Infringement types</p>
          <Checkbox.Group
          defaultValue={reportingFor}
          value={reportingFor}
          onChange={e => setReportingFor(e)}
          className='filters-checkbox'
        >
          <Checkbox value="counterfeit">Counterfeit</Checkbox>
          <Checkbox value="replica">Replica</Checkbox>
          <Checkbox value="copyright">Copyright</Checkbox>
          <Checkbox value="brand abuse">Brand Abuse</Checkbox>
          <Checkbox value="other">Other</Checkbox>
        </Checkbox.Group>
        </div>
        <div className="button-section" style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
          <Space>
            <Button
              type="default"
              size="large"
              onClick={() => {
                setCountryFilter("");
                setFlag("");
                setReportingFor([]);
                setMinPrice("");
                setMaxPrice("");
                setStartDate(undefined);
                setEndDate(undefined);
                setMarketplaceFilter([]);
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => {getDataHandler(); setOpenFilters(false)}}
            >
              Apply filters
            </Button>
          </Space>
        </div>
      </Modal>
      <Modal
        open={openUpdateStatus}
        onCancel={() => setOpenUpdateStatus(false)}
        footer={[]}
        title={`Update status of ${selectedRows?.length} listings`}
        width={500}
      >
        <Form layout="vertical">
          <Form.Item label="Status" name="status">
            <Select
              placeholder="Select status..."
              defaultValue={multipleListingsUpdateStatus}
              value={multipleListingsUpdateStatus}
              onChange={e => setMultipleListingsUpdateStatus(e)}
            >
              {
                allStatus?.map((status, i) => (
                  <Select.Option value={status?.code} key={i}>{status?.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 12}}>
            <Button
              size="large"
              type="primary"
              onClick={() => UpdateMultipleListingsHandler()}
              loading={updating}
            >
              Update listings
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        className="import-modal"
        open={importPopup}
        onCancel={() => setImportPopup(false)}
        footer={[]}
        title="Upload CSV"
        width={500}
      >
        <Upload.Dragger
          className="file-uploader"
          customRequest={file => {
            if(file?.file?.type === "application/pdf" || file?.file?.type === "text/csv") {
              setImportFile(file?.file)
            }
            else {
              message.error("CSV allowed only")
            }
          }}
        >
          <div className='upload-div'>
            <p className='para'>Drag and drop a file here, or:</p>
            <Options />
          </div>
        </Upload.Dragger>
        <p className="support-type">Supported file type: CSV</p>
        {
          importFile?.uid &&
          <div className='file-details'>
            <p>{importFile?.name}</p>
            <Button type='link' onClick={() => setImportFile(null)}>
              <DeleteOutlined />
            </Button>
          </div>
        }
        {
          importFile?.uid &&
          <>
          <p style={{marginBottom: 6}}><label>Category</label></p>
          <Select
            placeholder="Select category..."
            size="large"
            style={{width: "50%"}}
            options={categoryList}
            defaultValue={selectedCategory}
            value={selectedCategory}
            onChange={e => setSelectedCategory(e)}
          />
          <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 12, marginBottom: 8}}>
            <Button
              size="large"
              type="primary"
              onClick={() => importHandler()}
              loading={importing}
            >
              Upload Csv
            </Button>
          </div>
          </>
        }
        <p
          style={{
            width: "fit-content",
            marginleft: "auto",
            color: "#144ee3",
            cursor: "pointer"
          }}
          onClick={() => setImportHistoryPopup(true)}
        >
          View import history
        </p>
      </Modal>
      <Modal
        className="import-modal"
        open={importHistoryPopup}
        onCancel={() => setImportHistoryPopup(false)}
        footer={[]}
        title="Import History"
        width={700}
      >
        <div className="listing-table">
          <TableContainer
            data={importData}
            columns={importDataColumns}
            loading={importDataLoading}
            total={importDataTotal}
            page={importDataPage}
            setPage={setImportDataPage}
            rows={importDataLimit}
            setRows={setImportDataLimit}
            hideSelectAll={true}
          />
        </div>
      </Modal>
      <Modal
       className="import-modal"
       open={takedownHistory}
       onCancel={() => setTakedownHistory(false)}
       footer={[]}
       title="Automated Takedown History"
       width={800}
      >
        <TableContainer
            data={tdhData}
            columns={tdhColumns}
            loading={tdhLoading}
            hideSelectAll={true}
            isSelectable={false}
            pagination={false}
          />
      </Modal>
    </div>
  );
};

export default CaseListings;
