import { Button, Card, Collapse, Form, Input, InputNumber, Modal, Select, Space } from 'antd'
import React,{useContext, useEffect, useState} from 'react'
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import KeywordInput from '../../components/KeywordInput';
import {ReactComponent as Tick} from '../../assets/images/tick.svg';
import {ReactComponent as AddRuleIcon} from '../../assets/images/add-rule.svg';
import { MainContext } from '../../utils/context';

const { Panel } = Collapse;

const Rules = ({
        rules = [],
        setRules = () => {},
        successPopup = false,
        setSuccessPopup = () => {},
        allStatus = []
}) => {

        const {assets} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [defaultActivekey, setDefaultActiveKey] = useState(1);
        const [assetsList, setAssetsList] = useState([]);

        const addRuleHandler = () => {
                setRules([
                        ...rules,
                        {
                                name: `rule ${rules?.length + 1}`,
                                assets: [],
                                condition: {
                                        title: {
                                                type: "contains",
                                                keywords: []
                                        },
                                        price: {
                                                type: "less than",
                                                value: "",
                                                currency: "USD"
                                        }
                                },
                                marketplace: [],
                                seller_flag: "flagged"
                        }
                ]);
        }

        const updateNameField = (index, e, key) => {
                const newArr = [...rules]
                newArr[index][key] = e;
                setRules(newArr)
        }
        const updateTitleField = (index, e, key) => {
                const newArr = [...rules]
                newArr[index].condition.title[key] = e;
                setRules(newArr);
        }
        const updatePriceField = (index, e, key) => {
                const newArr = [...rules]
                newArr[index].condition.price[key] = e
                setRules(newArr)
        }
        const updateSellerFlag = (index, e, key) => {
                const newArr = [...rules]
                newArr[index][key] = e
                setRules(newArr)
        }

        // useEffect(() => {
                // let flatList = assets.flatMap(asset => asset?.keywords);
                // setAssetsList(flatList)
                // console.log(assets)
        // }, [assets])

  return (
    <>
    {
        rules?.map((rule, i) => (
                <Card className='card form-card rules-form-card' key={i}>
                        <MinusCircleOutlined 
                                className='delete-icon'
                                onClick={() => {
                                        let newArr = rules?.filter((item, index) => index !== i );
                                        setRules(newArr)
                                }}
                        />
                        <Collapse ghost defaultActiveKey={[i+1]} expandIconPosition="end" className='assets-collapse'>
                                <Panel 
                                        header={
                                        <h3 className="form-card-title rule-name-title">
                                                {rule?.name} 
                                        </h3>
                                        } 
                                        key={i+1}
                                >    
                                        <Form.Item
                                                name="ruleName"
                                        >
                                                <div style={{marginBottom: 8}}><label>Rule Name</label></div>
                                                <Input 
                                                        size='large' 
                                                        placeholder='Type here....'
                                                        defaultValue={rule?.name}
                                                        onChange={e => updateNameField(i, e.target.value, "name")}
                                                />
                                        </Form.Item>
                                        {/* <KeywordInput indexKey={i} component='rules' /> */}
                                        <Form.Item
                                                name="Assets"
                                        >
                                                <div style={{marginBottom: 8}}><label>Assets</label></div>
                                                <Select
                                                        placeholder="Select assets..."
                                                        size='large'
                                                        mode='multiple'
                                                        defaultValue={rules[i]?.assets}
                                                        value={rules[i]?.assets}
                                                        onChange={array => {
                                                                let newArr = [...rules];
                                                                newArr[i].assets = array;
                                                                setRules(newArr);
                                                        }}
                                                >
                                                        {
                                                                assets?.map((asset, i) => (
                                                                        <Select.Option key={i} value={asset?.name} >{asset?.name}</Select.Option>
                                                                ))
                                                        }
                                                </Select>
                                        </Form.Item>
                                        <Form.Item
                                                label="Condition"
                                        >
                                                <Space direction={isMobile ? "vertical" : "horizontal"} style={{width: isMobile ? "100%" : ""}}>
                                                        <p style={{width: 40}}><label>Title</label></p>
                                                        <Select
                                                                size='large'
                                                                style={{width: isMobile ? "100%" : 120}}
                                                                defaultValue="contains"
                                                                options={[
                                                                        {
                                                                                value: "contains",
                                                                                label: "Contains"
                                                                        },
                                                                        {
                                                                                value: "not-contains",
                                                                                label: "Not contains"
                                                                        },
                                                                ]}
                                                                onChange={e => updateTitleField(i, e, "type")}
                                                        />
                                                        <KeywordInput indexKey={i} component='title' placeholder="Type here..." style={{display: "none"}} />
                                                        {/* <Input 
                                                                size='large' 
                                                                placeholder='Enter Keywords (eg. shoes, T-shirt)'
                                                                style={{height: 40, width: 400}}
                                                        /> */}
                                                </Space>
                                                <Space style={{marginTop: 16, width: isMobile ? "100%" : ""}} direction={isMobile ? "vertical" : "horizontal"}>
                                                        <p style={{width: 40}}><label>Price</label></p>
                                                        <Select
                                                                size='large'
                                                                style={{width: isMobile ? "100%" : 120}}
                                                                defaultValue="less than"
                                                                options={[
                                                                        {
                                                                                value: "less than",
                                                                                label: "Less than"
                                                                        },
                                                                        {
                                                                                value: "more than",
                                                                                label: "More than"
                                                                        },
                                                                ]}
                                                                onChange={e => updatePriceField(i, e, "type")}
                                                        />
                                                        <Input 
                                                                prefix="$"
                                                                size='large' 
                                                                style={{height: 40, width: isMobile ? "100%" : 400}}
                                                                onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                        }
                                                                }}
                                                                onChange={e => updatePriceField(i, e.target.value, "value")}
                                                        />
                                                </Space>
                                        </Form.Item>
                                        <Form.Item name="sellerFlag" style={{marginBottom: 0}}>
                                                <div style={{marginBottom: 8}}><label>Seller Flag</label></div>
                                                <Select
                                                        style={{
                                                        }}
                                                        size='large'
                                                        defaultValue={rule?.seller_flag}
                                                        value={rule?.seller_flag}
                                                        onChange={e => updateSellerFlag(i, e, "seller_flag")}
                                                >
                                                        <Select.Option value="flagged">Flagged</Select.Option>
                                                        {
                                                                allStatus
                                                                ?.filter(item => item?.code !== "reported" && item?.code !== "removed")
                                                                ?.map((status, i) => (
                                                                        <Select.Option value={status?.code} key={i}>{status?.name}</Select.Option>
                                                                ))
                                                        }

                                                </Select>
                                        </Form.Item>
                                </Panel>
                        </Collapse>
                </Card>
        ))
    }
        <Card className='card form-card add-rule-card'>
                <AddRuleIcon />
                <p className="desc">Set smart rules to automate the entire reporting process</p>
                <Button
                        type='primary'
                        onClick={addRuleHandler}
                        style={{
                                padding: "0px 16px",
                                height: 40
                        }}
                >
                        <PlusOutlined /> Add New Rule
                </Button>
        </Card>
        <Modal open={successPopup} footer={[]} centered className='success-modal' width={420}>
                <Tick />
                <p className="modal-title">
                        Case has been created successfully.
                </p>
        </Modal>
    </>
  )
}

export default Rules