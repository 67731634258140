import React, { useContext, useEffect, useState } from 'react'
import './index.css'
import { Card, Checkbox, Col, Pagination, Row, Select, Space, Spin, message } from 'antd';
import { ReactComponent as BackBtn } from "../../assets/images/back-icon-btn.svg";
import { ReactComponent as Link } from "../../assets/images/link.svg";
import { ReactComponent as SelectIcon } from "../../assets/images/select-icon.svg";
import { useNavigate, useParams } from 'react-router';
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import NoImage from "../../assets/images/no-image.jpeg";
import moment from 'moment';
import NoData from '../../components/NoData';

const VisualSearchDetails = () => {

        const navigate = useNavigate();
        const { id } = useParams(); 

        const {token, setToken, setUser, logoutHandler} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [page, setPage] = useState(1);
        const [rows, setRows] = useState(8);
        const [total, setTotal] = useState(0);
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState([]);
        const [uploadedImages, setUploadedImages] = useState([]);
        const [active, setActive] = useState(0);

        const getDetailsHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                };

                fetch(`${apiUrl}/get-image-search-data/${id}?page=${page}&rows=${rows}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setLoading(false);
                        if(res?.status === 1) {
                                const object = res.data;
                                delete object.page;
                                delete object.rows;
                                delete object.total;
                                delete object.active;
                                setData(Object?.values(object));
                                setTotal(res?.total)
                                setActive(res?.active)
                                setUploadedImages(res?.uploaded_image)
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                      })
                      .catch(res => {
                        setLoading(false);
                        console.log('error', res)
                      });
        }

        useEffect(() => {
                getDetailsHandler()
        }, [page, rows])


  return (
        <div className="cases-div case-listings-div visual-search-details-div">
                <Card className="card cases-card visual-search-details-card">
                        <Row gutter={10}>
                                <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                        <BackBtn
                                        style={{ marginRight: 12, cursor: "pointer" }}
                                        onClick={() => navigate(-1)}
                                        />
                                        <h3 className="page-title">
                                                Visual search for
                                        </h3>
                                </Col>
                        </Row>
                        <hr style={{margin: '28px 0 24px 0', border: 'none', height: 1, background: '#E6E6E6'}} />
                        <div className="img-div">
                                <Space size="large" wrap>
                                        {
                                                uploadedImages?.map((img, i) => (
                                                        <img key={i} src={img} alt="img" className="uploaded-img" />
                                                ))
                                        }
                                </Space>
                        </div>
                        <div className="cta-div">
                                {
                                        active === 1 && <p className="sub-title">Showing results ({total || '--'})</p>
                                }
                        </div>
                        <div className="table-div">
                                <Row className="listing-row" gutter={[24, 24]}>
                                        {
                                                loading ?
                                                <div className="loading" style={{width: "100%"}}>
                                                        <Spin size="large" />
                                                </div> :
                                                active === 0 ? 
                                                <div style={{marginLeft: "auto", marginRight: "auto"}}>
                                                        <NoData 
                                                                title="Image search is in progress" 
                                                                desc={`Visual search listings will appear here`}
                                                        />
                                                </div> :
                                                data?.length ?
                                                data?.map((item, i) => (
                                                        <Col key={i} xs={24} lg={6} className="listing-col">
                                                        <div className="col-div">
                                                        {/* <Checkbox
                                                        className="list-checkbox"
                                                        value={item?.unique_id}
                                                        checked={selected?.includes(item?.unique_id)}
                                                        onChange={(e) => {
                                                                if(selected?.includes(e.target.value)) {
                                                                        const newArr = selected?.filter(item => item !== e.target.value);
                                                                        setSelected(newArr)
                                                                }
                                                                else {
                                                                        setSelected([...selected, e.target.value])
                                                                }
                                                        }}
                                                        /> */}
                                                        {
                                                                item?.thumbnail ?
                                                                <img
                                                                        src={item?.thumbnail}
                                                                        alt={item?.title}
                                                                        className="listing-img"
                                                                        onError={({ currentTarget }) => {
                                                                                currentTarget.onerror = null; // prevents looping
                                                                                currentTarget.src = NoImage;
                                                                        }}
                                                                /> :
                                                                <img
                                                                        src={NoImage}
                                                                        alt={item?.title}
                                                                        className="listing-img"
                                                                        onError={({ currentTarget }) => {
                                                                                currentTarget.onerror = null; // prevents looping
                                                                                currentTarget.src = NoImage;
                                                                        }}
                                                                />
                                                        }
                                                        <div
                                                        className="text-div"
                                                        >
                                                                <>
                                                                {/* <div className="platform">
                                                                {item?.platform_image?.length ? (
                                                                <img
                                                                        src={item?.platform_image}
                                                                        alt={item?.platform}
                                                                />
                                                                ) : (
                                                                <div className="img-holder"></div>
                                                                )}
                                                                <p>
                                                                        {item?.source?.substring(0, 20)}
                                                                        {item?.source?.length > 20 && "..."}
                                                                </p>
                                                                </div> */}
                                                                <div className="title">
                                                                <p>
                                                                {item?.title?.substring(0, 45)}
                                                                {item?.title?.length > 45 && "..."}
                                                                </p>
                                                                <a
                                                                href={item?.link}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                >
                                                                <Link />
                                                                </a>
                                                                </div>
                                                                {/* <div className="price">
                                                                <p>{item?.price || '$--'}</p>
                                                                </div> */}
                                                                {/* <div className="seller">
                                                                <p>
                                                                Seller:{" "}
                                                                <a
                                                                        href={`/seller/${
                                                                        item?.unique_id
                                                                        }?case_id=${id}&about=${"amazon"}`}
                                                                >
                                                                        {item?.seller?.name?.substring(0, 20)}
                                                                        {item?.title?.length > 20 && "..."}
                                                                </a>
                                                                </p>
                                                                </div> */}
                                                                <div className="date">
                                                                <p>
                                                                {moment(item?.last_seen).format("Do MMM YYYY") || "10th Apr 2023"}
                                                                </p>
                                                                </div>
                                                                </>
                                                        </div>
                                                        </div>
                                                        </Col>
                                                )) : 
                                                <div style={{marginLeft: "auto", marginRight: "auto"}}>
                                                        <NoData 
                                                                title="No listings" 
                                                                desc={`Visual search listings will appear here`}
                                                        />
                                                </div>
                                        }
                                </Row>
                                {(active === 1 && data?.length) ? (
                                        <div className="pagination-div">
                                                <Pagination
                                                        className="pagination"
                                                        defaultCurrent={page}
                                                        total={total}
                                                        pageSize={rows}
                                                        onChange={(e) => setPage(e)}
                                                />
                                                <div className="row-select-div">
                                                        {
                                                                !isMobile && <label>Show:</label>
                                                        }
                                                        <Select
                                                        className="row-select"
                                                        options={[
                                                        {
                                                        value: 8,
                                                        label: isMobile ? "8 rows" : "2 rows",
                                                        },
                                                        {
                                                        value: 16,
                                                        label: isMobile ? "16 rows" : "4 rows",
                                                        },
                                                        {
                                                        value: 36,
                                                        label: isMobile ? "36 rows" : "8 rows",
                                                        },
                                                        {
                                                        value: 64,
                                                        label: isMobile ? "64 rows" : "16 rows",
                                                        },
                                                        ]}
                                                        defaultValue={{
                                                        value: 8,
                                                        label: isMobile ? "8 rows" : "2 rows",
                                                        }}
                                                        onChange={(value) => setRows(value)}
                                                        suffixIcon={<SelectIcon style={{ marginLeft: 20 }} />}
                                                        size="default"
                                                        />
                                                </div>
                                        </div>
                                ) : null}
                        </div>
                </Card>
        </div>
  )
}

export default VisualSearchDetails