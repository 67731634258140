import React, { useContext } from 'react'
import './index.css'
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Tag, Tooltip, theme } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { MainContext } from '../../utils/context';

const KeywordInput = ({
  name = "keyword",
  label = "keywords",
  inputTags = {},
  setInputTags = () => {},
  indexKey = null,
  component = "",
  disabled = false,
  placeholder="Add assets..."
}) => {

        const {assets, setAssets, rules, setRules} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const { token } = theme.useToken();
        const [tags, setTags] = useState(
          component === "assets" ? assets[indexKey]?.keywords :
          component === "update" ? inputTags?.keyword[indexKey]?.keywords : 
          component === "update-assets" ? inputTags?.rules[indexKey]?.assets :
          component === "update-title" ? inputTags?.rules[indexKey]?.condition?.title?.keywords :
          []);
        const [inputVisible, setInputVisible] = useState(true);
        const [inputValue, setInputValue] = useState('');
        const [editInputIndex, setEditInputIndex] = useState(-1);
        const [editInputValue, setEditInputValue] = useState('');
        const inputRef = useRef(null);
        const editInputRef = useRef(null);
        useEffect(() => {
          if (inputVisible) {
            inputRef.current?.focus();
          }
        }, [inputVisible]);
        useEffect(() => {
          editInputRef.current?.focus();
        }, [inputValue]);
        const handleClose = (removedTag) => {
          const newTags = tags.filter((tag) => tag !== removedTag);
          console.log(newTags);
          setTags(newTags);
        };
        const showInput = () => {
          setInputVisible(true);
        };
        const handleInputChange = (e) => {
          setInputValue(e.target.value);
        };
        const handleInputConfirm = () => {
          if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
          }
        //   setInputVisible(false);
          setInputValue('');
        };
        const handleEditInputChange = (e) => {
          setEditInputValue(e.target.value);
        };
        const handleEditInputConfirm = () => {
          const newTags = [...tags];
          newTags[editInputIndex] = editInputValue;
          setTags(newTags);
          setEditInputIndex(-1);
          setInputValue('');
        };
        const tagInputStyle = {
          width: "100%",
          verticalAlign: 'top',
        };
        const tagPlusStyle = {
          background: token.colorBgContainer,
          borderStyle: 'dashed',
        };


        useEffect(() => {
          if(component === 'assets') {
            let newArr = [...assets];
            newArr[indexKey].keywords = tags;
            setAssets(newArr);
          }
          if(component === 'rules') {
            let newArr = [...rules];
            newArr[indexKey].assets = tags;
            setRules(newArr);
          }
          if(component === "update") {
                let newArr = [...inputTags?.keyword];
                newArr[indexKey].keywords = tags;
                setInputTags({...inputTags, keyword: newArr})
          }
          if(component === "title") {
            let newArr = [...rules];
            newArr[indexKey].condition.title.keywords = tags;
            setRules(newArr)
          }
          if(component === "update-assets") {
            let newArr =[...inputTags?.rules];
            newArr[indexKey].assets = tags;
            setInputTags({...inputTags, rules: newArr});
          }
          if(component === "update-title") {
            let newArr = [...inputTags?.rules];
            newArr[indexKey].condition.title.keywords = tags;
            setInputTags({...inputTags, rules: newArr});
          }
        }, [tags])

        return (
          <Form.Item
            name={name}
            label={ (component === 'rules' || component === 'title' || component === 'update-assets' || component === 'update-title') ? null : label}
          >
            {
              component === 'rules' &&
              <div><label>Assets</label></div>
            }
            {
              component === 'update-assets' &&
              <div><label>Assets</label></div>
            }
            <Space size={[0, 0]} wrap className='base-space' style={component === "title" ? {marginBottom: -8, width: isMobile ? "100%" : 400} : component === "update-title" ? {marginTop: 8, marginBottom: -10, width: isMobile ? "100%" : 400} : {marginTop: 8, minHeight: 40}}>
              <Space size={[0, 0]} wrap className='tags-space' style={{display: tags?.length === 0 ? "none": "", marginBottom: -8}}>
                {tags.map((tag, index) => {
                  if (editInputIndex === index) {
                    return (
                      <Input
                        ref={editInputRef}
                        key={tag}
                        size="small"
                        style={tagInputStyle}
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                      />
                    );
                  }
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable = {!disabled}
                      style={{
                        userSelect: 'none',
                      }}
                      onClose={() => handleClose(tag)}
                    >
                      <span
                        onDoubleClick={(e) => {
                          if (index !== 0 && !disabled) {
                            setEditInputIndex(index);
                            setEditInputValue(tag);
                            e.preventDefault();
                          }
                        }}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </span>
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}
                {
                  (tags?.length > 0 && !disabled) ?
                  <Tag 
                    style={{background: "white", border: "1.5px dashed #2563EB", padding: 6.5, cursor: "pointer"}}
                    onClick={() => setTags([])}
                  >
                    Clear all
                  </Tag> : null
                }
              </Space>
              {(inputVisible && disabled) ?
                <></> :
                inputVisible? (
                <Input
                  ref={inputRef}
                  type="text"
                  size="small"
                  style={tagInputStyle}
                  value={inputValue}
                  defaultValue={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                  placeholder={placeholder}
                  bordered={false}
                  className='keywords-input'
                />
              ) : (
                <Tag style={tagPlusStyle} onClick={showInput} disabled>
                  <PlusOutlined /> New Tag
                </Tag>
              )}
            </Space>
          </Form.Item>
        );
}

export default KeywordInput