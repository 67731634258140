import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
  Upload,
  message,
} from "antd";
import { SearchOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { MainContext } from "../../utils/context";
import { apiUrl } from "../../utils/constants";
import TableContainer from "../../components/TableContainer";
import { ReactComponent as Action } from "../../assets/images/action.svg";
import { useNavigate } from "react-router";

const Categories = () => {

  const navigate = useNavigate();

  const { token, logoutHandler } = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([{name: "", text: undefined}]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [selectedCat, setSelectedCat] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [addCatPopup, setAddCatPopup] = useState(false);
  const [updateCatPopup, setUpdateCatPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    icon: null,
  });

  const items = [
    {
      key: "1",
      danger: false,
      label: <a onClick={() => navigate(`./${selectedCat?.id}`)}>View subcategories</a>,
    },
    {
      key: "2",
      danger: false,
      label: <a onClick={() => setUpdateCatPopup(true)}>Update {selectedCat?.name}</a>,
    },
    {
      key: "3",
      danger: true,
      label: <a onClick={() => setDeleteConfirm(true)}>Delete {selectedCat?.name}</a>,
    },
  ];

  const columns = [
    {
      title: "Category Icon",
      dataIndex: "image",
      width: 200,
      render: (text) => text?.length ? <Image src={text} width={64} alt="category-icon" /> : 
      <div 
        className="placeholder"
        style={{
          height: 64,
          width: 64,
          borderRadius: 100,
          background: "#E7E7E7"
        }}
      ></div>,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 250,
      render: (text) =>
        text && text?.length ? (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ) : (
          "--"
        ),
    },
    {
      title: "No. of Subcategories",
      dataIndex: "subcategories",
      width: 250,
      align: "center"
    },
    {
      title: "Status",
      dataIndex: "active",
      width: 200,
      align: "center",
      render: (text) => (
        <>
        {
          text === undefined ? null :
          <Tag
            bordered={false}
            style={{
              padding: 4,
              width: "100%",
              textAlign: "center",
              fontSize: 14,
              fontWeight: 500,
              background: text === 1 ? "#DEF2E6" : "#FFEFF0",
              color: text === 1 ? "#11845B" : "#DC2B2B",
              borderRadius: 40,
            }}
          >
            {text === 1 ? "Active" : "Inactive"}
          </Tag>
        }
        </>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, row) => (
        <>
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            trigger={["click"]}
            onClick={() => {
              setSelectedCat(row);
            }}
          >
            <Button type="link">
              <Action />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const getCategoriesHandler = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/categories/*?rows=${rows}&page=${page}&search_str=${searchStr}&subcat_count=1`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res?.status === 1) {
          const object = res.category_details;
          setTotal(object?.total);
          delete object.page;
          delete object.rows;
          delete object.total;
          if(Object.values(object)?.length || page === 1) {
            setData(Object.values(object));
          }
          else (
            setPage(page - 1)
          )
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  };

  const CategoryCUDHAndler = (type) => {
    setUpdating(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    if(type === "create") {
      formdata.append("name", formData?.name);
      if(formData?.icon?.uid) {
        formdata.append("image", formData?.icon, formData?.icon?.name);
      }
    }
    else if(type === "update") {
      formdata.append("_method", "patch");
      formdata.append("name", formData?.name);
      if(formData?.icon?.uid) {
        formdata.append("image", formData?.icon, formData?.icon?.name);
        formdata.append("remove_image", 0)
      }
      else{
        formdata.append("remove_image", 1)
      }
    }
    else {
      formdata.append("_method", "patch");
      formdata.append("active", "0");
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    let url = type === "create" ? `${apiUrl}/categories`  : `${apiUrl}/categories/${selectedCat?.id}`

    fetch(url, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      setUpdating(false);
      if (res?.status === 1) {

        if(type === "update" || type === "create") {
          message.success(res?.message);
          setAddCatPopup(false);
          setUpdateCatPopup(false);
          setFormData({
            name: "",
            icon: null
          })
        }
        else {
          message.success("Category deleted successfully");
          // if((total - 1) <= rows) {
          //   setPage(1);
          // }
        }
        setDeleteConfirm(false);
        setSelectedCat({});
        getCategoriesHandler();
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.email[0]);
        }
    })
    .catch((error) => {
      setUpdating(false);
      console.log("error", error);
      message.error("Something went wrong!");
    });
  }

  useEffect(() => {
    if (searchStr?.length) {
      const getData = setTimeout(() => {
        getCategoriesHandler();
      }, 700);
      return () => clearTimeout(getData);
    } else {
      getCategoriesHandler();
    }
  }, [searchStr, page, rows]);

  useEffect(() => {
    if(selectedCat?.id) {
      setFormData({
        name: selectedCat?.name,
        icon: selectedCat?.image
      })
    }
    else {
      setFormData({
        name: "",
        icon: null
      })
    }
  }, [selectedCat])

  return (
    <div className="case-div client-div categories-div">
      <Card className="card cases-card categories-card">
        <Row gutter={10}>
          <Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <h3 className="page-title">Catogories</h3>
          </Col>
          <Col span={12}>
            <Space wrap style={{float: "right"}}>
              {
                isMobile ? 
                <Tooltip title="Add category">
                        <Button 
                                shape="circle" 
                                icon={<PlusOutlined />} 
                                onClick={() => {
                                  setFormData({
                                    name: "",
                                    icon: null
                                  });
                                  setAddCatPopup(true);
                                }}
                        />
                </Tooltip> :
                <Button
                  type='primary'
                  size='large'
                  onClick={() => {
                    setFormData({
                      name: "",
                      icon: null
                    });
                    setAddCatPopup(true);
                  }}
                >
                  <PlusOutlined />
                  Add new category
                </Button>
              }
            </Space>
          </Col>
        </Row>
        <hr
          style={{
            margin: "28px 0 24px 0",
            border: "none",
            height: 1,
            background: "#E6E6E6",
          }}
        />
        <Row>
          <Col xs={24} lg={18}>
              <Input
                size="large"
                placeholder="Search Category..."
                prefix={<SearchOutlined style={{ color: "#A9AFBA" }} />}
                style={{ width: isMobile ? '100%' : 296 }}
                value={searchStr}
                // onChange={(e) => setSearchStr(e.target.value)}
                onChange={e => {
                  (setSearchStr(e.target.value));
                  setPage(1);
                }}
              />
          </Col>
        </Row>
        {/* table component */}
        <TableContainer
          data={data}
          columns={columns}
          loading={loading}
          total={total}
          page={page}
          setPage={setPage}
          rows={rows}
          setRows={setRows}
          isSelectable={false}
        />
      </Card>
      <Modal
        title={`Delete ${selectedCat?.name}`}
        open={deleteConfirm} 
        onCancel={() => {setSelectedCat({}); setDeleteConfirm(false)}}
        footer={[]} 
        className='case-options-modal delete-confirm-modal'
        width={384}
      >
        <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
        <h3 className='sub-title'>Are you sure?</h3>
          <Button
            type='default'
            size='large'
            className='btn'
            onClick={() => {setSelectedCat({}); setDeleteConfirm(false)}}
            loading={updating}
          >
            No
          </Button>
          <Button
            type='primary'
            size='large'
            className='btn confirm-btn'
            onClick={() => CategoryCUDHAndler("delete")}
            loading={updating}
          >
            Yes, Delete it
          </Button>
      </Modal>
      <Modal
        title="Add new category"
        open={addCatPopup || updateCatPopup} 
        onCancel={() => {
          setAddCatPopup(false);
          setUpdateCatPopup(false);
          setSelectedCat({});
          setFormData({
            name: "",
            icon: null
          });
        }}
        footer={[]}
        className='case-options-modal delete-confirm-modal'
        width={384}
      >
        <Form layout="vertical">
          <Form.Item label="Category Name">
            <Input
              size="large"
              placeholder="Type here..."
              defaultValue={formData?.name}
              value={formData?.name}
              onChange={e => setFormData({...formData, name: e.target.value})}
            />
          </Form.Item>
          <Form.Item label="Category Icon" className='logo-input-div'>
            {
              (updateCatPopup && formData?.icon?.length) ?
              <div style={{textAlign: "left"}}>
                <Image src={formData?.icon} width={64} />
                <Button 
                  type="link" 
                  danger
                  onClick={() => setFormData({...formData, icon: null})}
                >
                  Remove icon
                </Button>
              </div> :
              <>
               <Upload
                  accept=".jpg, .jpeg, .png"
                  customRequest={file => setFormData({...formData, icon: file?.file})}
                  onRemove={() => setFormData({...formData, icon: null})}
                  className="upload"
                >
                  <Button>Choose Image</Button>
                </Upload>
                {
                  formData?.icon?.uid &&
                  <div className="icon-file-info">
                    <p className="name">{formData?.icon?.name?.substring(0, 15)}{formData?.icon?.name?.length > 15 && "..."}</p>
                    <DeleteOutlined
                      style={{color: "red", cursor: "pointer"}}
                      onClick={() => setFormData({...formData, icon: null})}
                    />
                  </div>
                }
              </>
            }
          </Form.Item>
          <div style={{display:"flex", alignItems: "center", justifyContent: "flex-end"}}>
            <Space>
              <Button
                type="default"
                onClick={() => {
                  setAddCatPopup(false);
                  setUpdateCatPopup(false);
                  setSelectedCat({});
                  setFormData({
                    name: "",
                    icon: null
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => addCatPopup ? CategoryCUDHAndler("create") : CategoryCUDHAndler("update")}
                disabled={!formData?.name?.length}
                loading={updating}
              >
                {
                  addCatPopup ? "Add category" : "Update category"
                }
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Categories;
