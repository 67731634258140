import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { Button, Card, Col, Input, Row, Select, Space, Image, Dropdown, Modal, message, Tag, Form } from 'antd';
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import {ReactComponent as Filter} from '../../assets/images/filter.svg';
import {ReactComponent as LinkIcon} from '../../assets/images/link.svg';
import {ReactComponent as Action} from '../../assets/images/action.svg';
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import TableContainer from '../../components/TableContainer';
import moment from 'moment';
import { Link } from 'react-router-dom';

const TestPurchase = () => {

        const {token, logoutHandler, user} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [loading, setLoading] = useState(false);
        const [deleteLoading, setDeleteLoading] = useState(false);
        const [data, setData] = useState([
                {
                        product: {
                                link: ""
                        },
                        client_name: "",
                        date: "",
                        activity: ""
                }
        ]);
        const [searchStr, setSearchStr] = useState("");
        const [page, setPage] = useState(1);
        const [rows, setRows] = useState(10);
        const [selectedRowKeys, setSelectedRowKeys] = useState([]); 
        const [selectedRows, setSelectedRows] = useState([]); console.log(selectedRowKeys);
        const [total, setTotal] = useState(0);
        const [deleteItem, setDeleteItem] = useState({});
        const [deleteConfirm, setDeleteConfirm] = useState(false);
        const [selectedProduct, setSelectedProduct] = useState({}); console.log(selectedProduct)
        const [imageIndex, setImageIndex] = useState(0);
        const [updateProduct, setUpdateProduct] = useState(false);
        const [editLoading, setEditLoading] = useState(false);

        const items = [
                {
                        key: '1',
                        label: (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a onClick={() => {
                                setUpdateProduct(true);
                                }}>
                            Update {deleteItem?.name}
                          </a>
                        ),
                },
                {
                  key: '2',
                  danger: true,
                  label: (
                    <a onClick={() => setDeleteConfirm(true)}>
                      Delete {deleteItem?.name}
                    </a>
                  ),
                }
        ];

        const columns = [
                {
                        title: "Listing",
                        dataIndex: "product",
                        width: 300,
                        render: (text, row) => (
                          <div 
                                style={{display: "flex", alignItems: "center", cursor: "pointer"}} 
                                onClick={() => setSelectedProduct(row)}
                          >
                            {
                              (text && text?.images?.length) ? <Image src={text?.images[0]} alt={text?.title} className='logo-img' /> : <div className='no-logo-div'></div>
                            }
                                <span className='title'>
                                        {/* <Link to={`/cases/${row?.case_id}?category=marketplace&status=${row?.product?.status}&search_str=${row?.product?.title}`}>
                                                {text?.title || "--"}
                                        </Link> */}
                                        {text?.title || "--"}
                                </span>
                          </div>
                        )
                },
                {
                        title: "Client name",
                        dataIndex: "client_name",
                        width: 150,
                        render: (text, row) => (
                          <div style={{display: "flex", alignItems: "center"}}>
                            {
                              (row?.client_logo?.length) ? <Image src={row?.client_logo} alt={text} className='logo-img' /> : <div className='no-logo-div'></div>
                            }
                            <span className='title'>{text}</span>
                          </div>
                        )
                },
                {
                        title: "Initiated on",
                        dataIndex: "date",
                        render: text => <>{text?.length > 0 && moment(text).format("MMM DD, YYYY")}</>
                },
                {
                        title: "Status",
                        dataIndex: "activity",
                        align: "center",
                        render: text => text && <div 
                                            className={
                                                        text?.includes("recieved") ?
                                                        `tag delivered` :
                                                        text?.includes("cancelled") ?
                                                        `tag cancelled` :
                                                        `tag`
                                                }
                                                style={{width: isMobile ? 180 : ""}}
                                        >
                                                {text}
                                        </div>
                },
                {
                        title: "Link",
                        dataIndex: "product",
                        align: "center",
                        render: text => text?.link?.length ? <a href={text?.link} target="_blank" rel="noreferrer"><LinkIcon /></a> : ""
                },
                {
                        title: "Action",
                        align: "center",
                        render: (text, row) => 
                        <>
                        <Dropdown
                          menu={{items,}}
                          placement="bottomRight"
                          trigger={['click']}
                          onClick={() => setDeleteItem(row)}
                        >
                          <Button
                            type='link'
                          >
                            <Action />
                          </Button>
                        </Dropdown>
                        </>
                }
        ]

        const getTestPurchaseHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/list-all-test-purchase/*?rows=${rows}&page=${page}&search_str=${searchStr}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setLoading(false);
                        if(res?.status === 1) {
                        const object = res.test_purchase_details;
                        setTotal(object?.total);
                        delete object.page;
                        delete object.rows;
                        delete object.total;
                        setData(Object?.values(object));
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        setLoading(false);
                        console.log('error', res)
                });
        }

        const deleteTestPurchaseHandler = () => {
                setDeleteLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(
                `${apiUrl}/test-purchase/${deleteItem?.id}`, 
                requestOptions
                )
                .then(response => response.json())
                .then(res => {
                        setDeleteLoading(false);
                        setDeleteItem({});
                        message.success(res?.message);
                        setDeleteConfirm(false);
                        getTestPurchaseHandler();
                })
                .catch(err => {
                        setDeleteLoading(false);
                        console.log('error', err)
                })
        }

        const deleteMultipleTPHandler = () => {
                setDeleteLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(
                `${apiUrl}/test-purchase/${selectedRowKeys}`, 
                requestOptions
                )
                .then(response => response.json())
                .then(res => {
                        setDeleteLoading(false);
                        setSelectedRowKeys({});
                        message.success(res?.message);
                        setDeleteConfirm(false);
                        getTestPurchaseHandler();
                })
                .catch(err => {
                        setDeleteLoading(false);
                        console.log('error', err)
                })
        }

        const editTPHandler = () => {
                setEditLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var formdata = new FormData();
                formdata.append("activity", deleteItem?.activity);
                formdata.append("description", deleteItem?.description);
                formdata.append("insight", deleteItem?.insights);
                formdata.append("_method", "patch");
            
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: formdata,
                  redirect: 'follow'
                };
            
                fetch(`${apiUrl}/test-purchase/${deleteItem?.id}`, requestOptions)
                  .then(response => response.json())
                  .then((res) => {
                    setEditLoading(false);
                    if (res?.status === 1) {
                      message.success(res?.message);
                      getTestPurchaseHandler();
                      setUpdateProduct(false)
                    }
                    else if(res?.api_status ==='401') {
                      logoutHandler("401");
                      }
                      else {
                        message.success(res?.message);
                      }
                  })
                  .catch((error) => {
                    setEditLoading(false);
                    console.log("error", error);
                    message.error("Something went wrong!");
                  });
              }

        useEffect(() => {
                if(searchStr?.length) {
                setPage(1);
                const getData = setTimeout(() => {
                        getTestPurchaseHandler();
                        }, 700);
                
                        return () => clearTimeout(getData);
                }
                else {
                getTestPurchaseHandler();
                }
        }, [page, rows, searchStr])

  return (
    <div className="cases-div test-purchase-div">
        <Card className='card cases-card test-purchase-card'>
                <Row gutter={10}>
                        <Col xs={24} lg={12} style={{display: 'flex', alignItems: 'center'}}>
                        <h3 className='page-title'>Test purchase</h3>
                        </Col>
                </Row>
                <hr style={{margin: '28px 0 24px 0', border: 'none', height: 1, background: '#E6E6E6'}} />
                <Row>
                        <Col xs={24} lg={24} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Input
                                        size='large'
                                        placeholder='Search test purchase'
                                        prefix={<SearchOutlined style={{color: "#A9AFBA"}} />}
                                        style={{width: isMobile ? "100%" : 296}}
                                        value={searchStr}
                                        onChange={e => setSearchStr(e.target.value)}
                                        disabled={!data?.length && !searchStr?.length}
                                />
                                {
                                        selectedRowKeys?.length ?
                                        <Button
                                                size='large'
                                                danger
                                                onClick={() => setDeleteConfirm(true)}
                                                loading={deleteLoading}
                                        >
                                                Delete selected test purchase
                                        </Button> : null
                                }
                        </Col>
                </Row>
                <TableContainer
                        data={data} 
                        columns={columns} 
                        loading={loading}
                        total={total}
                        page={page}
                        setPage={setPage}
                        rows={rows}
                        setRows={setRows}
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                />
        </Card>

        <Modal
          title={`Delete test purchase`}
          open={deleteConfirm} 
          onCancel={() => {setDeleteItem({}); setDeleteConfirm(false)}}
          footer={[]} 
          className='case-options-modal delete-confirm-modal'
          width={384}
        >
          <DeleteOutlined style={{fontSize: 50}} className="delete-icon" />
          <h3 className='sub-title'>Are you sure?</h3>
            <Button
              type='default'
              size='large'
              className='btn'
              onClick={() => {setDeleteItem({}); setDeleteConfirm(false)}}
              loading={deleteLoading}
            >
              No
            </Button>
            <Button
              type='primary'
              size='large'
              className='btn confirm-btn'
              onClick={() => (selectedRowKeys?.length) ? deleteMultipleTPHandler() : deleteTestPurchaseHandler()}
              loading={deleteLoading}
            >
              Yes, Delete it
            </Button>
        </Modal>

        <Modal
                title={`Product details`}
                open={selectedProduct?.id} 
                onCancel={() => {setSelectedProduct({})}}
                footer={[]} 
                className='tp-details-modal listing-details-modal'
                width={800}
        >
                <Row gutter={16} style={{marginTop: 20}}>
                        <Col lg={13} sm={24}>
                                <Image
                                        src={
                                        selectedProduct?.product?.images?.length > 0 &&
                                        selectedProduct?.product?.images[imageIndex]
                                        }
                                        // width={isMobile ? "100%" : 552}
                                        // height={isMobile ? "100%" : 450}
                                />
                                <div className="preview-grid-div">
                                        {selectedProduct?.product?.images?.length > 0 &&
                                        selectedProduct?.product?.images?.map((src, i) => (
                                        <img
                                                src={src}
                                                alt="src"
                                                className={
                                                imageIndex === i
                                                ? "preview-grid-item preview-grid-item-active"
                                                : "preview-grid-item"
                                                }
                                                key={i}
                                                onClick={() => setImageIndex(i)}
                                        />
                                        ))}
                                </div>
                        </Col>
                        <Col lg={11} sm={24}>
                                <div className={`status-div ${selectedProduct?.latest_status}`}>
                                        <Tag className={`${selectedProduct?.latest_status}`}>
                                        {selectedProduct?.latest_status}
                                        </Tag>
                                        <p className="date">
                                        {moment(selectedProduct?.updated_at).format("Do MMM YYYY")}
                                        </p>
                                </div>
                                <p className="title">{selectedProduct?.product?.title}</p>
                                <p className="desc">
                                        {
                                        selectedProduct?.description
                                        }
                                </p>
                                <hr />
                                <p className="line">
                                        Case name: <span>{selectedProduct?.case_name}</span>
                                </p>
                                <hr />
                                <p className="line">
                                        Client name: <span>{selectedProduct?.client_name}</span>
                                </p>
                                <hr />
                                <Link
                                        to={`/cases/${selectedProduct?.product?.case_id}?category=marketplace&status=${selectedProduct?.latest_product_status}&search_str=${selectedProduct?.product?.uid}`}
                                        className="link"
                                >
                                        view product
                                </Link>
                        </Col>
                </Row>
        </Modal>
        <Modal
                title={`Update test purchase record`}
                open={updateProduct} 
                onCancel={() => {setDeleteItem({}); setUpdateProduct(false)}}
                footer={[]} 
                className='update-tp-modal'
                width={500}
        >
                <Form
                        layout='vertical'
                >
                <Form.Item
                        label="Activity"
                >
                        <Select
                                placeholder="Select activity"
                                size="large"
                                options={[
                                {
                                value: "initiated test purchase",
                                label: "Initiated test purchase",
                                },
                                {
                                value: "order placed",
                                label: "Order placed",
                                },
                                {
                                value: "product received",
                                label: "Product received",
                                },
                                {
                                value: "product reviewed",
                                label: "Product reviewed",
                                },
                                {
                                value: "product not delivered",
                                label: "Product not delivered",
                                },
                                {
                                value: "test purchased cancelled",
                                label: "Test purchased cancelled",
                                }
                                ]}
                                defaultValue={deleteItem?.activity}
                                value={deleteItem?.activity}
                                onChange={(e) => setDeleteItem({...deleteItem, activity: e})}
                        />
                </Form.Item>
                <Form.Item
                        label="Description"
                >
                        <Input.TextArea
                                rows={4}
                                placeholder="Type here...."
                                maxLength={250}
                                size="large"
                                defaultValue={deleteItem?.description}
                                value={deleteItem?.description}
                                onChange={(e) => setDeleteItem({...deleteItem, description: e.target.value})}
                        />
                </Form.Item>
                <Form.Item
                        label="Insights"
                >
                        <Input.TextArea
                                rows={4}
                                placeholder="Type here...."
                                maxLength={250}
                                size="large"
                                defaultValue={deleteItem?.insights}
                                value={deleteItem?.insights}
                                onChange={(e) => setDeleteItem({...deleteItem, insights: e.target.value})}
                        />
                </Form.Item>
                <Form.Item>
                        <Space>
                                <Button onClick={() => {setDeleteItem({}); setUpdateProduct(false)}} disabled={editLoading}>Cancel</Button>
                                <Button type="primary" onClick={() => editTPHandler()} loading={editLoading}>
                                        Update
                                </Button>
                        </Space>
                </Form.Item>
                </Form>
        </Modal>
    </div>
  )
}

export default TestPurchase