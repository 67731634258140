import { Button, Card, Form, Image, Input, Space, Upload } from 'antd'
import React from 'react'
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const Info = ({
        data={},
        formData = {},
        setFormData = () => {},
        isEdit = false
}) => {

        const isMobile = window.innerWidth < 767;

        console.log(formData?.id)

  return (
    <div className='form-div client-info-div' style={{padding: 0}}>
        <Card className='card form-card'>
                <h3 className="form-card-title">Client Information</h3>
                {
                        formData?.id ?
                        <Form
                                className='form'
                                layout='vertical'
                                autoComplete="on"
                        >
                                <Form.Item
                                        label="Client Name"
                                        name="clientName"
                                >
                                        <Input 
                                                placeholder='ex: Client name....' 
                                                size='large' 
                                                defaultValue={formData?.name}
                                                value={formData?.name}
                                                onChange={e => setFormData({...formData, name: e.target.value})}
                                                readOnly={!isEdit}
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="Legal Name"
                                        name="legalName"
                                >
                                        <Input 
                                                placeholder='ex: Client legal name....' 
                                                size='large' 
                                                defaultValue={formData?.legal_name}
                                                value={formData?.legal_name}
                                                onChange={e => setFormData({...formData, legal_name: e.target.value})}
                                                readOnly={!isEdit}
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="Logo"
                                        name="logo"
                                        className='logo-input-div'
                                >
                                        {
                                                formData?.logo?.length ?
                                                <>
                                                        <Image src={formData?.logo} alt={formData?.name} width={60} />
                                                        {
                                                                isEdit &&
                                                                <Button 
                                                                        type='link' 
                                                                        danger
                                                                        onClick={() => setFormData({...formData, logo: ""})}
                                                                >
                                                                        Remove
                                                                </Button>
                                                        }
                                                </> :
                                                <Upload
                                                        customRequest={file => setFormData({...formData, logo: file?.file})}
                                                        onRemove={() => setFormData({...formData, logo: null})}
                                                        disabled={!isEdit}
                                                >
                                                        <Button>Choose Image</Button>
                                                </Upload>
                                        }
                                </Form.Item>
                                {
                                        formData?.websites?.map((site, i) => (
                                                <Form.Item
                                                        key={i}
                                                        label="website"
                                                        name={`website_${i+1}`}
                                                >
                                                        <Input 
                                                                placeholder='ex: google.com' 
                                                                size='large' 
                                                                prefix = {<>https</>}
                                                                suffix = {
                                                                        (i > 0 && isEdit) &&
                                                                        <Button 
                                                                                type='link' 
                                                                                danger 
                                                                                onClick={() => {
                                                                                        const id = formData.websites.indexOf(site);
                                                                                        const newArr = formData.websites.filter((item, i) => i !== id)
                                                                                        setFormData({...formData, websites: newArr })
                                                                                }} 
                                                                                className='remove-btn'
                                                                        > 
                                                                                <MinusCircleOutlined /> 
                                                                        </Button>
                                                                }
                                                                onKeyPress={(event) => {
                                                                        if (/^\s+$/.test(event.key)) {
                                                                        event.preventDefault();
                                                                        }
                                                                }}
                                                                defaultValue={site}
                                                                value={site}
                                                                onChange={e => {
                                                                        const newArr = [...formData?.websites]
                                                                        newArr[i] = e.target.value
                                                                        setFormData({...formData, websites: newArr })
                                                                }}
                                                                defaultChecked
                                                                readOnly={!isEdit}
                                                        />
                                                </Form.Item>
                                        ))
                                }
                                {
                                        isEdit &&
                                        <Button 
                                                onClick={() => {
                                                        setFormData({
                                                                ...formData,
                                                                websites: [...formData.websites, ""]
                                                        })
                                                }} 
                                                className='add-btn'
                                        >
                                                <PlusOutlined /> Add website
                                        </Button>
                                }
                                {
                                        formData?.emails?.map((email, i) => (
                                                <Form.Item
                                                        key={i}
                                                        label="email"
                                                        name={`email_${i+1}`}
                                                        rules={[
                                                                {
                                                                        type: 'email'
                                                                },
                                                        ]}
                                                >
                                                        <Input 
                                                                placeholder='ex: abc@mail.com' 
                                                                size='large' 
                                                                style={i > 0 && isEdit ? {paddingLeft: 12} : {}}
                                                                suffix = {
                                                                        (i > 0 && isEdit) &&
                                                                        <Button 
                                                                                type='link' 
                                                                                danger 
                                                                                onClick={() => {
                                                                                        const id = formData.emails.indexOf(email);
                                                                                        const newArr = formData.emails.filter((item, i) => i !== id)
                                                                                        setFormData({...formData, emails: newArr })
                                                                                }} 
                                                                                className='remove-btn'
                                                                        > 
                                                                                <MinusCircleOutlined /> 
                                                                        </Button>
                                                                }
                                                                onKeyPress={(event) => {
                                                                        if (/^\s+$/.test(event.key)) {
                                                                                event.preventDefault();
                                                                        }
                                                                }}
                                                                defaultValue={email}
                                                                value={email}
                                                                onChange={e => {
                                                                        const newArr = [...formData?.emails]
                                                                        newArr[i] = e.target.value
                                                                        setFormData({...formData, emails: newArr })
                                                                }}
                                                                readOnly={!isEdit}
                                                        />
                                                </Form.Item>
                                        ))
                                }
                                {
                                        isEdit &&
                                        <Button 
                                                onClick={() => {
                                                        setFormData({
                                                                ...formData,
                                                                emails: [...formData.emails, ""]
                                                        })
                                                }} 
                                                className='add-btn'
                                        >
                                                <PlusOutlined /> Add email
                                        </Button>
                                }
                                {
                                        formData?.contacts?.map((contact, i) => (
                                                <Form.Item
                                                        key={i}
                                                        label="Phone Number (Optional)"
                                                        name={`phone_${i+1}`}
                                                        rules={[
                                                                {
                                                                        type: 'tel',
                                                                },
                                                                {
                                                                        min: 10,
                                                                        message: "Phone number should be minumum 10 digits",
                                                                },
                                                                {
                                                                        max: 12,
                                                                        message: "Phone number should be maximum 12 digits",
                                                                },
                                                        ]}
                                                >
                                                        <Input 
                                                                placeholder='ex: 9xxxxxxx1' 
                                                                size='large'
                                                                suffix = {
                                                                        (i > 0 && isEdit) &&
                                                                        <Button 
                                                                                type='link' 
                                                                                danger 
                                                                                onClick={() => {
                                                                                        const id = formData.contacts.indexOf(contact);
                                                                                        const newArr = formData.contacts.filter((item, i) => i !== id)
                                                                                        setFormData({...formData, contacts: newArr })
                                                                                }} 
                                                                                className='remove-btn'
                                                                        > 
                                                                                <MinusCircleOutlined /> 
                                                                        </Button>
                                                                }
                                                                onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                        }
                                                                }}
                                                                defaultValue={contact}
                                                                value={contact}
                                                                onChange={e => {
                                                                        const newArr = [...formData?.contacts]
                                                                        newArr[i] = e.target.value
                                                                        setFormData({...formData, contacts: newArr })
                                                                }}
                                                                readOnly={!isEdit}
                                                        />
                                                </Form.Item>
                                        ))
                                }
                                {
                                        isEdit &&
                                        <Button 
                                                onClick={() => {
                                                        setFormData({
                                                                ...formData,
                                                                contacts: [...formData.contacts, ""]
                                                        })
                                                }} 
                                                className='add-btn'
                                        >
                                                <PlusOutlined /> Add phone number
                                        </Button>
                                }
                        </Form> : null
                }
        </Card>
        <Card className='card form-card'>
                <h3 className="form-card-title">Address</h3>
                <Form
                        className='form'
                        layout='vertical'
                        autoComplete="off"
                >
                        <Form.Item
                                label="Street Address"
                                name="street_address"
                        >
                                <Input 
                                        placeholder='Address line 1' 
                                        size='large' 
                                        defaultValue={formData?.address?.line}
                                        onChange={e => setFormData({...formData, address: {...formData?.address, line: e.target.value}}) }
                                        readOnly={!isEdit}
                                />
                        </Form.Item>
                        <Space size={16} className='address-space' direction={isMobile ? "vertical" : "horizontal"}>
                                <Form.Item
                                        label="City"
                                        name="city"
                                >
                                        <Input 
                                                placeholder='Type here...' 
                                                size='large' 
                                                defaultValue={formData?.address?.city}
                                                onChange={e => setFormData({...formData, address: {...formData?.address, city: e.target.value}}) }
                                                readOnly={!isEdit}
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="State"
                                        name="state"
                                >
                                        <Input 
                                                placeholder='Type here...' 
                                                size='large' 
                                                defaultValue={formData?.address?.state}
                                                onChange={e => setFormData({...formData, address: {...formData?.address, state: e.target.value}}) }
                                                readOnly={!isEdit}
                                        />
                                </Form.Item>
                                <Form.Item
                                        label="Zip"
                                        name="zip"
                                >
                                        <Input 
                                                placeholder='Type here...' 
                                                size='large' 
                                                onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                }}
                                                defaultValue={formData?.address?.zipcode}
                                                onChange={e => setFormData({...formData, address: {...formData?.address, zipcode: e.target.value}}) }
                                                readOnly={!isEdit}
                                        />
                                </Form.Item>
                        </Space>
                </Form>
        </Card>
    </div>
  )
}

export default Info