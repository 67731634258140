import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { useNavigate, useParams } from 'react-router';
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import { Button, Card, Col, Row, Space, Spin, Tabs, Tooltip, message } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import {ReactComponent as BackBtn} from '../../assets/images/back-icon-btn.svg';
import TabPane from 'antd/es/tabs/TabPane';
import Info from './Info';
import Rules from './Rules';
import Documents from './Documents';
import Categories from './Categories';

const CaseDetails = () => {

        const navigate = useNavigate();
        const { id } = useParams();
        const { token, user, logoutHandler, setTotalRegion, countryList, setCountryList } = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [tab, setTab] = useState("case_info");

        const [loading, setLoading] = useState(false);
        const [updating, setUpdating] = useState(false);
        const [data, setData] = useState({});
        const [formData, setFormData] = useState({});
        const [isEdit, setIsEdit] = useState(false);
        const [ipDocs, setIpDocs] = useState(null);
        const [infringingImages, setInfringingImages] = useState(null);
        const [whitelistedSellers, setWhitelistedSellers] = useState(null);

        const [categories, setCategories] = useState([]);
        const [checkAll, setCheckAll] = useState(false);
        const [searchEngine, setSearchEngine] = useState([])
        const [searchEngineChecked, setSearchEngineChecked] = useState([]);
        const [selectedClient, setSelectedClient] = useState({});
        const [countryCodes, setCountryCodes] = useState([]);

        const getCaseDetailsHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/case/${id}`, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                        setLoading(false);
                        if (res?.status === 1) {
                                setData(res?.case_details);
                                setFormData(res?.case_details);
                                getClientDetailsHandler(res?.case_details?.client_id);
                                let newArr = res?.case_details?.countries?.map((country, i) => (
                                        {
                                                disabled : undefined,
                                                key: country?.id,
                                                label: country?.name,
                                                title: undefined,
                                                value: country?.id
                                        }
                                ))
                                setCountryList(newArr)
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch((error) => {
                        setLoading(false);
                        console.log("error", error);
                        message.error("Something went wrong!");
                });
        }

        const getClientDetailsHandler = (clientId) => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("domains", "[]");
                myHeaders.append("Authorization", `Bearer ${token}`);
            
                var requestOptions = {
                  method: "GET",
                  headers: myHeaders,
                  redirect: "follow",
                };

                fetch(`${apiUrl}/client/${clientId}`, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                        setLoading(false);
                        if (res?.status === 1) {
                                setSelectedClient(res?.client_details);
                                setTotalRegion(res?.client_details?.totalRegion)
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch((error) => {
                        setLoading(false);
                        console.log("error", error);
                        message.error("Something went wrong!");
                });
        } 

        const updateCaseHandler = () => {
                setUpdating(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var formdata = new FormData();
                formdata.append("_method", "patch");

                formdata.append("name", formData?.name);
                formdata.append("keyword", JSON.stringify(formData?.keyword));
                formdata.append("ecom", JSON.stringify(formData?.ecom));
                formdata.append("search_engine", JSON.stringify(formData?.search_engine));
                formdata.append("social_media", JSON.stringify(formData?.social_media));
                formdata.append("domains", JSON.stringify(formData?.domains));
                if(ipDocs?.uid) {
                        formdata.append("ipdocs[]", ipDocs, ipDocs?.name);
                }
                if(infringingImages?.uid) {
                        formdata.append("infringing_images[]", infringingImages, infringingImages?.name);
                }
                if(whitelistedSellers?.uid) {
                        formdata.append("whitelisted_sellers[]", whitelistedSellers, whitelistedSellers?.name);
                }
                formdata.append("rules", JSON.stringify(formData?.rules));

                // countryList?.map(country => formdata?.append("countries[]", JSON.stringify({name: country?.label, id: country?.value, subcategories: countryCodes })))
                countryList?.map(
                        country => 
                                formdata?.append(
                                        "countries[]", 
                                        JSON.stringify(
                                                {
                                                        name: country?.label, 
                                                        id: country?.value, 
                                                        subcategories: countryCodes?.filter(item => item?.country_id === country?.value)?.map(it => it?.id)
                                                }
                                )
                        )
                )

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/case/${id}`, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                        setUpdating(false);
                        if (res?.status === 1) {
                                message.success(res?.message);
                                setIsEdit(false);
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch((error) => {
                        setUpdating(false);
                        console.log("error", error);
                        message.error("Something went wrong!");
                });
        }

        useEffect(() => {
                if(!isEdit) {
                        getCaseDetailsHandler()
                }
        }, [id, isEdit]);

        useEffect(() => {
                setFormData({...formData, search_engine: searchEngine})
        }, [searchEngine])

  return (
        <div className='cases-div add-new-case-div add-new-client-div client-info-div case-info-div'>
                <Card className='card add-new-case-card'>
                        <Row gutter={10}>
                                <Col xs={24} lg={12} style={{display: 'flex', alignItems: 'center', }}>
                                        <BackBtn style={{marginRight: 12, cursor: "pointer"}} onClick={() => navigate(-1)} />
                                        <h3 className='page-title'>{data?.name || "--"}</h3>
                                </Col>
                                {
                                        (user?.role_id === 2 || user?.role_id === 3 || user?.role_id === 4) ?
                                        <></> :
                                        <Col xs={24} lg={12}>
                                                <Space wrap style={{float: "right"}}>
                                                        {
                                                        isEdit &&
                                                        <>
                                                        {
                                                                isMobile ?
                                                                <>
                                                                <Tooltip title="Cancel">
                                                                        <Button 
                                                                                shape="circle" 
                                                                                icon={ <CloseOutlined />} 
                                                                                onClick={() => {
                                                                                        setIsEdit(false);
                                                                                }}
                                                                        />
                                                                        </Tooltip>
                                                                </> :
                                                                <>
                                                                 <Button
                                                                        size='large'
                                                                        style={{width: 88}}
                                                                        onClick={() => {
                                                                                setIsEdit(false);
                                                                        }}
                                                                >
                                                                        Cancel
                                                                </Button>
                                                                </>
                                                        }
                                                        </>
                                                        }
                                                        {
                                                                isMobile ?
                                                                <>
                                                                <Tooltip title={isEdit ? "Save" : "Edit"}>
                                                                        <Button 
                                                                        shape="circle" 
                                                                        icon={ isEdit ? <SaveOutlined /> : <EditOutlined />} 
                                                                        loading={isEdit ? updating : loading}
                                                                        onClick={() => {
                                                                                if(isEdit) {
                                                                                        updateCaseHandler()
                                                                                }
                                                                                else {
                                                                                        setIsEdit(true);
                                                                                }
                                                                        }}
                                                                        />
                                                                </Tooltip>
                                                                </> : 
                                                                <>
                                                                <Button
                                                                        type='primary'
                                                                        size='large'
                                                                        style={{width: 88}}
                                                                        onClick={() => {
                                                                                if(isEdit) {
                                                                                        updateCaseHandler()
                                                                                }
                                                                                else {
                                                                                        setIsEdit(true);
                                                                                }
                                                                        }}
                                                                        loading={isEdit ? updating : loading}
                                                                >
                                                                {
                                                                isEdit ? "Update" : "Edit"
                                                                }
                                                                </Button>
                                                                </>
                                                        }
                                                </Space>
                                        </Col>
                                }
                                <Col span={24}>
                                        <Tabs defaultActiveKey={tab} activeKey={tab} onChange={e => {setTab(e)}}>
                                                <TabPane tab="Case info" key="case_info" />
                                                <TabPane tab="Documents" key="documents" />
                                                <TabPane tab="Categories" key="categories" />
                                                <TabPane tab="Rules" key="rules" />
                                        </Tabs>
                                </Col>
                        </Row>
                </Card>
                <div className="content-body">
                        {
                                loading ?
                                        <div className="loading-div" style={{height: "70vh", display: "flex", alignItems: "center", justifyContent: "center"}}><Spin size='large' /></div> :
                                (tab === "case_info") ?
                                        <Info 
                                                data={data} 
                                                formData={formData} 
                                                setFormData={setFormData} 
                                                isEdit={isEdit} 
                                        /> :
                                (tab === "documents") ?
                                        <Documents 
                                                data={data} 
                                                formData={formData} 
                                                setFormData={setFormData} 
                                                isEdit={isEdit}
                                                ipDocs={ipDocs}
                                                setIpDocs={setIpDocs}
                                                infringingImages={infringingImages}
                                                setInfringingImages={setInfringingImages}
                                                whitelistedSellers={whitelistedSellers}
                                                setWhitelistedSellers={setWhitelistedSellers}
                                        /> :
                                (tab === "categories") ?
                                        <Categories 
                                                data={data} 
                                                formData={formData} 
                                                setFormData={setFormData} 
                                                isEdit={isEdit}
                                                checkedList={categories} 
                                                setCheckedList={setCategories} 
                                                checkAll={checkAll} 
                                                setCheckAll={setCheckAll} 
                                                searchEngine={searchEngine} 
                                                setSearchEngine={setSearchEngine} 
                                                searchEngineChecked={searchEngineChecked} 
                                                setSearchEngineChecked={setSearchEngineChecked} 
                                                countryCodes={countryCodes}
                                                setCountryCodes={setCountryCodes}
                                                selectedClient={selectedClient}
                                        /> :
                                (tab === "rules") ?
                                        <Rules 
                                                data={data} 
                                                formData={formData} 
                                                setFormData={setFormData} 
                                                isEdit={isEdit} 
                                        /> :
                                        null
                        }
                </div>
        </div>
  )
}

export default CaseDetails