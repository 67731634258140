import React, { useContext, useState } from 'react';
import './index.css';
import { Button, Card, Col, Form, Input, Row, Space, message } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { ReactComponent as BackBtn } from "../../assets/images/back-icon-btn.svg";
import { useNavigate } from 'react-router';
import { MainContext } from '../../utils/context';
import NoImage from "../../assets/images/profile-img.png";
import { apiUrl } from '../../utils/constants';

const Profile = () => {

        const navigate = useNavigate();

        const { user, setUser, token, logoutHandler } = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [loading, setLoading] = useState(false);
        const [formData, setFormData] = useState({
                name: user?.name,
                email: user?.email,
                contact_no: user?.contact_no,
                password: "",
                confirm_password: ""

        })
        const [isEdit, setIsEdit] = useState(false);

        const updateHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);

                var formdata = new FormData();
                formdata.append("_method", "patch");
                formdata.append("name", formData?.name);
                formdata.append("contact_no", formData?.contact_no);
                if(formData?.password?.length) {
                        formdata.append("password", formData?.password);
                        formdata.append("password_confirmation", formData?.confirm_password);
                }

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/auth/update-user/${user?.id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                setLoading(false);
                if(res?.status === 1) {
                        message.success(res?.message);
                        setIsEdit(false);
                        setUser(res?.user);
                        setFormData({...formData, password: "", confirm_password: ""})
                }
                else if(res?.api_status ==='401') {
                        logoutHandler("401");
                        }
                        else {
                                message.error(res?.email[0]);
                        }
                })
                .catch(res => {
                        setLoading(false);
                        console.log('error', res)
                });
        }

  return (
    <div className='cases-div profile-div'>
        <Card className='card cases-card profile-card'>
                <Row gutter={10}>
                        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                        <BackBtn
                                style={{ marginRight: 12, cursor: "pointer" }}
                                onClick={() => navigate(-1)}
                        />
                                <h3 className='page-title'>Profile</h3>
                        </Col>
                        <Col span={12} style={{display: "flex", justifyContent: "flex-end"}}>
                                <Space size="middle">
                                        {
                                                isEdit ?
                                                <>
                                                <Button
                                                        type='default'
                                                        size= {isMobile ? "default" : 'large'}
                                                        onClick={() => {
                                                                setIsEdit(false);
                                                                setFormData({
                                                                        name: user?.name,
                                                                        email: user?.email,
                                                                        contact_no: user?.contact_no,
                                                                        password: "",
                                                                        setPassword: ""
                                                                });
                                                        }}
                                                        disabled={loading}
                                                >
                                                        {
                                                                isMobile ?
                                                                <CloseOutlined /> :
                                                                "Cancel"
                                                        }
                                                </Button>
                                                <Button
                                                        type='primary'
                                                        size= {isMobile ? "default" : 'large'}
                                                        onClick={() => updateHandler()}
                                                        loading={loading}
                                                        disabled={(formData?.password?.length && (formData?.password !== formData?.confirm_password))}
                                                >
                                                        {
                                                                isMobile ?
                                                                <SaveOutlined /> :
                                                                "Save"
                                                        }
                                                </Button>
                                                </> :
                                                <Button
                                                        type='primary'
                                                        size= {isMobile ? "default" : 'large'}
                                                        onClick={() => setIsEdit(true)}
                                                >
                                                        {
                                                                isMobile ? 
                                                                <EditOutlined /> :
                                                                "Edit profile"
                                                        }
                                                </Button>
                                        }
                                </Space>
                        </Col>
                </Row>
                <hr
                        style={{
                        margin: '28px 0 24px 0',
                        border: 'none',
                        height: 1,
                        background: '#E6E6E6',
                        }}
                />
                <div className="main-section" style={{width: isMobile ? "100%" : 700, marginRight: "auto", marginLeft: "auto", padding: isMobile ? 0 : "50px 0"}}>
                        <Row gutter={24}>
                                <Col xs={24} lg={6}>
                                        {/* <img
                                                src={user?.avatar}
                                                alt={user?.name}
                                                className="listing-img-table"
                                                onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = NoImage;
                                                }}
                                                style={{
                                                        width: "90%",
                                                        marginTop: 24
                                                }}
                                        /> */}
                                        <div className='avatar-div' style={isMobile ? {marginBottom: 24} : {}}>
                                                {user?.name?.split(' ').map(word => word.charAt(0)).join('')}
                                        </div>
                                </Col>
                                <Col xs={24} lg={18}>
                                        <Form layout='vertical'>
                                                <Row gutter={24}>
                                                        <Col xs={24} lg={24}>
                                                                <Form.Item label="Name">
                                                                        <Input
                                                                                size='large'
                                                                                placeholder='Enter your name here...'
                                                                                defaultValue={formData?.name}
                                                                                value={formData?.name}
                                                                                onChange={e => setFormData({...formData, name: e.target.value})}
                                                                                readOnly={!isEdit}
                                                                        />
                                                                </Form.Item>
                                                        </Col>
                                                        <Col xs={24} lg={12}>
                                                                <Form.Item label="Email">
                                                                        <Input
                                                                                size='large'
                                                                                placeholder='Enter your email here...'
                                                                                defaultValue={formData?.email}
                                                                                value={formData?.email}
                                                                                onChange={e => setFormData({...formData, email: e.target.value})}
                                                                                readOnly
                                                                        />
                                                                </Form.Item>
                                                        </Col>
                                                        <Col xs={24} lg={12}>
                                                                <Form.Item 
                                                                        label="Contact no"
                                                                        rules={[
                                                                                {
                                                                                        type: 'tel',
                                                                                },
                                                                                {
                                                                                        min: 10,
                                                                                        message: "Phone number should be minumum 10 digits",
                                                                                },
                                                                                {
                                                                                        max: 12,
                                                                                        message: "Phone number should be maximum 12 digits",
                                                                                },
                                                                        ]}
                                                                >
                                                                        <Input
                                                                                size='large'
                                                                                placeholder='Enter your contact no. here...'
                                                                                onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                        }
                                                                                }}
                                                                                defaultValue={formData?.contact_no}
                                                                                value={formData?.contact_no}
                                                                                onChange={e => setFormData({...formData, contact_no: e.target.value})}
                                                                                readOnly={!isEdit}
                                                                        />
                                                                </Form.Item>
                                                        </Col>
                                                        {
                                                                isEdit &&
                                                                <>
                                                                <Col xs={24} lg={12}>
                                                                        <Form.Item label="New Password">
                                                                                <Input
                                                                                        size='large'
                                                                                        placeholder='Enter your new password here...'
                                                                                        defaultValue={formData?.password}
                                                                                        value={formData?.password}
                                                                                        onChange={e => setFormData({...formData, password: e.target.value})}
                                                                                        readOnly={!isEdit}
                                                                                />
                                                                        </Form.Item>
                                                                </Col>
                                                                <Col xs={24} lg={12}>
                                                                        <Form.Item label="Confirm New Password">
                                                                                <Input
                                                                                        size='large'
                                                                                        placeholder='Confirm your new password here...'
                                                                                        defaultValue={formData?.confirm_password}
                                                                                        value={formData?.confirm_password}
                                                                                        onChange={e => setFormData({...formData, confirm_password: e.target.value})}
                                                                                        readOnly={!isEdit}
                                                                                />
                                                                        </Form.Item>
                                                                </Col>
                                                                </>
                                                        }
                                                </Row>
                                        </Form>
                                </Col>
                        </Row>
                </div>
        </Card>
    </div>
  )
}

export default Profile