import React, {useState, useContext} from 'react'
import { Button, Col, Form, Input, Row, message } from 'antd'
import "./index.css";
import { ReactComponent as LOGO } from '../../assets/images/logo-white.svg';
import img from '../../assets/images/login-img.gif';
import { apiUrl } from '../../utils/constants';
import axios from 'axios';
import { MainContext } from '../../utils/context';
import { useNavigate } from 'react-router';

const LoginPage = () => {

        const navigate = useNavigate();

        const {setUser, setToken, setAllStatus} = useContext(MainContext);

        const [loading, setLoading] = useState(false);

        const validateMessages = {
                required: '${label} is required!',
                types: {
                  email: '${label} is not a valid email!',
                  number: '${label} is not a valid number!',
                },
                number: {
                  range: '${label} must be between ${min} and ${max}',
                },
        };

        const loginHandler = (values) => {
                setLoading(true);
                var formdata = new FormData();
                formdata.append("email", values?.email);
                formdata.append("password", values?.password);

                axios.post(`${apiUrl}/auth/login`, formdata)
                .then(res => {
                        if(res?.status === 200) {
                                message.success("Logged in successfully!");
        
                                if(res?.data?.user?.role_id === 6) {
                                        navigate("/clients");
                                }
                                if(res?.data?.user?.role_id === 3) {
                                        var myHeaders = new Headers();
                                        myHeaders.append("Authorization", `Bearer ${res?.data?.access_token}`);
                                
                                        var requestOptions = {
                                        method: "GET",
                                        headers: myHeaders,
                                        redirect: "follow",
                                        };

                                        fetch(`${apiUrl}/client/${res?.data?.user?.company_id}`, requestOptions)
                                        .then((response) => response.json())
                                        .then((res2) => {
                                                setLoading(false)
                                                setUser({...res?.data?.user, company_details: res2?.client_details});
                                                setToken(res.data.access_token);
                                                setAllStatus(res?.data?.status);
                                        })
                                        .catch((error) => {
                                                setLoading(false)
                                                console.log("error", error);
                                                message.error("Something went wrong!");
                                        });
                                }
                                else if(res?.data?.user?.role_id === 5) {
                                        setLoading(false)
                                        setUser({...res?.data?.user, client_details: res?.data?.client_details});
                                        setToken(res.data.access_token);
                                        setAllStatus(res?.data?.status);
                                }
                                else {
                                        setLoading(false)
                                        setUser(res.data.user);
                                        setToken(res.data.access_token);
                                        setAllStatus(res?.data?.status);
                                }
                        }
                        else {
                                setLoading(false)
                                message.error("Incorrect email or password!");
                        }
                })
                .catch(err => {
                        setLoading(false);
                        if(err?.response?.status === 401) {
                                message.error(err?.response?.statusText);
                        }
                        else {
                                message.error("Something Went wrong!");
                        }
                        console.log(err?.response);
                });
        }

  return (
        <div className="login-page">
                <LOGO className='logo' />
                <div className="base-div">
                        <Row className='row'>
                                <Col span={24} className='form-col'>
                                        <h2 className="title">Log In</h2>
                                        <Form
                                                className='form'
                                                layout='vertical'
                                                validateMessages={validateMessages}
                                                onFinish={(values) => loginHandler(values)}
                                                autoComplete="off"
                                        >
                                                <Form.Item
                                                        label="Email Address"
                                                        name="email"
                                                        rules={[
                                                                {
                                                                  type: 'email',
                                                                  required: true
                                                                },
                                                        ]}
                                                >
                                                        <Input placeholder='ex: abc@mail.com' size='large' />
                                                </Form.Item>
                                                <Form.Item
                                                        name="password"
                                                        label="Password"
                                                        rules={[
                                                        {
                                                        required: true,
                                                        },
                                                        ]}
                                                >
                                                        <Input.Password placeholder='Type here....' size='large' />
                                                </Form.Item>
                                                        <a href='#' className='forgot-password-link'>Forgot Password?</a>
                                                <Button 
                                                        className='submit-btn'
                                                        htmlType='submit'
                                                        type='primary'
                                                        block
                                                        size='large'
                                                        loading={loading}
                                                >
                                                        Log In
                                                </Button>
                                        </Form>
                                </Col>
                        </Row>
                </div>
        </div>
  )
}

export default LoginPage