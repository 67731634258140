import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Space, Spin, Tooltip, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import moment from 'moment/moment';

const PlanInfo = ({
        solutionType = 1,
        setSolutionType = () => {},
        planRules = {},
        setPlanRules = () => {},
        frequency = "monthly",
        setFrequency = () => {},
        addons = [],
        setAddons = () => {},
        investigators = [],
        setInvestigators = () => {},
        legal = [],
        setLegal = () => {},
        supportManager = [],
        setSupportManager = () => {},
        startDate = "",
        setStartDate = () => {},
        endDate = "",
        setEndDate = () => {}
}) => {

        const {token, logoutHandler} = useContext(MainContext);

        const isMobile = window.innerWidth < 767;

        const [solutionTypes, setSolutionTypes] = useState([]);

        const [planTypes, setPlanTypes] = useState([]);

        const [addonsList, setAddonsList] = useState([
                {
                        label: "Visual Search",
                        value: "visual search"
                },
                {
                        label: "Test Purchase",
                        value: "test purchase"
                },
                {
                        label: "Domain Management",
                        value: "domain management"
                },
                {
                        label: "seller Intelligence",
                        value: "seller intelligence"
                },
        ])

        const [loading, setLoading] = useState({
                solutions: false,
                plans: false
        });
        const [investigatorLoading, setInvestigatorLoading] = useState(false);
        const [planType, setPlanType] = useState(3);
        const [selectedPlan, setSelectedPlan] = useState(planTypes[planType-1]);
        const [customizePopup, setCustomizePopup] = useState(false);

        const [investigatorsList, setInvestigatorsList] = useState([]);
        const [legalList, setLegalList] = useState([]);
        const [supportManagerList, setSupportManagerList] = useState([]);

        const customizePlanHandler = () => {
                const newArr = [...planTypes]
                newArr[selectedPlan?.index] = selectedPlan;
                setPlanTypes(newArr)
                setCustomizePopup(false)
        }

        const getSolutionsHandler = () => {
                setLoading({...loading, solutions: true});
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                };

                fetch(`${apiUrl}/solutions/*`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setLoading({...loading, solutions: false});
                        if(res?.status === 1) {
                                setSolutionTypes(res?.solution_details)
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        setLoading({...loading, solutions: false});
                        console.log('error', res)
                });
        }

        const getPlansHandler = () => {
                setLoading({...loading, plans: true});
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                };

                fetch(`${apiUrl}/plan/*`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setLoading({...loading, plans: false});
                        if(res?.status === 1) {
                                const object = res.plan_details;
                                delete object.page;
                                delete object.rows;
                                delete object.total;
                                setPlanTypes(Object?.values(object));
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        setLoading({...loading, plans: false});
                        console.log('error', res)
                });
        }

        const handleRuleChange = (newValue, index) => {
                const updatedRules = [...selectedPlan.rules];
                updatedRules[index].rule = newValue;

                setSelectedPlan({...selectedPlan, rules: updatedRules})
        }

        const getInvestigatorsHandler = () => {
                setInvestigatorLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/auth/list-users/*?user_type=touchstone&role_id=4`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setInvestigatorLoading(false);
                        if(res?.status === 1) {
                                const object = res.user_details;
                                delete object.page;
                                delete object.rows;
                                delete object.total;
                                setInvestigatorsList(Object?.values(object));
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        setInvestigatorLoading(false);
                        console.log('error', res)
                });
        }

        const getLegalHandler = () => {
                setInvestigatorLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/auth/list-users/*?user_type=touchstone&role_id=2`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setInvestigatorLoading(false);
                        if(res?.status === 1) {
                                const object = res.user_details;
                                delete object.page;
                                delete object.rows;
                                delete object.total;
                                setLegalList(Object?.values(object));
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        setInvestigatorLoading(false);
                        console.log('error', res)
                });
        }

        const getSupportManagersHandler = () => {
                setInvestigatorLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/auth/list-users/*?user_type=touchstone&role_id=6`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setInvestigatorLoading(false);
                        if(res?.status === 1) {
                                const object = res.user_details;
                                delete object.page;
                                delete object.rows;
                                delete object.total;
                                setSupportManagerList(Object?.values(object));
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        setInvestigatorLoading(false);
                        console.log('error', res)
                });
        }

        useEffect(() => {
                setPlanRules(selectedPlan);
        }, [selectedPlan])

        useEffect(() => {
                getSolutionsHandler();
                getPlansHandler();
                getInvestigatorsHandler();
                getLegalHandler();
                getSupportManagersHandler()
        }, [])

        useEffect(() => {
                if(planTypes?.length) {
                        setSelectedPlan(planTypes[planType-1]);
                }
        }, [planTypes])

        useEffect(() => {
                if(planType === 3) {
                        setAddons(['visual search', 'test purchase', 'domain management', 'seller intelligence'])
                }
                else if(planType === 2) {
                        setAddons(['test purchase'])
                }
                else if(planType === 1) {
                        setAddons([])
                }
        }, [planType])

  return (
        <>
                <Card className='card form-card'>
                        <h3 className="form-card-title">Solution Type</h3>
                        {
                                loading?.solutions ?
                                <div className="loading" style={{height: 169}}>
                                        <Spin />
                                </div> :
                                !solutionTypes?.length ?
                                <div className="loading" style={{height: 169}}>
                                        No solutions
                                </div> :
                                <Radio.Group
                                        className='solutiontype-radio-group'
                                        value={solutionType}
                                        onChange={e => setSolutionType(e.target.value)}
                                >
                                        {
                                                solutionTypes?.map((type, i) => (
                                                        <Radio key={i} value={i+1} className='solutiontype-radio'>
                                                                <div>
                                                                        <p className="title">{type?.name}</p>
                                                                        <div className="radio-desc-div">
                                                                                <hr />
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>Crawling</span><span>:</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.crawling}
                                                                                        </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>Monitoring</span><span>:</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.monitoring}
                                                                                        </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>Legal</span><span>:</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.legal}
                                                                                        </Col>
                                                                                </Row>
                                                                        </div>
                                                                </div>
                                                        </Radio>
                                                ))
                                        }
                                </Radio.Group>
                        }
                </Card>
                <Card className='card form-card'>
                        <h3 className="form-card-title">Plan Type</h3>
                        {
                                loading?.plans ?
                                <div className="loading" style={{height: 420}}>
                                        <Spin />
                                </div> :
                                !planTypes?.length ?
                                <div className="loading" style={{height: 420}}>
                                        No plans
                                </div> :
                                <Radio.Group
                                        className='plantype-radio-group'
                                        value={planType}
                                        onChange={e => {setPlanType(e.target.value); setSelectedPlan(planTypes[e.target.value - 1])}}
                                >
                                        {
                                                planTypes?.map((type, i) => (
                                                        <Radio key={i} value={i+1} className='plantype-radio'>
                                                                <div className='top-section'>
                                                                        <div className="top-left">
                                                                                <p className="title">{type?.name}</p>
                                                                                <p className="price">
                                                                                        {
                                                                                                isMobile ?
                                                                                                `$${type?.cost}/month` :
                                                                                                `Price: $${type?.cost} per month`
                                                                                        }
                                                                                </p>
                                                                        </div>
                                                                        <div className="top-right">
                                                                                {
                                                                                        planType === i+1 &&
                                                                                        <>
                                                                                        {
                                                                                                isMobile ?
                                                                                                <Tooltip title="Customize">
                                                                                                        <Button 
                                                                                                        shape="circle" 
                                                                                                        icon={ <EditOutlined />} 
                                                                                                        onClick={() => {setSelectedPlan({...type, index: i}); setCustomizePopup(true)}}
                                                                                                        />
                                                                                                </Tooltip> :
                                                                                                <Button
                                                                                                        type='default'
                                                                                                        onClick={() => {setSelectedPlan({...type, index: i}); setCustomizePopup(true)}}
                                                                                                >
                                                                                                        Customize Plan
                                                                                                </Button>
                                                                                        }
                                                                                        </>
                                                                                }
                                                                                <DownOutlined style={{transform:  planType === i+1 ? "rotate(180deg)" : ""}} />
                                                                        </div>
                                                                </div>
                                                                <div className={planType === i+1 ? "bottom-section open" : "bottom-section"}>
                                                                        {/* <div className="bottom-left">
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>{type?.rules[0]?.property?.replace(/_/g, " ")}</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.rules[0]?.rule}
                                                                                        </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>{type?.rules[1]?.property?.replace(/_/g, " ")}</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.rules[1]?.rule}
                                                                                        </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>{type?.rules[2]?.property?.replace(/_/g, " ")}</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.rules[2]?.rule} Accounts
                                                                                        </Col>
                                                                                </Row>
                                                                        </div>
                                                                        <div className="bottom-center">
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>{type?.rules[3]?.property?.replace(/_/g, " ")}</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.rules[3]?.rule}
                                                                                        </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>{type?.rules[4]?.property?.replace(/_/g, " ")}</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.rules[4]?.rule}
                                                                                        </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                        <Col span={12} className='type-label'>
                                                                                                <span>{type?.rules[5]?.property?.replace(/_/g, " ")}</span>
                                                                                        </Col>
                                                                                        <Col span={12} className='type-answer'>
                                                                                                {type?.rules[5]?.rule}
                                                                                        </Col>
                                                                                </Row>
                                                                        </div>
                                                                        <div className="bottom-right">
                                                                                <Row>
                                                                                        <Col span={24} className='type-label'>
                                                                                                <span>{type?.rules[6]?.property?.replace(/_/g, " ")}</span>
                                                                                        </Col>
                                                                                        <Col span={24} className='type-answer'>
                                                                                                {type?.rules[6]?.rule}
                                                                                        </Col>
                                                                                </Row>
                                                                        </div> */}
                                                                        <div className="bottom">
                                                                                {
                                                                                        type?.rules?.filter(item => item?.property !== "cost")
                                                                                        ?.map((rule, index) => (
                                                                                                <Row key={index}>
                                                                                                        <Col span={13} className='type-label'>
                                                                                                                {rule?.property?.replace(/_/g, " ")}
                                                                                                        </Col>
                                                                                                        <Col span={11} className='type-answer'>
                                                                                                                {rule?.rule}{" "}
                                                                                                                {
                                                                                                                        rule?.property === "social_media" && "Accounts"
                                                                                                                }
                                                                                                        </Col>
                                                                                                </Row>
                                                                                        ))
                                                                                }
                                                                        </div>
                                                                </div>
                                                        </Radio>
                                                ))
                                        }

                                </Radio.Group>
                        }
                </Card>
                <Card className='card form-card'>
                        <h3 className="form-card-title">Addons</h3>
                        {
                                loading?.plans ?
                                <div className="loading" style={{height: 169}}>
                                        <Spin />
                                </div> :
                                !planTypes?.length ?
                                <div className="loading" style={{height: 169}}>
                                        No Addons 
                                </div> :
                                <Checkbox.Group
                                        className='solutiontype-radio-group addon-checkbox-group'
                                        defaultValue={addons}
                                        value={addons}
                                        onChange={e => setAddons(e)}
                                >
                                        {
                                                addonsList?.map((type, i) => (
                                                        <Checkbox key={i} value={type?.value} className='solutiontype-radio addon-checkbox'>
                                                                <div>
                                                                        <p className="title">{type?.label}</p>
                                                                </div>
                                                        </Checkbox>
                                                ))
                                        }
                                </Checkbox.Group>
                        }
                </Card>
                <Card className='card form-card'>
                        <h3 className="form-card-title">Plan Details</h3>
                        <Form
                                className='form'
                                layout='vertical'
                                autoComplete="off"
                        >
                                <Row gutter={24}>
                                        <Col span={12}>
                                                <Form.Item
                                                        label="Start Date"
                                                        name="startDate"
                                                >
                                                        <DatePicker
                                                                size="large"
                                                                format={"MMMM DD, YYYY"}
                                                                style={{width: "100%", height: 40}}
                                                                defaultValue={selectedPlan?.start_date}
                                                                // onChange={(date, dateString) => setSelectedPlan({...selectedPlan, start_date: dateString})}
                                                                onChange={(date) => setStartDate(moment(date).format("YYYY-MM-DD"))}
                                                        />
                                                </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                                <Form.Item
                                                        label="End Date"
                                                        name="endDate"
                                                >
                                                        <DatePicker
                                                                size="large"
                                                                format={"MMMM DD, YYYY"}
                                                                style={{width: "100%", height: 40}}
                                                                defaultValue={selectedPlan?.end_date}
                                                                // onChange={(date, dateString) => setSelectedPlan({...selectedPlan, end_date: dateString})}
                                                                onChange={(date) => setEndDate(moment(date).format("YYYY-MM-DD"))}
                                                        />
                                                </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                                <Form.Item
                                                        label="Payment frequency"
                                                        name="paymentFrequency"
                                                >
                                                        <Select
                                                                className="row-select"
                                                                options={[
                                                                        {
                                                                        value: "monthly",
                                                                        label: 'Monthly'
                                                                        },
                                                                        {
                                                                        value: "yearly",
                                                                        label: 'Yearly'
                                                                        }
                                                                ]}
                                                                defaultValue={frequency}
                                                                onChange={value => setFrequency(value)}
                                                                size="large"
                                                        />
                                                </Form.Item>
                                        </Col>
                                </Row>
                        </Form>
                </Card>
                <Card className='card form-card'>
                        {
                                // planType !== 1 &&
                                selectedPlan?.rules?.filter(item => item?.property === "account_manager")[0]?.rule?.includes("1") &&
                                <>
                                <h3 className="form-card-title" style={{marginBottom: 10}}>Select Support Manager</h3>
                                <Form
                                        className='form'
                                        layout='vertical'
                                >
                                        <Form.Item>
                                                <Select
                                                        placeholder="Select support manager"
                                                        defaultValue={supportManager}
                                                        value={supportManager}
                                                        onChange={e => setSupportManager(e)}
                                                        size='large'
                                                        allowClear
                                                >
                                                        {
                                                                supportManagerList?.map((item, i) => (
                                                                        <Select.Option value={item?.id} key={i}>{item?.name}</Select.Option>
                                                                ))
                                                        }
                                                </Select>
                                        </Form.Item>
                                </Form>
                                </>
                        }
                        {
                                solutionType !== 3 &&
                                <>
                                <h3 className="form-card-title" style={{marginBottom: 10}}>Select Investigators</h3>
                                <Form
                                        className='form'
                                        layout='vertical'
                                >
                                        <Form.Item>
                                                <Select
                                                        placeholder="Select investigators"
                                                        mode="multiple"
                                                        allowClear
                                                        defaultValue={investigators}
                                                        value={investigators}
                                                        onChange={e => setInvestigators(e)}
                                                        size="large"
                                                >
                                                        {
                                                                investigatorsList?.map((item, i) => (
                                                                        <Select.Option value={item?.id} key={i}>{item?.name}</Select.Option>
                                                                ))
                                                        }
                                                </Select>
                                        </Form.Item>
                                </Form>
                                </>
                        }
                        <h3 className="form-card-title" style={{marginBottom: 10}}>Select Legal</h3>
                        <Form
                                className='form'
                                layout='vertical'
                        >
                                <Form.Item>
                                        <Select
                                                placeholder="Select legal"
                                                mode="multiple"
                                                allowClear
                                                defaultValue={legal}
                                                value={legal}
                                                onChange={e => setLegal(e)}
                                                size='large'
                                        >
                                                {
                                                        legalList?.map((item, i) => (
                                                                <Select.Option value={item?.id} key={i}>{item?.name}</Select.Option>
                                                        ))
                                                }
                                        </Select>
                                </Form.Item>
                        </Form>
                </Card>
                <Modal 
                        title={`Customize ${selectedPlan?.name} plan`} 
                        open={customizePopup} 
                        onCancel={() => setCustomizePopup(false)} 
                        centered 
                        okText="Customize"
                        onOk={() => {customizePlanHandler()}}
                >
                        <Form
                                 className='form'
                                 layout='vertical'
                                 autoComplete="off"
                        >
                                <Row gutter={24}>
                                        <Col span={12}>
                                                <Form.Item label={selectedPlan?.rules[0]?.property?.replace(/_/g, " ")}>
                                                        <Input value={selectedPlan?.rules[0]?.rule} onChange={e => handleRuleChange(e.target.value, 0)} />
                                                </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                                <Form.Item label={selectedPlan?.rules[1]?.property?.replace(/_/g, " ")}>
                                                        <Input value={selectedPlan?.rules[1]?.rule} onChange={e => handleRuleChange(e.target.value, 1)} />
                                                </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                                <Form.Item label={selectedPlan?.rules[2]?.property?.replace(/_/g, " ")}>
                                                        <Input value={selectedPlan?.rules[2]?.rule} onChange={e => handleRuleChange(e.target.value, 2)} />
                                                </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                                <Form.Item label={selectedPlan?.rules[3]?.property?.replace(/_/g, " ")}>
                                                        <Input value={selectedPlan?.rules[3]?.rule} onChange={e => handleRuleChange(e.target.value, 3)} />
                                                </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                                <Form.Item label={selectedPlan?.rules[4]?.property?.replace(/_/g, " ")}>
                                                        <Input value={selectedPlan?.rules[4]?.rule} onChange={e => handleRuleChange(e.target.value, 4)} />
                                                </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                                <Form.Item label={selectedPlan?.rules[5]?.property?.replace(/_/g, " ")}>
                                                        <Input value={selectedPlan?.rules[5]?.rule} onChange={e => handleRuleChange(e.target.value, 5)} />
                                                </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                                <Form.Item label={selectedPlan?.rules[6]?.property?.replace(/_/g, " ")}>
                                                        <Input value={selectedPlan?.rules[6]?.rule} onChange={e => handleRuleChange(e.target.value, 6)} />
                                                </Form.Item>
                                        </Col>
                                </Row>
                        </Form>
                </Modal>
        </>
  )
}

export default PlanInfo