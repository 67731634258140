import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import { Button, Card, Col, Dropdown, Form, Image, Input, Modal, Row, Select, Space, Spin, Upload, message } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import './index.css';
import { ReactComponent as BackBtn } from "../../assets/images/back-icon-btn.svg";
import { ReactComponent as Email } from "../../assets/images/email.svg";
import { ReactComponent as Mobile } from "../../assets/images/mobile.svg";
import { ReactComponent as Location } from "../../assets/images/location.svg";
import { ReactComponent as Chevron } from "../../assets/images/right-chevron.svg";
import {ReactComponent as Filter} from '../../assets/images/filter.svg';
import { ReactComponent as LinkIcon } from "../../assets/images/link.svg";
import { ReactComponent as Action } from "../../assets/images/action.svg";
import NoImage from "../../assets/images/no-image.jpeg";
import TableContainer from '../../components/TableContainer';
import moment from 'moment';
import ProductDetailsModal from '../../components/ProductDetailsModal';

const SellerDetails = () => {

  const navigate = useNavigate();

  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const caseId = queryParams.get("case_id");
  const about = queryParams.get("about");

  const { token, user, logoutHandler, allStatus } = useContext(MainContext);

  const isMobile = window.innerWidth < 767;

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [data, setData] = useState({});
  const [products, setProducts] = useState([{platform: ""}]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); 
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProuctStatus, setSelectedProductStatus] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [status, setStatus] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [multipleListingsUpdateStatus, setMultipleListingsUpdateStatus] = useState(allStatus[0]?.code || "");
  const [tempSelectedProduct, setTempSelectedProduct] = useState({});
  const [formData, setFormData] = useState({
    sellerUrl: "",
    sellerInvoice: null,
    sellerStatus: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    supplier: "",
    supplierAddress: ""
  });

  const items = [
    {
      key: '1',
      danger: false,
      label: (
              <a onClick={() => setSelectedProduct(tempSelectedProduct)}>
                Update
              </a>
      ),
    }
  ];

  const columns = [
    {
      title: "Listing",
      dataIndex: "title",
      width: 300,
      render: (text, row) => (
        <div
          className="listing-title-cell"
          // onClick={() => setSelectedProduct(row)}
        >
          {row?.images?.length && (
            <img
              src={row?.image[0]}
              width={68}
              alt={row?.title}
              className="listing-img-table"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = NoImage;
              }}
            />
          )}
          <span>
            {text?.substring(0, 60)}
            {text?.length > 60 && "..."}
          </span>
        </div>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      width: 100,
    },
    {
      title: "Price",
      dataIndex: "converted",
      width: 100,
      render: (text) => (text?.value ? `$${text?.value?.toFixed(2)}` : ""),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      render: (text) => text ? <div className={`status ${text?.toLowerCase()}`}>{text}</div> : "",
    },
    {
      title: "Link",
      dataIndex: "link",
      width: 100,
      align: "center",
      render: (text) => (
        text ? 
        <a href={text} target="_blank" rel="noreferrer">
          <LinkIcon />
        </a> : ""
      ),
    },
    {
      title: "Action",
      align: "center",
      width: 100,
      render: (text, row) => (
        row?.id ? 
        <Dropdown
                menu={{items,}}
                placement="bottomRight"
                trigger={['click']}
                onClick={() => {
                  setTempSelectedProduct(row)
                }}
        >
                <Button
                        type='link'
                >
                        <Action />
                </Button>
        </Dropdown> : ""
                        
      ),
    },
  ];

  const getSellerDetailsHandler = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const uniqueId = encodeURIComponent(id);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = status === "" ?
     `${apiUrl}/get-seller?case_id=${caseId}&about=${about}&unique_id=${uniqueId}&page=${page}&limit=${rows}&search_str=${searchStr}` :
     `${apiUrl}/get-seller?case_id=${caseId}&about=${about}&unique_id=${uniqueId}&page=${page}&limit=${rows}&search_str=${searchStr}&search_status[]=${status}`

    fetch(url, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      setLoading(false);
      if (res?.status === 1) {
        setData(res);
        setProducts(Object.values(res?.seller_other_information?.products))
        setFilteredProducts(Object.values(res?.seller_other_information?.products))
        setTotal(res?.seller_other_information?.totalListing)
        setFormData({
          email: res?.sellerInfo?.email || "",
          sellerUrl: res?.sellerInfo?.seller_url || "",
          sellerInvoice: res?.sellerInfo?.seller_invoice || null,
          sellerStatus: res?.sellerInfo?.seller_status || "",
          companyName: res?.sellerInfo?.company_name || "",
          companyAddress: res?.sellerInfo?.company_address || "",
          companyContact: res?.sellerInfo?.company_contact || "",
          supplier: res?.sellerInfo?.supplier || "",
          supplierAddress: res?.sellerInfo?.supplier_address || ""
        })
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.email[0]);
        }
    })
    .catch((error) => {
      setLoading(false);
      console.log("error", error);
      message.error("Something went wrong!");
    });
  }

  const updateSellerHandler = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("email", formData?.email);
    formdata.append("seller_url", formData?.sellerUrl);
    formdata.append("about", about);
    formdata.append("case_id", caseId);
    formdata.append("unique_id", id);
    formData?.sellerInvoice?.name && 
      formdata.append("seller_invoice", formData?.sellerInvoice, formData?.sellerInvoice?.name);
    formdata.append("seller_flag", "0");
    formdata.append("seller_status", formData?.sellerStatus);
    formdata.append("company_contact", formData?.companyContact);
    formdata.append("company_address", formData?.companyAddress);
    formdata.append("company_name", formData?.companyName);
    formdata.append("supplier", formData?.supplier);
    formdata.append("supplier_address", formData?.supplierAddress);
    formdata.append("report_action[]", "Report Seller");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/update-seller`, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      setLoading(false);
      if (res?.status === 1) {
        message.success(res?.message);
        setDetailsModal(false);
        setIsEdit(false);
        getSellerDetailsHandler();
      }
      else if(res?.api_status ==='401') {
        logoutHandler("401");
        }
        else {
                message.error(res?.email[0]);
        }
    })
    .catch((error) => {
      setLoading(false);
      console.log("error", error);
      message.error("Something went wrong!");
    });
  }

  const UpdateMultipleListingsHandler = () => {
    setUpdating(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("about", "amazon");
    formdata.append("case_id", caseId);
    formdata.append("seller_status", multipleListingsUpdateStatus);
    formdata.append("seller_flag", "0");
    selectedRows?.map((key, i) => (
      formdata.append("unique_id[]", key?.uid)
    ))

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${apiUrl}/update-seller`, requestOptions)
      .then(response => response.json())
      .then((res) => {
        setUpdating(false);
        if (res?.status === 1) {
          message.success(res?.message);
          setOpenUpdateStatus(false);
          setSelectedRows([]);
          setSelectedRowKeys([]);
          getSellerDetailsHandler();
        }
        else if(res?.api_status ==='401') {
          logoutHandler("401");
          }
          else {
                  message.error(res?.email[0]);
          }
      })
      .catch((error) => {
        setUpdating(false);
        console.log("error", error);
        message.error("Something went wrong!");
      });
  }

  useEffect(() => {
    if (searchStr?.length) {
      const getData = setTimeout(() => {
        getSellerDetailsHandler();
      }, 700);

      return () => clearTimeout(getData);
    } else {
      getSellerDetailsHandler();
    }
  }, [page, rows, searchStr, status])

  // useEffect(() => {
  //   let newArr = searchStr?.length ? products?.filter(item => item?.title?.toLowerCase()?.includes(searchStr)) : products;
  //   setFilteredProducts(newArr);
  // }, [searchStr]);

  // useEffect(() => {
  //   let newArr = status !== "all" ? products?.filter(item => item?.status?.toLowerCase()?.includes(status)) : products;
  //   setFilteredProducts(newArr);
  // }, [status])

  return (
    <div className="cases-div case-listing-div seller-details-div">
      <Card className='card cases-card seller-details-card'>
        <>
        <Row gutter={10} style={{padding: 24, backgroundColor: "#FFFFFF"}}>
          <Col xs={24} lg={14} style={{ display: "flex", alignItems: "center" }}>
            <BackBtn
              style={{ marginRight: 12, cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <h3 className="page-title">
              Seller profile
            </h3>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className='first-section-row'>
          <Col xs={24} lg={16} className='left-col'>
            <Card>
              {
                loading ?
                <div className="loading" style={{height: 280}}><Spin size='large' /></div> :
                <Row gutter={24}>
                  <Col span={4}>
                    <div className="img-placeholder"></div>
                  </Col>
                  <Col xs={24} lg={20}>
                    <div className="top-div">
                      <div>
                        <p className="title">{data?.sellerInfo?.company_name || data?.seller_other_information?.seller?.name}</p>
                        <div className="desc-div">
                          <div className={`status ${data?.sellerInfo?.seller_status?.toLowerCase() || data?.seller_other_information?.status?.toLowerCase()}`}>{data?.sellerInfo?.seller_status || data?.seller_other_information?.status}</div>
                          <div className="last-update">Last updated: {moment(data?.sellerInfo?.updated_on || data?.seller_other_information?.last_seen).format("Do MMMM YYYY") || "--"}</div>
                        </div>
                      </div>
                      <div>
                        {
                          user?.role_id !== 3 &&
                          <Button
                            type='default'
                            style={isMobile ? {marginTop: 16} : {}}
                            onClick={() => {
                              setDetailsModal(true);
                              setIsEdit(true);
                            }}
                          >
                            Update details
                          </Button>
                        }
                      </div>
                    </div>
                    <div className="bottom-div">
                      <p className="platform">
                        <span>Platforms:</span> {data?.seller_other_information?.platforms || "--"}
                      </p>
                      <ul className="meta">
                        <li><Location /> {data?.sellerInfo?.company_address || "--"}</li>
                        <li><Mobile /> {data?.sellerInfo?.company_contact || "--"}</li>
                        <li><Email /> {data?.sellerInfo?.email || "--"}</li>
                      </ul>
                      <p className="link" onClick={() => setDetailsModal(true)}>View more details <Chevron /></p>
                    </div>
                  </Col>
                </Row>
              }
            </Card>
          </Col>
          <Col xs={24} lg={8} className='right-col'>
              <Card>
                <p className="label">Total listings</p>
                <p className="value">{loading ? "--" : data?.seller_other_information?.totalListing}</p>
              </Card>
              <Card>
                <p className="label">Listings reported</p>
                <p className="value">{loading ? "--" : data?.seller_other_information?.reportedListing}</p>
              </Card>
              <Card>
                <p className="label">Listings Removed</p>
                <p className="value">{loading ? "--" : data?.seller_other_information?.removedListing}</p>
              </Card>
          </Col>
        </Row>
        <div className="second-section-div">
          <hr />
          <Card style={{padding: "40px 24px"}}>
            <p className="card-title">Listings {loading ? "" : <span className="number">({filteredProducts?.length})</span>}</p>
            <Row>
              <Col span={24}>
                <Space wrap>
                  <Input
                    size='large'
                    placeholder='Search listings'
                    prefix={<SearchOutlined style={{color: "#A9AFBA"}} />}
                    style={{width: isMobile ? "100%" : 296}}
                    value={searchStr}
                    onChange={e => {setSearchStr(e.target.value); setPage(1);}}
                  />
                  <div className='select-input-div'>
                    <label>Status</label>
                    <Select
                      className='select status-select'
                      defaultValue={status}
                      style={{minWidth: 120, textTransform: "capitalize"}}
                      size='large'
                      bordered={false}
                      onChange={value => setStatus(value)}
                    >
                      <Select.Option value="">All</Select.Option>
                        {
                          allStatus?.map((status) => (
                            <Select.Option value={status?.code}>{status?.name}</Select.Option>
                          ))
                        }
                    </Select>
                  </div>
                  {/* <Button
                    size='large'
                  >
                    <Filter style={{marginRight: 10}} />
                    Filter
                  </Button> */}
                  {
                      (selectedRowKeys?.length && user?.role_id !== 3) ? <>
                      <Button 
                        size="large" 
                        onClick={() => setOpenUpdateStatus(true)}
                      >
                        Update Status of {selectedRowKeys?.length} Listings
                      </Button>
                      </> : null
                    }
                </Space>
              </Col>
            </Row>
            <TableContainer
              data={filteredProducts}
              columns={columns}
              loading={loading}
              isPagination={true}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              total={total}
              page={page}
              setPage={setPage}
              rows={rows}
              setRows={setRows}
            />
          </Card>
        </div>
        </>
      </Card>
      <Modal
        open={detailsModal}
        onCancel={() => {
          setDetailsModal(false);
          setIsEdit(false);
        }}
        footer={[]}
        title=""
        width={768}
        className="listing-details-modal seller-details-modal"
      >
        <Row gutter={0} className='modal-top-info-div'>
          <Col span={3}>
            <div className="img-placeholder"></div>
          </Col>
          <Col span={20}>
            <div className="top-div">
              <div>
                <p className="title">{data?.sellerInfo?.company_name || data?.seller_other_information?.seller?.name}</p>
                <div className="desc-div">
                  <div className={`status ${data?.sellerInfo?.seller_status.toLowerCase() || data?.seller_other_information?.status?.toLowerCase()}`}>{data?.sellerInfo?.seller_status || data?.seller_other_information?.status}</div>
                  <div className="last-update">Last updated: {moment(data?.sellerInfo?.updated_on || data?.seller_other_information?.last_seen).format("Do MMMM YYYY") || "--"}</div>
                </div>
              </div>
              {
                user?.role_id !== 3 &&
                <div style={isMobile ? {marginTop: 12} : {}}>
                  {
                    isEdit ?
                    <Button
                      type='primary'
                      onClick={updateSellerHandler}
                      loading={loading}
                      disabled={
                        !formData?.email?.length ||
                        !formData?.sellerUrl?.length ||
                        !formData?.sellerStatus?.length ||
                        !formData?.companyName?.length ||
                        !formData?.companyAddress?.length ||
                        !formData?.companyContact?.length ||
                        !formData?.supplier?.length ||
                        !formData?.supplierAddress?.length
                      }
                    >
                      Save details
                    </Button> :
                    <Button
                      type='default'
                      onClick={() => {
                        setIsEdit(true);
                      }}
                    >
                      Update details
                    </Button>
                  }
                </div>
              }
            </div>
          </Col>
        </Row>
        <hr />
        <Form
          className='form'
          layout='vertical'
          autoComplete="on"
        >
          <Form.Item
            label="Seller email"
            name="email"
            rules={[
              {
                      type: 'email'
              },
            ]}
          >
            <Input 
              placeholder='Type here....' 
              size='large' 
              onKeyPress={(event) => {
                      if (/^\s+$/.test(event.key)) {
                              event.preventDefault();
                      }
              }}
              defaultValue={formData?.email}
              value={formData?.email}
              onChange={e => setFormData({...formData, email: e.target.value})}
              readOnly={!isEdit}
            />
          </Form.Item>
          <Form.Item
            label="Seller URL"
            name="seller_url"
          >
            <Input 
              placeholder='Type here....' 
              size='large' 
              defaultValue={formData?.sellerUrl}
              value={formData?.sellerUrl}
              onChange={e => setFormData({...formData, sellerUrl: e.target.value})}
              readOnly={!isEdit}
            />
          </Form.Item>
          <Form.Item
            label="Seller invoice"
            name="seller_invoice"
            className='logo-input-div'
          >
            {
              isEdit &&
              <Upload
                customRequest={file => setFormData({...formData, sellerInvoice: file?.file})}
                onRemove={() => setFormData({...formData, sellerInvoice: null})}
              >
                <Button>Choose File</Button>
              </Upload>
            }
            {
              formData?.sellerInvoice?.length &&
              <a
                className="link"
                href={formData?.sellerInvoice}
                target='_blank'
                rel='noreferrer'
              >
                View invoice
              </a>
            }
          </Form.Item>
          <Form.Item
            label="Seller Status"
            name="seller_status"
          >
            <Select
              size="large"
              placeholder="Select status"
              // options={[
              //   {
              //     value: "pending",
              //     label: "Pending",
              //   },
              //   {
              //     value: "verified",
              //     label: "Verified",
              //   },
              //   {
              //     value: "unverified",
              //     label: "Unverified",
              //   },
              //   {
              //     value: "reported",
              //     label: "Reported",
              //   },
              //   {
              //     value: "removed",
              //     label: "Removed",
              //   },
              // ]}
              defaultValue={formData?.sellerStatus}
              value={formData?.sellerStatus}
              onChange={e => setFormData({...formData, sellerStatus: e})}
              disabled={!isEdit}
            >
              {
                allStatus?.map((status) => (
                  <Select.Option value={status?.code}>{status?.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <hr />
          <Form.Item
            label="Company name"
            name="company_name"
          >
            <Input 
              placeholder='Type here....' 
              size='large' 
              defaultValue={formData?.companyName}
              value={formData?.companyName}
              onChange={e => setFormData({...formData, companyName: e.target.value})}
              readOnly={!isEdit}
            />
          </Form.Item>
          <Form.Item
            label="Company address"
            name="company_address"
          >
            <Input 
              placeholder='Type here....' 
              size='large' 
              defaultValue={formData?.companyAddress}
              value={formData?.companyAddress}
              onChange={e => setFormData({...formData, companyAddress: e.target.value})}
              readOnly={!isEdit}
            />
          </Form.Item>
          <Form.Item
            label="Company Phone no."
            name="company_contact"
          >
            <Input 
              placeholder='Type here....' 
              size='large' 
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
                }
              }}
              defaultValue={formData?.companyContact}
              value={formData?.companyContact}
              onChange={e => setFormData({...formData, companyContact: e.target.value})}
              readOnly={!isEdit}
            />
          </Form.Item>
          <hr />
          <Form.Item
            label="Supplier"
            name="supplier"
          >
            <Input 
              placeholder='Type here....' 
              size='large' 
              defaultValue={formData?.supplier}
              value={formData?.supplier}
              onChange={e => setFormData({...formData, supplier: e.target.value})}
              readOnly={!isEdit}
            />
          </Form.Item>
          <Form.Item
            label="Supplier address"
            name="supplier_address"
          >
            <Input 
              placeholder='Type here....' 
              size='large' 
              defaultValue={formData?.supplierAddress}
              value={formData?.supplierAddress}
              onChange={e => setFormData({...formData, supplierAddress: e.target.value})}
              readOnly={!isEdit}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={openUpdateStatus}
        onCancel={() => setOpenUpdateStatus(false)}
        footer={[]}
        title={`Update status of ${selectedRows?.length} listings`}
        width={500}
      >
        <Form layout="vertical">
          <Form.Item label="Status" name="status">
            <Select
              placeholder="Select status..."
              defaultValue={multipleListingsUpdateStatus}
              value={multipleListingsUpdateStatus}
              onChange={e => setMultipleListingsUpdateStatus(e)}
            >
              {
                allStatus?.map((status, i) => (
                  <Select.Option value={status?.code} key={i}>{status?.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 12}}>
            <Button
              size="large"
              type="primary"
              onClick={() => UpdateMultipleListingsHandler()}
              loading={updating}
            >
              Update listings
            </Button>
          </div>
        </Form>
      </Modal>
      <ProductDetailsModal
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        selectedProuctStatus={selectedProuctStatus}
        setSelectedProductStatus={setSelectedProductStatus}
        caseId={caseId}
        fetchDataHandler={getSellerDetailsHandler}
      />
    </div>
  )
}

export default SellerDetails