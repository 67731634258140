import './index.css';
import { Card, Col, Pagination, Row, Spin, Tabs, message } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from '../../utils/context';
import { apiUrl } from '../../utils/constants';
import moment from 'moment/moment';
import NoData from '../../components/NoData';

const Notifications = () => {

        const navigate = useNavigate();

        const {token, logoutHandler, updateNotificationHandler} = useContext(MainContext);

        const [loading, setLoading] = useState(false);
        const [data, setData] = useState([]);
        const [page, setPage] = useState(1);
        const [limit, setLimit] = useState(10);
        const [total, setTotal] = useState(0);

        const getNotificationsHandler = () => {
                setLoading(true);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${apiUrl}/notifications?page=${page}&limit=${limit}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                        setLoading(false);
                        if(res?.status === 1) {
                                setData(res?.data);
                                setTotal(res?.total);
                        }
                        else if(res?.api_status ==='401') {
                                logoutHandler("401");
                                }
                                else {
                                        message.error(res?.email[0]);
                                }
                })
                .catch(res => {
                        setLoading(false);
                        console.log('error', res)
                });
        }

        useEffect(() => {
                getNotificationsHandler();
        }, [page, limit])

  return (
    <div className="notifications-div cases-div add-new-case-div add-new-client-div client-info-div">
      <Card className="card add-new-case-card notifications-card">
        <Row gutter={10}>
          <Col
            xs={24}
            lg={12}
            style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
          >
            <h3 className="page-title">Notifications</h3>
          </Col>
        </Row>
      </Card>
      <div className="content-body">
        <Card className="notifications-content-card">
                {
                        loading ?
                        <div className="loading-div" style={{height: "50vh", display: "flex", alignItems: "center", justifyContent: "center"}}><Spin size='large' /></div> :
                        data?.length ?
                        <>
                        {
                                data?.map((item, i) => (
                                        <ul className="notifications-list" key={i}>
                                                <li className="notifications-list-item notification">
                                                        <div className="img-div">
                                                                <div className="img">
                                                                        {item?.area?.split(' ').map(word => word.charAt(0)).join('')}
                                                                </div>
                                                        </div>
                                                        <div className="text-div">
                                                                {
                                                                        item?.area?.toLowerCase() === "users" ?
                                                                        <p className="title">{item?.area || "--"}</p> :
                                                                        <p className="title">{item?.case_name || "--"}{" "}<span>(case id: {item?.case_id || "--"})</span></p>
                                                                }
                                                                <p className="desc">
                                                                        {/* <span className="category">Social media:</span> 10234 listings <span className="status">Reported</span> */}
                                                                        {item?.message}
                                                                </p>
                                                                <p className="date">
                                                                        {moment(item?.on).format("DD MMM, YYYY [at] h:mm a z")}
                                                                </p>
                                                                <p 
                                                                onClick={() => {
                                                                        updateNotificationHandler(item);
                                                                        (item?.area?.toLowerCase() === "users") ?
                                                                        navigate(`/users`) :
                                                                        (item?.area?.toLowerCase() === "domain-management") ?
                                                                        navigate(`/domain-management?caseid=${item?.data?.case_id}&status=${item?.data?.status}`) :
                                                                        navigate(`/cases/${item?.data?.case_id}?category=${item?.data?.area}&status=${item?.data?.status}`)
                                                                }} 
                                                                className="link"
                                                                >
                                                                        View
                                                                </p>
                                                        </div>
                                                        <div className="marker-div">
                                                                <div className={(item?.seen === null || item?.seen === 0) ?  "marker new" : "marker"}>New</div>
                                                        </div>
                                                </li>
                                        </ul>
                                ))
                        }
                        </> : <NoData title="No notifications" desc="Notifications will appear here" />
                }
                <div className="pagination-div" style={{justifyContent: "flex-end", marginTop: 60}}>
                        <Pagination className='pagination' defaultCurrent={page} current={page} total={total} pageSize={limit} onChange={(e) => setPage(e)} />
                </div>
        </Card>
      </div>
    </div>
  );
};

export default Notifications;
